import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    //useRecordContext,
    ReferenceField, ReferenceManyField, TextField,
    SingleFieldList, BooleanField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldBold} from "../_common/CompReact";
import {FloatNumber} from "../booking/BookingColumnsForList";

export const TravelCreditColumnsForList = (resource, locale, classes) => {
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        passenger:
            <div label={'Passenger'}>
                <ReferenceField source="booking_id" reference="booking">
                    <ReferenceManyField reference="bookings_passenger" target="booking_id" link={false}
                                        label={'Ticket number'} sortable={false}
                                        cellClassName={classes.ticket_number_st}>
                        <SingleFieldList style={{display: "grid"}}>
                            <ReferenceField source="passenger_id" reference="passenger">
                                <div><TextField source="title"/> <TextField source="f_name"/> <TextField source="l_name"/></div>
                            </ReferenceField>
                        </SingleFieldList>
                    </ReferenceManyField>
                </ReferenceField>
            </div>,
        customer:
            <div label={'Customer'} sortBy={'customer:name'}>
                <ReferenceField source="booking_id" reference="booking">
                    <ReferenceField source="customer_id" reference="customer">
                        <TextField source="name"/>
                    </ReferenceField>
                </ReferenceField>
            </div>,
        booking:
            <ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'}>
                <div label={'Booking'} style={{display: "grid"}}>
                    <TextFieldBold
                        //itsOriText={record => (`${record.last_name}`)}
                        //copyClipBoard={true}
                        source="route"
                    />
                    <DateFieldComp source="booking_date" textAlign={"center"}
                                   cellClassName={classes.date_st} showTime={false} locale={'us'}/>
                    <TextField source="issued_airline_details.title"/>
                </div>
            </ReferenceField>,

        expire_date: <DateFieldComp source="expire_date" label={'Expire date'} textAlign={"center"}
                                    cellClassName={classes.date_st} showTime={false} locale={'us'}/>,

        amount_dollar: <FloatNumber source={'amount_dollar'} label={'Amount ($)'} textAlign={"right"}/>,
        /*is_used: <BooleanFieldComp source="is_used" label={'Is used'} textAlign={"center"}/>,*/
        /*is_used: <SelectAndSaveChoices
                label={'Is used'}
                source="is_used"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
            />,*/
        bookman_property: <BooleanField source={'bookman_property'} label={'Bookman Property'} textAlign={"center"}/>,
        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};
