import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    TextField,
    //useRecordContext,
    EmailField, BooleanField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldBold} from "../_common/CompReact";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
// import {commonYesNo} from "../utils/choices";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
// import get from 'lodash/get';
//import {Box} from "@material-ui/core";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
// import {titleMrMs} from "../utils/choices";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";

export const CustomerColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        customer: <TextFieldBold source="name" label={'Name'}/>,
        is_company: <BooleanField source={'is_company'} label={'Is company'}/>,
        title: <TextField source={`title`} label={'Title'}/>
            /*<SelectAndSaveChoices
                label={'title'}
                source="title"
                statuses={titleMrMs}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                disableCondition={true}
                width={'2em'}
            />*/,
        /*passenger:
            <Box display={'grid'} label={'passenger'} sortBy={`l_name`}>
                <Box><TextFieldBold source={`l_name`} label={'Last name'}/></Box>
                <Box><TextFieldBold source={`f_name`} label={'First name'}/></Box>
            </Box>,*/
        f_name: <TextField source={`f_name`} label={'First name'}/>,
        l_name: <TextField source={`l_name`} label={'Last name'}/>,
        /*b_day: <DateFieldComp source="b_day" label={'Birthday'} textAlign={'center'} cellClassName={classes.date_st}/>,*/

        email: <EmailField source={`email`} label={'email'}/>,
        mobile: <TextField source={`mobile`} label={'mobile'}/>,
        address: <div style={{display: "grid"}} label={'Address'} title={row => `${row.address}`}>
            <TextField source={`country`} label={'Country'}/>
            <TextField source={`region`} label={'Region'}/>
            <TextField source={`city`} label={'City'}/>
        </div>,


        created_at: <DateFieldComp source="created_at" label={'Created'}  cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'}  cellClassName={classes.date_st}/>,
    }
    return columns;
};
