import * as React from "react";
import "react-tabulator/lib/css/tabulator.min.css";
import MasterReport from "../masterReport";
import {FormControl, Grid, InputLabel, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {useEffect} from "react";

// https://tabulator.info/
const report_fields = ['year', 'month', 'customer', 'booking', 'transaction', 'date']
const report_group_by = ['year', 'month', 'customer', 'booking']

const variantsConfig = {
    'Profit': [[false, true, false, false, false, false], [false, false, false, false]],
    'Profit by customer': [[false, false, true, true, false, false], [false, false, true, false]],
    'Total by year': [[true, false, false, false, false, false], [false, false, false, false]],
    'Total by year details': [[true, true, false, false, false, false], [true, false, false, false]],
    'Refunds': [[false, false, false, true, true, false], [false, false, false, true]],
}

const table_columns = [
    // eslint-disable-next-line no-undef
    {title: "Year", field: "year", width: 200, responsive: 0}, //never hide this column
    {title: "Month", field: "month", width: 100, sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
        //a, b - the two values being compared
        //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
        //column - the column component for the column being sorted
        //dir - the direction of the sort ("asc" or "desc")
        //sorterParams - sorterParams object from column definition array
        // debugger
        return aRow._row.data.month_num - bRow._row.data.month_num; //you must return the difference between the two values
    },},
    // {title: "Month num", field: "month_num", width: 100},
    {title: "Customer", field: "customer", width: 150, responsive: 2}, //hide this column first
    {title: "Booking", field: "booking", width: 150, responsive: 2}, //hide this column first
    {title: "Transaction", field: "transaction", width: 150, responsive: 2}, //hide this column first
    {title: "Date", field: "date", hozAlign: "center", width: 150, formatter: "date",},
    {title: "Qty of Bookings", field: "qty", hozAlign: "center", width: 170, topCalc: "sum"},
    {
        title: "Sales",
        field: "sale",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {
        title: "Cost",
        field: "cost",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {
        title: "Profit",
        field: "profit",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
]

const filters = {
    booking: ['Query', false, 'booking/admin/booking', (option) => `Booking ${option.id}`],
    customer: ['Query', false, 'booking/admin/customer', (option) => option.name],
    date_from: ['DateTime', true, ],
    date_to: ['DateTime', false, ],
}

const AdditionalParamsComponent = (props) => {
    const {filterState, setFilterState} = props
    const currentYear = new Date().getFullYear()
    const [yearState, setYearState] = React.useState(currentYear)

    const years = Array.from({length: 10}, (_, i) => currentYear - i);

    const handleChange = (event) => {
      setYearState(event.target.value)
    }

    useEffect(() => {
        setYearState(currentYear)
    }, [setYearState, currentYear])

    useEffect(() => {
        setFilterState({
            ...filterState,
            date_from: {...filterState['date_from'], use: true, value: `${yearState}-01-01T00:00:00`},
            date_to: {...filterState['date_to'], use: true, value: `${yearState}-12-31T23:59:59`},
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yearState])

    return (<Grid container spacing={3} style={{marginBottom: 8}}>
        <Grid item xs={12}>
            <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={yearState}
                    onChange={handleChange}
                    label="Year"
                >
                    {years.map(y=><MenuItem value={y}>{y}</MenuItem>)}
                </Select>
            </FormControl>
        </Grid>
    </Grid>)
}

const Profit = () => {

    return <MasterReport
        report_fields={report_fields}
        report_group_by={report_group_by}
        variantsConfig={variantsConfig}
        table_columns={table_columns}
        report_url='api/reports/profit'
        filters={filters}
        additionalOptions={{
            initialSort:[
                {column:"month", dir:"asc"}, //sort by this first
            ]
        }}
        AdditionalParamsComponent={AdditionalParamsComponent}
    />
};

export default Profit