// in src/Menu.js
import * as React from 'react';
import {useState} from 'react';
import {
    //DashboardMenuItem,
    useTranslate,
    // usePermissions,
} from 'react-admin';
import {useSelector} from 'react-redux';
// import {Divider} from '@material-ui/core';
import SubMenu from "./SubMenu";
import {MenuItemLink} from 'react-admin';

import BusinessIcon from '@material-ui/icons/Business';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
//-import AirplaneTicketIcon from '@material-ui/icons/AirplaneTicket';
//-import TerminalIcon from '@material-ui/icons/Terminal';
//-import FeedIcon from '@material-ui/icons/Feed';
//-import ArticleIcon from '@material-ui/icons/Article';

import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalAirportIcon from '@material-ui/icons/LocalAirport';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
//import AirplayIcon from '@material-ui/icons/Airplay';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import HistoryIcon from '@material-ui/icons/History';
//import AssessmentIcon from '@material-ui/icons/Assessment';

import {getCognitoClient} from "../utils/dataProvider";
import {canAccess2} from "../utils/canAccess2";


export const MyMenu = (props) => {
    const translate = useTranslate();
    const {dense} = props || {dense: false}
    //const resources = useSelector(getResources);

    const [state, setState] = useState({
        menuTickets: false,
    });

    useSelector((state) => state.theme); // force rerender on theme change
    // const classes = useStyles();

    /*const {loading, permissions} = usePermissions();
    if (loading) return null;*/
    const handleToggle = (menu) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    getCognitoClient();

    return (
        <div>
            {/*<DashboardMenuItem/>*/}
            <MenuItemLink
                to={`/`} // by default react-admin renders Dashboard on this route
                primaryText="Sales and Stats"
                leftIcon={<DashboardIcon/>}
                //onClick={onMenuClick}
                sidebarIsOpen={true}
                dense={dense}
            />
            <MenuItemLink
                to={`/travel_credit_reports`} // by default react-admin renders Dashboard on this route
                primaryText="Travel Credit"
                leftIcon={<DashboardIcon/>}
                //onClick={onMenuClick}
                sidebarIsOpen={true}
                dense={dense}
            />
            {/*<Divider/>*/}
            {canAccess2({resource: 'booking', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/booking',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'General Sales'}`)}
                    leftIcon={<FlightTakeoffIcon/>}
                    dense={dense}
                />
            }
            {canAccess2({resource: 'supplier', action: 'list'}) &&
                <MenuItemLink
                    to={{
                        pathname: '/supplier',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Program Account'}`)}
                    leftIcon={<LibraryBooksIcon/>}
                    dense={dense}
                />
            }
            <MenuItemLink
                to={{
                    pathname: '/hiddenCity',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Hidden City'}`)}
                leftIcon={<BusinessIcon/>}
                dense={dense}
            />
            <MenuItemLink
                to={{
                    pathname: '/invoice',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Invoice'}`)}
                leftIcon={<CreditCardIcon/>}
                dense={dense}
            />


            <SubMenu
                handleToggle={() => handleToggle('menuTickets')}
                isOpen={state.menuTickets}
                name={translate(`${'ADMIN TABLE'}`)}
                //icon={<BusinessIcon/>}
                dense={dense}
            >
                {/*<MenuItemLink
                to={{
                    pathname: '/universal',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Tickets'}`)}
                leftIcon={<BusinessIcon/>}
                dense={dense}
            />*/}
                <MenuItemLink
                    to={{
                        pathname: '/airline',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Airlines'}`)}
                    leftIcon={<LocalAirportIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/creditCard',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Credit Card'}`)}
                    leftIcon={<CreditCardIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/currency',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Currencies'}`)}
                    leftIcon={<AttachMoneyIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/customer',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Customers'}`)}
                    leftIcon={<GroupAddIcon/>}
                    dense={dense}
                />
                {/*<MenuItemLink
                to={{
                    pathname: '/supplierData',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Suppliers'}`)}
                leftIcon={<SupervisorAccountIcon/>}
                dense={dense}
            />*/}
                <MenuItemLink
                    to={{
                        pathname: '/supplierDataIsMiles',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Dealers'}`)}
                    leftIcon={<SupervisorAccountIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/history',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'History'}`)}
                    leftIcon={<HistoryIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/non_flight_transactions',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Non Flight Cat.'}`)}
                    leftIcon={<CreditCardIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/programs',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Programs'}`)}
                    leftIcon={<AccountBalanceWalletIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/passenger',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Passengers'}`)}
                    leftIcon={<AirlineSeatReclineExtraIcon/>}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/supplierDataNotMiles',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Suppliers'}`)}
                    leftIcon={<SupervisorAccountIcon/>}
                    dense={dense}
                />
                {/*<MenuItemLink
                    to={{
                        pathname: '/projects',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Projects'}`)}
                    leftIcon={<AssessmentIcon/>}
                    dense={dense}
                />*/}
                {/*<MenuItemLink
                to={{
                    pathname: '/bookings_passenger',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Bookings Passenger'}`)}
                leftIcon={<AirlineSeatReclineExtraIcon/>}
                dense={dense}
            />*/}
                {/*<MenuItemLink
                to={{
                    pathname: '/bookings_reference',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Bookings Reference'}`)}
                leftIcon={<AirplayIcon/>}
                dense={dense}
            />*/}

                {/*<MenuItemLink
                to={{
                    pathname: '/refund',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Refund'}`)}
                leftIcon={<CreditCardIcon/>}
                dense={dense}
            />*/}
                <MenuItemLink
                    to={{
                        pathname: '/travelCredit',
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`${'Travel Credit'}`)}
                    leftIcon={<CreditCardIcon/>}
                    dense={dense}
                />

                {/*<MenuItemLink
                to={{
                    pathname: '/bank',
                    state: {_scrollToTop: true},
                }}
                primaryText={translate(`${'Banks'}`)}
                leftIcon={<BusinessIcon/>}
                dense={dense}
            />*/}
            </SubMenu>
        </div>
    )
};