import * as React from "react";
import "react-tabulator/lib/css/tabulator.min.css";
import MasterReport from "../masterReport";

const report_fields = ['customer', 'transaction', 'date', ]
const report_group_by = ['customer', ]

const variantsConfig = {
    'default': [[true, false, false, ], [false, ]],
}

const table_columns = [
    // eslint-disable-next-line no-undef
    {title: "Date", field: "date", hozAlign: "center", width: 150, formatter: "date",},
    {title: "Customer", field: "customer", width: 250, responsive: 2}, //hide this column first
    {title: "Transaction", field: "transaction", width: 150, responsive: 2},
    {
        title: "Total amount",
        field: "total_amount",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {
        title: "Invoiced",
        field: "invoiced",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {
        title: "Yet to invoice",
        field: "yet_to_invoice",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {title: "Last booking date", field: "last_booking_date", hozAlign: "center", width: 150, formatter: "date",},
]

const filters = {
    customer: ['Query', false, 'booking/admin/customer', (option) => option.name],
    date_from: ['DateTime', false, ],
    date_to: ['DateTime', false, ],
}


const Invoices = () => {

    return <MasterReport
        report_fields={report_fields}
        report_group_by={report_group_by}
        variantsConfig={variantsConfig}
        table_columns={table_columns}
        report_url='api/reports/invoice'
        filters={filters}
        customFilter={'yet_to_invoice_is_not_0=true'}
    />
};

export default Invoices
