import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    TextInput,
    useRedirect, ReferenceField, TextField, AutocompleteInput, ReferenceInput,
} from 'react-admin';

import {Box, Button, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, TextFieldButton} from "../_common/CompReact";
import {validateFirstName} from "../utils/validateTable";
import useMyStyles from "../utils/useMyStyles";
import ListComponentAdmin from "../_common/ListComponentAdmin";
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import HistoryListForFilter from "../_common/HistoryListForFilter";
// import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {SupplierColumnsForList} from "../supplier/SupplierColumnsForList";
// import {validateFirstName} from "../utils/validateTable";
// import {localesConfig} from "../utils/config";
//import {BasicTabs} from "../_common/BaseTab";

export const ProgramsEditCreate = props => {//Не работает
    const classes = useMyStyles();
    const {record} = props;
    const {id} = record;
    const redirect = useRedirect()
    //console.log('idSource=============>', idSource, record)

    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Title</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="title"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <Box p="1em" fullWidth>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                <TextInput source={`title`} label={'Title'} validate={validateFirstName} fullWidth/>
                            </Box>
                            <Box flex={1}  mr={'2em'}>
                                <ReferenceInput source="airline_id" reference="airline"
                                                //validate={validateReq}
                                                label={'Airline'}
                                                sort={{field: 'title', order: 'ASC'}}
                                >
                                    <AutocompleteInput resettable
                                                       optionText={record => (record && `${record.title} (${record.code})`)}
                                                       fullWidth/>
                                </ReferenceInput>
                            </Box>
                            <Box flex={1}>
                            </Box>
                        </Box>

                        {id &&
                            <>
                                <Typography variant="h6" gutterBottom className={classes.booking_title}>
                                    Program Account
                                </Typography>
                                <Button
                                    onClick={() => {
                                        redirect(`/supplier/create/?programId=${id}`)
                                    }}
                                    color="primary"
                                    label={'Create New'}
                                >
                                    {`Create New Program Account `}
                                </Button>
                                <Box display="flex" alignItems={"center"}>
                                    <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                        <ListComponentAdmin
                                            {...props}
                                            resource={'supplier'}
                                            style={{width: 'max-content'}}
                                            actions={false}
                                            filter={{program_id: id}}
                                            label={' '}
                                            is_bulkActionButtons={true}
                                            sort={{field: 'id', order: 'DESC'}}
                                        >
                                            <DatagridComponentAdmin
                                                {...props}
                                                //rowClick="edit"
                                                rowClick={(id) => redirect(`/supplier/${id}/?programId=${id}`)}>
                                                >
                                                <ReferenceField source="program_id" reference="programs" link={false}
                                                                label={'Program'} sortBy={'programs:title'}>
                                                    <TextField source="title"/>
                                                </ReferenceField>
                                                <TextField source={`account_name`} label={'Account name'}/>
                                                <TextField source={`f_name`} label={'Account First Name'}/>
                                                <TextField source={`l_name`} label={'Account Last Name'}/>
                                                <TextField source={`account_number`} label={'Account number'}/>
                                                <TextField source={`login`} label={'Account Username'}/>
                                                {/*<TextField source={`password`} label={'Password'}/>*/}
                                                <TextFieldButton
                                                    //itsOriText={record => (`${record.last_name}`)}
                                                    copyClipBoard={true}
                                                    source="password"
                                                    label={'Password'}
                                                    pasw={true}
                                                    bold={false}
                                                />
                                                <TextField source={`pin`} label={'Pin'}/>
                                                <TextField source={`email_login`} label={'Email Connected to Account'}/>
                                                {/*<TextField source={`email_password`} label={'Email Password'}/>*/}
                                                <TextFieldButton
                                                    //itsOriText={record => (`${record.last_name}`)}
                                                    copyClipBoard={true}
                                                    source="email_password"
                                                    label={'Email Password'}
                                                    pasw={true}
                                                    bold={false}
                                                />
                                                <ReferenceField source="supplier_id" reference="supplierData"
                                                                label={'Dealer'} link={false}
                                                                sortBy={'supplierData:title'}>
                                                    <TextField source="title"/>
                                                </ReferenceField>
                                                <TextField source={`cost`} label={'Cost (mi)'}/>
                                            </DatagridComponentAdmin>
                                        </ListComponentAdmin>
                                    </Box>
                                </Box>
                            </>
                        }

                    </Box>
                </FormTab>
                {id &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'program'} fullWidth/>
                    </FormTab>
                }
            </TabbedForm>
        </>
    )
};

export default ProgramsEditCreate
