import * as React from "react";
import "react-tabulator/lib/css/tabulator.min.css";
import MasterReport from "../masterReport";

const report_fields = ['year', 'month', 'customer', 'booking', 'transaction', 'date']
const report_group_by = ['year', 'month', 'customer', 'booking']

const variantsConfig = {
    Receivable: [[false, false,true, false, false, false], [false, false,false, false]],
    'Receivable by month': [[true, true,true, false, false, false], [true, true,false, false]],
    "Receivable detail": [[false, false,true, true, false, false], [false, false,true, false]],
    "Payment by booking": [[false, false,true, true, true, true], [false, false, false, true]],
}

const table_columns = [
    // eslint-disable-next-line no-undef
    {title: "Year", field: "year", width: 200, responsive: 0}, //never hide this column
    {title: "Month", field: "month", width: 100, sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
        //a, b - the two values being compared
        //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
        //column - the column component for the column being sorted
        //dir - the direction of the sort ("asc" or "desc")
        //sorterParams - sorterParams object from column definition array
        // debugger
        return aRow._row.data.month_num - bRow._row.data.month_num; //you must return the difference between the two values
    },},
    {title: "Customer", field: "customer", width: 150, responsive: 2}, //hide this column first
    {title: "Booking", field: "booking", width: 150, responsive: 2}, //hide this column first
    {title: "Transaction", field: "transaction", width: 150, responsive: 2}, //hide this column first
    {title: "Date", field: "date", hozAlign: "center", width: 150, formatter: "date",},
    {
        title: "Amount",
        field: "amount",
        hozAlign: "right",
        width: 150,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
]

const filters = {
    booking: ['Query', false, 'booking/admin/booking', (option) => `Booking ${option.id}`],
    customer: ['Query', false, 'booking/admin/customer', (option) => option.name],
    date_from: ['DateTime', false, ],
    date_to: ['DateTime', false, ],
}


const Receivable = () => {

    return <MasterReport
        report_fields={report_fields}
        report_group_by={report_group_by}
        variantsConfig={variantsConfig}
        table_columns={table_columns}
        report_url='api/reports/receivable'
        filters={filters}
        additionalOptions={{
            initialSort:[
                {column:"month", dir:"asc"}, //sort by this first
            ]
        }}
        customFilter={'amount_is_not_0=true'}
    />
};

export default Receivable
