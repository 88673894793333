import * as React from 'react';
import {useCallback} from 'react';
//import DownloadIcon from '@material-ui/icons/GetApp';
import {
    //fetchRelatedRecords,
    useNotify,
    useListContext,
} from 'ra-core';
import {Button} from "@material-ui/core";
import {apiPathConfig} from "./config";
import {fetchJson as httpClient} from "./keycloak";
import DownloadIcon from "@material-ui/icons/GetApp";
import {saveAs} from 'file-saver';
import moment from "moment";
import {useRefresh} from "react-admin";

const defaultIcon = <DownloadIcon/>;

const HandlerCreateCsvInvoice = (props) => {
    const {
        onClick,
        label,
        table,
        type = 'bill',
        //icon=false,
        //exporter: customExporter,
    } = props;
    const {
        //exporter: exporterFromContext,
        resource,
        selectedIds,
        filterValues,
    } = useListContext(props);

    //const exporter = customExporter || exporterFromContext;
    const notify = useNotify();
    const refresh = useRefresh();

    const getData = async () => {
        const url = apiPathConfig.getApiUrl()
        //let report_url = `booking/admin/currency`;

        let report_url = `api/common/invoice/download_invoices`;
        if (table === 'hiddenCity') {
            report_url = `api/common/invoice/download_hidden_city`;
        }
        if (table === 'booking') {
            report_url = `api/common/invoice/download_bookings`;
            if (type === 'refund') report_url = `${report_url}?refunds_only=true`;
        }

        let data = {
            selectedIds,
            filterValues
        }
        console.log('===data===========>', data)
        try {
            //const response = await httpClient(`${url}${report_url}`);
            const response = await httpClient(`${url}${report_url}`, {
                method: 'post',
                body: JSON.stringify(data.selectedIds)
                /*body: JSON.stringify({
                    b64: base64
                })*/
            })
            if (response.status >= 200 && response.status <= 300) {
                const {json: dt, body: bodyStr} = response
                const {message} = dt || {}
                //console.log('response=========>', bodyStr);
                if (message) {
                    //alert(response.json.message)
                    notify(message, {type: 'info', autoHideDuration: 8000,});
                } else {
                    let filename = 'invoice' + moment(new Date()).format('_YYYYMMDD_HH_mm') + '.csv'
                    if (table === 'hiddenCity') {
                        filename = 'hiddenCity' + moment(new Date()).format('_YYYYMMDD_HH_mm') + '.csv'
                    }
                    if (table === 'booking') {
                        filename = 'bookingBill' + moment(new Date()).format('_YYYYMMDD_HH_mm') + '.csv'
                        if (type === 'refund') filename = 'bookingRefund' + moment(new Date()).format('_YYYYMMDD_HH_mm') + '.csv'
                    }
                    saveAs(new Blob([bodyStr]), filename);
                    notify(`File ${filename} successfully created`, {type: 'info', autoHideDuration: 8000,});
                    refresh();
                }
            } else {
                notify(`ERROR ${response.status}`, {type: 'error', autoHideDuration: 8000,});
            }
        } catch (err) {
            console.log('err=======>', err);
            notify(err, {type: 'error',});
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }

    /*useEffect(() => {
         if (result) {
         }
     }, [result]);// eslint-disable-line*/

    const handleClick = useCallback(
        event => {
            getData();
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notify, onClick, resource, selectedIds]
    );

    return (
        <>
            <Button
                style={{marginLeft: selectedIds.length > 0 && 24, marginTop: selectedIds.length > 0 && -4}}
                color={"primary"}
                onClick={handleClick}
                label={label}
            >
                {defaultIcon}{label}
            </Button>
        </>
    );
};

export default HandlerCreateCsvInvoice;
