import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    TextInput,
    // useLocale,
    //NumberInput,
    //BooleanInput,
    AutocompleteInput, ReferenceInput,
    PasswordInput,
    ReferenceField, NumberInput,
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, GetFieldById, NumberInputFloatComp, TextFieldButton} from "../_common/CompReact";
import useMyStyles from "../utils/useMyStyles";
import {validateReq} from "../utils/validateTable";
import HistoryListForFilter from "../_common/HistoryListForFilter";
// import {validateFirstName} from "../utils/validateTable";
// import {localesConfig} from "../utils/config";
//import {BasicTabs} from "../_common/BaseTab";

export const SupplierEditCreate = props => {//Не работает
    const classes = useMyStyles();
    const {record, supplierId, programId} = props;
    const {id} = record;
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Program name</Typography>
                            <ReferenceField source="program_id" reference="programs">
                                <TextFieldButton
                                    //itsOriText={record => (`${record.last_name}`)}
                                    //copyClipBoard={true}
                                    source="title"
                                    backgroundColor={'#eee'}
                                />
                            </ReferenceField>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <Box p="1em" fullWidth>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                {programId
                                    ?
                                    <>
                                        <div className={classes.cost_show_field}>
                                            <div>
                                                <span style={{fontSize: 12}}>{'Program Name'}</span>
                                            </div>
                                            <div>
                                                <GetFieldById id={programId} resource={'programs'}
                                                              field={'title'}/>
                                            </div>
                                        </div>
                                        <Box hidden><NumberInput source={'program_id'} defaultValue={programId}/></Box>
                                    </>
                                    :
                                    <ReferenceInput
                                        sort={{field: 'title', order: 'ASC'}}
                                        source="program_id" reference="programs" label={'Program Name'}
                                        validate={validateReq}
                                    >
                                        <AutocompleteInput resettable optionText="title" fullWidth/>
                                    </ReferenceInput>
                                }
                            </Box>
                            {supplierId
                                ?
                                <Box flex={1} mt={'-18px'}>
                                    <div className={classes.cost_show_field}>
                                        <div>
                                            <span style={{fontSize: 12}}>{'Dealer Name (is miles)'}</span>
                                        </div>
                                        <div>
                                            <GetFieldById id={supplierId} resource={'supplierData'} field={'title'}/>
                                        </div>
                                    </div>
                                    <Box hidden><NumberInput source={'supplier_id'} defaultValue={supplierId}/></Box>
                                </Box>
                                :
                                <Box flex={1}>
                                    <ReferenceInput source="supplier_id" reference="supplierData"
                                                    validate={validateReq}
                                                    filter={{is_miles: true}} label={'Dealer Name (is miles)'}>
                                        <AutocompleteInput
                                            resettable
                                            optionText={record => (record && `${record.title} ${record.is_miles ? ' (is miles)' : ''}`)}
                                            //optionText="title"
                                            fullWidth
                                        />
                                    </ReferenceInput>
                                </Box>
                            }
                        </Box>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="account_name" label={'Account name'} validate={validateReq}
                                           fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="pin" label={'Pin'} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="f_name" label={'Account First Name'} fullWidth/>
                            </Box>
                            <Box flex={1}>
                                <TextInput source="l_name" label={'Account Last Name'} fullWidth/>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="account_number" label={'Account number'} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <NumberInputFloatComp source="cost" label={'Cost (mi)'} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                            </Box>
                            <Box flex={1}>
                                {/*<ReferenceInput source="currency_id" reference="currency" label={"Currency"}>
                                    <AutocompleteInput resettable
                                                       optionText={record => (record && `(${record.code}) ${record.title}`)}
                                                       fullWidth/>
                                </ReferenceInput>*/}
                            </Box>
                        </Box>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="login" label={'Login'} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <PasswordInput source="password" label={'Password'} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="email_login" label={'Email Login'} fullWidth/>
                            </Box>
                            <Box flex={1}>
                                <PasswordInput source="email_password" label={'Email password'}
                                               fullWidth/>
                            </Box>
                        </Box>
                    </Box>
                </FormTab>
                {/*<FormTab label="History">
                    <HistoryListForFilter {...props} table={'program_account'} fullWidth/>
                </FormTab>*/}
                {id && !supplierId && !programId &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'program_account'} fullWidth/>
                    </FormTab>
                }
            </TabbedForm>
        </>
    )
};

export default SupplierEditCreate
