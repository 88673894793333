import * as React from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    TextInput, TextField, BooleanInput, BooleanField, ReferenceManyField,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";
import {Box} from "@material-ui/core";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {validateReq} from "../utils/validateTable";

const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const{booking_id} = props;
    /*const {getValues} = useFormContext();
    console.log(booking_id, '=====OK======>', getValues("id"))*/
    return (
        <RowForm  {...props}>
            {/*<RowForm {...props}>*/}
            {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
            {/*<ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'} label={'Route'}>
                <Box display={'grid'}>
                    <TextField source="route"/>
                </Box>
            </ReferenceField>*/}
            <BooleanInput source={`is_main`} label={'Display'} defaultValue={false} validate={validateReq}/>
            <ReferenceInput
                source="airline_id" reference="airline" label={'Airline'}
                sort={{field: 'title', order: 'ASC'}} validate={validateReq} fullWidth>
                <AutocompleteInput
                    resettable
                    optionText={record => (record && `${record.title} (${record.code})`)}/>
            </ReferenceInput>
            <TextInput source="pnr" label={'PNR'} validate={validateReq}/>
            <Box hidden={true}><TextInput source={'booking_id'} defaultValue={booking_id}/></Box>
        </RowForm>
    )
};

export const BookingsReferenceListForTab2 = props => {
    const {booking_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    return (
        <div style={{width: "max-content", minWidth: 500, marginTop: -16}}>
            <ReferenceManyField
                fullWidth
                label=" "
                reference="new_bookings_reference"
                target="booking_id"
            >
                <EditableDatagrid
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={true}
                    /*onChange={(data) => {
                        console.log('===fdsBookingForm==========>')
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    }}*/
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm  submitOnEnter={false} booking_id={booking_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <BooleanField source={`is_main`} label={'Display'}/>
                    <ReferenceField source="airline_id" reference="airline" link={false}
                                    sortBy={'airline:code'} label={'Airline'}>
                        <Box display={'grid'}>
                            <TextField source="code"/>
                        </Box>
                    </ReferenceField>
                    <TextField source="pnr" label={'PNR'}/>
                </EditableDatagrid>
            </ReferenceManyField>
        </div>
    )
};