import * as React from "react";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import {Title} from 'react-admin';
// import Profit from "./reports/profit";
import ReportTabs from "./reports/tab";


const Dashboard = () => (<>
    <ReportTabs type={'reports'}/>
    {/*<Card>*/}
    {/*    <Title title=" " />*/}
    {/*    <CardContent><Profit /></CardContent>*/}
    {/*</Card>*/}
</>);

export default Dashboard