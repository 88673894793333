import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    TextField,
    //useRecordContext,
    ReferenceField, BooleanField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldBold, TextFieldButton} from "../_common/CompReact";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
// import {commonYesNo} from "../utils/choices";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
// import get from 'lodash/get';
//import {Box} from "@material-ui/core";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
// import {titleMrMs} from "../utils/choices";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";

export const PassengerColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        customer:
            <ReferenceField source="customer_id" reference="customer" sortBy={'customer:name'}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="name"
                />
            </ReferenceField>,
        is_main: <BooleanField source="is_main" label={'Is main'}/>,
            /*<SelectAndSaveChoices
                label={'Is main'}
                source="is_main"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
            />,*/
        title: <TextField source={`title`} label={'Title'}/>,
        /*passenger:
            <Box display={'grid'} label={'passenger'} sortBy={`l_name`}>
                <Box><TextFieldBold source={`l_name`} label={'Last name'}/></Box>
                <Box><TextFieldBold source={`f_name`} label={'First name'}/></Box>
            </Box>,*/
        f_name: <TextFieldBold source={`f_name`} label={'First name'}/>,
        m_name: <TextFieldBold source={`m_name`} label={'Middle name'}/>,
        l_name: <TextFieldBold source={`l_name`} label={'Last name'}/>,
        b_day: <DateFieldComp source="b_day" label={'Birthday'} textAlign={'center'} cellClassName={classes.date_st}/>,

        /*email: <EmailField source={`email`} label={'email'}/>,
        mobile: <TextField source={`mobile`} label={'mobile'}/>,
        address: <TextField source={`address`} label={'address'}/>,*/

        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'} cellClassName={classes.date_st}/>,
    }
    return columns;
};
