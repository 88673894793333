//import * as React from 'react';
//import {TextInput} from 'react-admin';

//import {AutocompleteInput, ReferenceInput} from "react-admin";

export const CustomerTopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="customer_id" reference="customer" alwaysOn>
            <AutocompleteInput optionText="title" resettable emptyText={'All customers'}/>
        </ReferenceInput>);*/
    // dt.push(<TextInput label="Search" source="q" alwaysOn />)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!" />);
    // dt.push(<TextInput label="Customer" source="customer"/>)
    // dt.push(<TextInput label="Passenger name" source="passanger_name"/>)
    // dt.push(<TextInput label="Airline" source="airline"/>)
    // dt.push(<TextInput label="Program Name" source="programName"/>)
    return dt;
}
