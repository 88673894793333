//import * as React from 'react';
//import {TextInput} from 'react-admin';

import {AutocompleteInput, ReferenceInput} from "react-admin";
import {DateInputComp} from "../_common/CompReact";
import * as React from "react";

export const TravelCreditTopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="booking_id" reference="booking" alwaysOn>
        <AutocompleteInput optionText={record => (record && record.id ? `${record.route} #${record.id}` : 'All Booking')} resettable emptyText={'All Booking'}/>
    </ReferenceInput>);
    dt.push(<DateInputComp source={"expire_date_gte"} label={"Expire date from"} fullWidth/>);
    dt.push(<DateInputComp source={"expire_date_lte"} label={"Expire date to"} fullWidth/>);

    // dt.push(<TextInput label="Search" source="q" alwaysOn />)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!" />);
    // dt.push(<TextInput label="Customer" source="customer"/>)
    // dt.push(<TextInput label="Passenger name" source="passanger_name"/>)
    // dt.push(<TextInput label="Airline" source="airline"/>)
    // dt.push(<TextInput label="Program Name" source="programName"/>)
    return dt;
}
