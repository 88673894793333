import * as React from 'react';
import {
    BooleanInput,
    TabbedForm,
    FormTab,
    AutocompleteInput, ReferenceInput, NumberInput, ReferenceField
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {
    DateFieldComp,
    DateInputComp,
    GetFieldById,
    NumberInputFloatComp,
    TextFieldButton
} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";
import useMyStyles from "../utils/useMyStyles";

export const TravelCreditEditCreate = props => {//Не работает
    const classes = useMyStyles();
    const {record, bookingId} = props;
    const {id} = record;
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Route</Typography>
                            <ReferenceField source="booking_id" reference="booking">
                                <TextFieldButton
                                    //itsOriText={record => (`${record.last_name}`)}
                                    //copyClipBoard={true}
                                    source="route"
                                    backgroundColor={'#eee'}
                                />
                            </ReferenceField>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                <FormTab label="Common">
                    <Box p="1em" fullWidth>

                        <Box display="flex" alignItems={"center"}>
                            {bookingId
                                ?
                                <Box flex={1} mr={'2em'} mb={'1em'}>
                                    <div className={classes.cost_show_field}>
                                        <div>
                                            <span style={{fontSize: 12}}>{'Booking'}</span>
                                        </div>
                                        <div>
                                            <GetFieldById id={bookingId} resource={'booking'} field={'route'}/>
                                        </div>
                                    </div>
                                    <Box hidden><NumberInput source={'booking_id'} defaultValue={bookingId}/></Box>
                                </Box>
                                :
                                <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                    <ReferenceInput source="booking_id" reference="booking" validate={validateReq}>
                                        <AutocompleteInput resettable optionText={record => (record && record.id ? `${record.route} #${record.id}` : 'All Booking')} fullWidth/>
                                    </ReferenceInput>
                                </Box>
                            }
                            <Box flex={1}>
                                <DateInputComp source={"expire_date"} label={"Expire date"} validate={validateReq}
                                               fullWidth/>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'2em'}>
                                <NumberInputFloatComp source="amount_dollar" label={'Amount ($)'} validate={validateReq}
                                                      fullWidth/>
                            </Box>
                            <Box flex={1}>
                                <BooleanInput source="is_used" label={'Is used'}
                                             validate={validateReq} fullWidth/>
                            </Box>
                        </Box>

                    </Box>
                </FormTab>
            </TabbedForm>
        </>
    )
};

export default TravelCreditEditCreate
