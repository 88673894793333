//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
// import * as React from 'react';
import React, {Fragment} from 'react';
import {
    SimpleList,
    TextField,
    useLocale,
    //EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';
import SupplierListAside from "./SupplierListAside";

import '../index.css';
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
import {SupplierTopFilters} from './SupplierTopFilters'
import {SupplierColumnsForList} from "./SupplierColumnsForList";
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
import {useEffect} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";

/*const useImageFieldStyles = makeStyles(theme => ({
    image: {
        width: 50,
        height: 50,
    }
}));
import {makeStyles} from '@material-ui/core/styles';*/
/*
const PostShow = () => (
    <SimpleShowLayout>
        <TextField source={`programName`} label={'program name'}/>
        <TextField source={`account_last_name`} label={'account last name'}/>
        <TextField source={`account_first_name`} label={'account first name'}/>
        <TextField source={`account_number`} label={'account number'}/>
        <TextField source={`password`} label={'password'}/>
        <TextField source={`dob`} label={'dob'}/>
        <TextField source={`phone`} label={'phone'}/>
        <TextField source={`cvc`} label={'cvc'}/>
        <TextField source={`exp`} label={'exp'}/>
        <EmailField source={`email`} label={'email'}/>
        <TextField source={`email_pasw`} label={'email password'}/>
        <EditCommentFieldFromList source={`notes_program`} label={'notes program'} component={'text'} long={'200px'}/>
    </SimpleShowLayout>
);
*/


export const SupplierList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const getAirlines = async () => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `booking/admin/airline?&limit=100&ordering=code&skip=0`;
            try {
                const response = await httpClient(`${url}${report_url}`);
                if (response.status >= 200 && response.status <= 300) {
                    console.log('response=======>', response.json.results);
                    response.json.results.unshift({id: 0, code: 'All Airlines', title: 'All Airlines'})
                    setAllAirlines(response.json.results)
                }
            } catch (err) {
                //console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
                //refresh();
            }
        }

    ;
    const [airline, setAirline] = React.useState(
        localStorage.getItem('fdsProgramAccountFilterAirline')
            ? +localStorage.getItem('fdsProgramAccountFilterAirline')
            : 0
    );
    const [allAirlines, setAllAirlines] = React.useState([]);


    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: '#fefefe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: SupplierColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    useEffect(() => {
        getAirlines();
    }, []) // eslint-disable-line

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={' '}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={SupplierColumnsForList(resource, locale, classes)}
                permissions={permissions}
                asideFilter={true}
                // filter={false}
            />}
            filters={SupplierTopFilters(resource, locale)}
            filter={airline ? {airline: airline} : false}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<SupplierListAside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                <Fragment>
                    {allAirlines && allAirlines.length > 0 &&
                        <Tabs
                            fullWidth
                            centered
                            value={airline}
                            indicatorColor="primary"
                            onChange={(event, value) => {
                                localStorage.setItem('fdsProgramAccountFilterAirline', value);
                                setAirline(value)
                            }}
                            style={{justifyContent: "flex-start"}}
                        >
                            {allAirlines.map(choice => (
                                <Tab
                                    key={choice.id}
                                    label={choice.code}
                                    value={choice.id}
                                    style={{minWidth: 32}}
                                    //icon={`${'https://www.gstatic.com/flights/airline_logos/35px/'}${carrier.operating}${'.png'}`}
                                />
                            ))}
                        </Tabs>
                    }
                    <DatagridComponentAdmin {...props} rowClick="edit">
                        {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                        {columns}
                    </DatagridComponentAdmin>
                </Fragment>
            }
        </ListComponentAdmin>
    )
}