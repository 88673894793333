import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    TextField,
    //useRecordContext,
    ReferenceField, ArrayField, SingleFieldList, BooleanField, useRecordContext,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldButton} from "../_common/CompReact";
import {FloatNumber} from "../booking/BookingColumnsForList";
import {commonTrueFalse, hidden_city_flight_status} from "../utils/choices";
import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
import get from "lodash/get";
import moment from "moment";
import {Box} from "@material-ui/core";

const GetLastFlight = (props) => {
    //const {type} = props;
    const record = useRecordContext(props);
    let dt = ''; let route = '';
    let last_flight = get(record, 'last_flight', null);
    if (last_flight && last_flight.length > 0) {
        // res = moment(last_flight[last_flight.length - 1].travel_date).format("D-MMM-YY")
        dt = moment(last_flight[0].travel_date).format("D-MMM-YY")
        route = last_flight[0].route;
    }
    return (<div style={{display: "grid"}}><span>{route}</span><span>{dt}</span></div>)
}

const IfCredit = (props) => {
    const {source} = props;
    const record = useRecordContext(props);
    let flight_status = get(record, 'flight_status', null);
    //res = moment(last_flight[last_flight.length - 1].travel_date).format("D-MMM-YY")
    if (flight_status === 'refund_to_airlines_credits') {
        if (source === 'expire_date') return (
            <DateFieldComp source="expire_date" textAlign={'center'}/>
        )
        else return (
            <BooleanField source={'bookman_property'} textAlign={"center"}/>
        )

    } else return null
}

export const HiddenCityColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <Box sortBy={'id'} label={'HC\u00a0ID'}>
            {'HC\u00a0'}<NumberField source="id" />
        </Box>,
        booking_id: <Box sortBy={'booking_id'} label={'Booking ID'}>
            <NumberField source="booking_id" />
        </Box>,

        passenger_name: <ArrayField source="passenger_name"
                                    label={'Passenger Name'} sortable={false}>
            <SingleFieldList style={{display: "inherit"}} linkType={false}>
                <>
                    <TextField source="title"/>{' '}
                    <TextField source="f_name"/>{' '}
                    <TextField source="l_name"/>
                </>
            </SingleFieldList>
        </ArrayField>,
        booking:
            <ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'}
                            cellClassName={classes.route_st}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="route"
                />
            </ReferenceField>,

        hc_travel_date: <DateFieldComp source="hc_travel_date" label={'Travel Date'} textAlign={"center"}
                                       cellClassName={classes.date_st} showTime={false} locale={'us'}/>,
        hc_route: <TextField source={`hc_route`} label={'Travel Route'} cellClassName={classes.route_st}/>,
        hc_flight_number: <TextField source={`hc_flight_number`} label={'Flight Number'}
                                     cellClassName={classes.flight_number_st}/>,

        last_flight: /*<TextField source={'last_flight'} label={'Last Flight'}/>,*/
        /*<Box label={'Username'} sortBy={'username'} style={{display: "grid"}}>*/
            <GetLastFlight
                sortBy={'last_flight_date'}
                label={'Last Flight'}
                cellClassName={classes.date_route_st}
                textAlign={"center"}
            />
        /*</Box>*/,

        is_load: /*<BooleanField source={`is_paid`} label={'Is Paid'} cellClassName={classes.paid_st}/>,*/
            <SelectAndSaveChoices
                label={'Exported to Xero'}
                source="is_load"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
            />,

        flight_status:
            <SelectAndSaveChoices
                label={'Refund Status'}
                //label={'Flight Status'}
                source="flight_status"
                statuses={hidden_city_flight_status}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'11em'}
            />,

        expire_date: <IfCredit source={"expire_date"} label={"Expire Date"} cellClassName={classes.date_st}/>,
        bookman_property: /*<BooleanField source={'bookman_property'} label={'Bookman Property'} textAlign={"center"}/>,*/
            <IfCredit source={"bookman_property"} label={"Bookman Property"} textAlign={"center"}/>,

        refunded_date: <DateFieldComp source="refunded_date" label={'Refunded date'} textAlign={"center"}
                                      cellClassName={classes.date_st} showTime={false} locale={'us'}/>,
        refund: <FloatNumber source={`refund`} label={'Dead-end Refund'} cellClassName={classes.quantity_st}
                             textAlign={"right"}/>,
        notes: <TextField source={'notes'} label={'Notes'}/>,
        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};
