import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {SupplierList} from "./SupplierList";
import {SupplierEdit} from "./SupplierEdit";
import {SupplierCreate} from "./SupplierCreate";

const resource = {
    list: SupplierList,
    create: SupplierCreate,
    edit: SupplierEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;