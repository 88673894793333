import * as React from 'react';
import {
    BooleanField,
    NumberField,
    //ReferenceField,
    TextField,
    useRecordContext,
    EmailField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldBold} from "../_common/CompReact";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
// import {commonYesNo} from "../utils/choices";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
import get from 'lodash/get';
import {Box} from "@material-ui/core";
import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";

const Total = ({props}) => {
    //const {source} = props;
    let result = null;
    const record = useRecordContext(props);
    let qty = get(record, "qty", null);
    let price = get(record, "price", null);
    let taxes = get(record, "taxes", null);
    let cancelation = get(record, "cancelation", null);
    //console.log(qty, '=======>', price)
    if (price) {
        if (qty) result = price * qty;
        else result = price;
        if(taxes) result += taxes;
        if(cancelation) result += cancelation;
    }
    return (
        <span>{result.toFixed(3)}</span>
    );
}

export const universalColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        paid: <BooleanField source={`is_paid`} label={'Is paid'}/>,
        customer: <TextFieldBold source={`customer`} label={'customer'}/>,
        booking_date: <DateFieldComp source="booking_date" label={'booking date'} textAlign={'center'} showTime={false}
                                     locale={'us'}/>,
        airline: <TextField source={`airline`} label={'airline'}/>,
        cost: <NumberField source={'cost'} label={'cost'} headerClassName={classes.colorCost} cellClassName={classes.colorCost}/>,
        price: <NumberField source={'price'} label={'price'}/>,
        qty: <NumberField source={`qty`} label={'qty'}/>,
        taxes: <NumberField source={'taxes'} label={'taxes'}/>,
        cancelation: <NumberField source={'cancelation'} label={'Changes/ Cancelation/ Luggage (CC Fees)'}/>,
        total: <Total label={'total'}/>,
        ccy: <TextField source={`ccy`} label={'ccy'}/>,

        title: <TextField source={`title`} label={'title'} headerClassName={classes.borderLeftRed} cellClassName={classes.borderLeftRed}/>,
        passenger:
            <Box display={'grid'} label={'Passenger'} sortBy={`passanger_name`}>
                <Box><TextField source={`passanger_name`} label={'Passenger name'}/></Box>
                <Box><TextField source={`passanger_first_name_initial`} label={'Passenger first name initial'}/></Box>
            </Box>,
        // passenger_name: <TextField source={`passanger_name`} label={'Passenger name'}/>,
        // passenger_first_name_initial: <TextField source={`passanger_first_name_initial`} label={'Passenger first name initial'}/>,

        booking_ref: <TextFieldBold source={`booking_ref`} label={'PNR / Booking Ref'} headerClassName={classes.borderLeftRed} cellClassName={classes.borderLeftRed}/>,
        ticket_number: <TextField source={`ticket_number`} label={'ticket number'}/>,
        travel_date: <DateFieldComp source="travel_date" label={'travel date'} textAlign={'center'} showTime={false}
                                    locale={'us'}/>,
        travel_route: <TextField source={`travel_route`} label={'travel route'}/>,
        single_rtn: <TextField source={`single_rtn`} label={'Single / Rtn'}/>,
        airline_find: <TextField source={`airline_find`} label={'airline'}/>,
        flight_number: <TextField source={`flight_number`} label={'flight number'}/>,
        class: <TextField source={`class`} label={'class'}/>,
        miles_only: <BooleanField source={`miles_only`} label={'miles only'}/>,
        notes_ticket: /*<TextField source={`notes_ticket`} label={'notes ticket'}/>,*/
                    <EditCommentFieldFromList source={`notes_ticket`} label={'notes ticket'} component={'text'} long={'200px'}/>,

        programName: <TextFieldBold source={`programName`} label={'program name'} headerClassName={classes.borderLeftRed} cellClassName={classes.borderLeftRed}/>,
        account:
            <Box display={'grid'} label={'account'} sortBy={`account_last_name`}>
                <Box><TextField source={`account_last_name`} label={'account last name'}/></Box>
                <Box><TextField source={`account_first_name`} label={'account first name'}/></Box>
            </Box>,
        account_number: <TextField source={`account_number`} label={'account number'}/>,
        username: <TextField source={`programName`} label={''}/>,
        password: <TextField source={`password`} label={'password'}/>,
        dob: <TextField source={`dob`} label={'dob'}/>,
        phone: <TextField source={`phone`} label={'phone'}/>,
        cvc: <TextField source={`cvc`} label={'cvc'}/>,
        exp: <TextField source={`exp`} label={'exp'}/>,
        email: <EmailField source={`email`} label={'email'}/>,
        email_pasw: <TextField source={`email_pasw`} label={'email_pasw'}/>,
        notes_program: /*<TextField source={`notes_ticket`} label={'notes ticket'}/>,*/
                    <EditCommentFieldFromList source={`notes_programm`} label={'notes program'} component={'text'} long={'200px'}/>,

        /*brand:
            <Box display={'grid'} label={'brand'} sortBy={`brand`}>
                <Box><TextFieldBold source={`brand`} label={'brand'}/></Box>
            </Box>,*/
        /*synonym: <TextField source={`synonym`} label={'synonym'}/>,*/
        /*url_part: <TextField source={`url_part`} label={'url_part'}/>,*/
        /*source: <TextField source={`source`} label={'source'}/>,*/
        /*car_type: <TextField source={`car_type`} label={'car_type'}/>,*/
        /*popular:
            <SelectAndSaveChoices
                label={'popular'}
                source="popular"
                statuses={commonYesNo}
                resource={resource}
                textAlign={'center'}
                //showTextValue={true}
                width={'4em'}
            />,*/
        /*show:
            <SelectAndSaveChoices
                label={'show'}
                source="show"
                statuses={commonYesNo}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'4em'}
            />,*/
        /*sort_desktop: <EditCommentFieldFromList source="sort_desktop" component={'int'} long={'60px'}/>,*/
        /*sort_mobile: <EditCommentFieldFromList source="sort_mobile" component={'int'} long={'60px'}/>,*/
        /*difficulty:
            <SelectAndSaveChoices
                label={'Difficulty'}
                source="attributes.difficulty"
                statuses={difficultyQuestion}
                resource="questions"
                textAlign={'center'}
                showTextValue={true}
                width={'8em'}
            />,*/
        // is_active: <BooleanField source="attributes.is_active" label={'is_active'} textAlign={'center'}/>,
        /*correct_answer:
            <SelectAndCreate
                filterForThisId={'question'}
                refreshTable={true}
                showTextValue={true}
                reference="answers"
                source="correct_answer"
                fieldsForMenu={'answer'}
                textAlign={'center'}
                //width={'8em'}
            />,*/
        /*location: <ReferenceField source="location_id" reference="locating" textAlign={"left"}>
            <TextField source="location"/>
        </ReferenceField>,*/
    }
    return columns;
};
