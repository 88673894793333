import * as React from 'react';
import {
    Create, useCreate, useRedirect
} from 'react-admin';

import PassengerEditCreate from "./PassengerEditCreate";
import PostEditActions from "../_common/PostEditActions";

export const PassengerCreate = props => {
    const {location} = props;
    const {search} = location;

    const redirect = useRedirect()

    let customerId = '';
    let bookingId = '';
    if(search){
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
        bookingId = url.searchParams.get('bookingId')
    }
    // const redirect = useRedirect()

    const [create] = useCreate();
    const onSuccessData = (data) => {
        const {data: dataRes} = data;
        const {id: idRes} = dataRes || {};
        //console.log('DATA======>', idRes)
        if (bookingId && idRes) {
            create('bookings_passenger', {booking_id: bookingId,  passenger_id: idRes})
            redirect(`/booking/${bookingId}`)
        } else props.history.goBack();
    };

    return (
        <Create {...props}
            title={' '}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={() => props.history.goBack()}
            onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <PassengerEditCreate customerId={customerId}/>
        </Create>
    )
};