import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {HiddenCityList} from "./HiddenCityList";
import {HiddenCityEdit} from "./HiddenCityEdit";
import {HiddenCityCreate} from "./HiddenCityCreate";

const resource = {
    list: HiddenCityList,
    create: HiddenCityCreate,
    edit: HiddenCityEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;