// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// import fetch from 'cross-fetch';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";



export default function Asynchronous(props) {
    const {setValue, url, getOptionLabel, label = '---'} = props

    const [open, setOpen] = React.useState(false);
    const [valueTyping, setValueTyping] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // const loading = open && options.length === 0;


    const domain = apiPathConfig.getApiUrl()
    const fetchQuery = `${domain}${url}?limit=25&ordering=-id&skip=0`

    const handleChange = (event) => {
        setValueTyping(event.target.value)
        // debugger
    }
    const handleChangeA = (event, newInputValue) => {
        setValue(newInputValue.id)
    }

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await httpClient(`${fetchQuery}&search=${valueTyping}`);
            // await sleep(1e3); // For demo purposes.
            const res = response.json;
            const results = res.results
            // debugger
            setOptions(Object.keys(results).map((key) => results[key]));
            setLoading(false)
        })();
    }, [setLoading, fetchQuery, valueTyping]);

    // React.useEffect(() => {
    //     if (!open) {
    //         setOptions([]);
    //     }
    // }, [open]);

    return (
        <Autocomplete
            id={`${url}-id-autocomplete`}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            // getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={getOptionLabel}
            options={options}
            onChange={handleChangeA}
            // onSelect={handleChangeA}
            loading={loading}
            filterOptions={(option, state) => {
                                return option
                            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={valueTyping}
                    onChange={handleChange}
                    size={"small"}
                    label={ label }
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        //style: {padding: "0px"},
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}