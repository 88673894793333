import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField,
    //TextField,
    useLocale,
    TextInput,
} from 'react-admin';

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import useMyStyles from "../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent} from "@material-ui/core";
import CardComp from "../_common/CardComp";
import {DateFieldComp, FilterDataAside, TextFieldBold} from "../_common/CompReact";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const ListEditForm = (props) => {
    // const {classes, locale} = props;
    const {classes} = props;
    return (
        <RowForm {...props}>
            <NumberField source="id" headerClassName={classes.headerShortWidth}/>
            <TextInput source={`title`} label={'title'} fullWidth/>
            <DateFieldComp source="created_at" label={'create'} textAlign={'center'} showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>
            <DateFieldComp source="updated_at" label={'update'} textAlign={'center'} showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>
        </RowForm>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

export const Bank = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={' '}
            actions={<TableActions
                preference={`${resource}${".list.columns"}`}
                select={false}
                create={true}
                filter={true}
            />}
            // is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            <EditableDatagrid
                classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                //noDelete={true}
                // initialRowMode="edit"
                rowClick="edit"
                mutationMode="pessimistic" //Подтвердить удаление
                undoable
                createForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={false}/>}
                editForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={true}/>}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <TextFieldBold source={`title`} label={'title'}/>
                <DateFieldComp source="created_at" label={'create'} textAlign={'center'} showTime={false}
                               locale={'us'} cellClassName={classes.date_st}/>
                <DateFieldComp source="updated_at" label={'update'} textAlign={'center'} showTime={false}
                               locale={'us'} cellClassName={classes.date_st}/>
            </EditableDatagrid>
        </ListComponentAdmin>
    )
};