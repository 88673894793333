import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default function ConfigReport(props) {
    const classes = useStyles();
    const {title, state, setState, table_columns = []} = props
    // const [state, setState] = React.useState({
    //   year: true,
    //   month: false,
    //   name: false,
    // });

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };

    // const {year, month, name} = state;
    // const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

    return (
        <>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">{title}</FormLabel>
                <FormGroup>
                    {Object.keys(state).map(i => (<FormControlLabel
                        control={<Checkbox checked={state[i]} onChange={handleChange} name={i}/>}
                        label={table_columns.find(x => x.field === i) ? table_columns.find(x => x.field === i).title : i}
                    />))}
                </FormGroup>
            </FormControl>
        </>
    );
}