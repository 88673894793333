import * as React from 'react';
import {
    Create
} from 'react-admin';

import SupplierEditCreate from "./SupplierEditCreate";
import PostEditActions from "../_common/PostEditActions";

export const SupplierCreate = props => {
    const {location} = props;
    const {search} = location;
    let supplierId = '';
    let programId = '';
    if(search){
        let url = new URL('https://test/' + search);
        supplierId = url.searchParams.get('supplierId')
        programId = url.searchParams.get('programId')
    }

    return (
        <Create {...props}
            title={' '}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            onSuccess={() => props.history.goBack()}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <SupplierEditCreate supplierId={supplierId} programId={programId}/>
        </Create>
    )
};