import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    // TextField,
    useRecordContext,
    ReferenceField,
    TextField,
    //ReferenceManyField,
    SingleFieldList, EmailField, ArrayField, ReferenceManyField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import {DateFieldComp, TextFieldBold, TextFieldButton} from "../_common/CompReact";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
// import {commonYesNo} from "../utils/choices";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
import get from 'lodash/get';
import {Box, Divider, Link} from "@material-ui/core";
import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
import {commonTrueFalse, paymentType, ticketClass, ticketType, transactionType} from "../utils/choices";
import moment from "moment";
import {canAccess2} from "../utils/canAccess2";
// import {Box} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

/*const Total = ({props}) => {
    //const {source} = props;
    let result = '';
    const record = useRecordContext(props);
    let qty = get(record, "quantity", null);
    let price = get(record, "sales_price", null);
    let taxes = get(record, "taxes", null);
    let other_fee = get(record, "other_fee", null);
    //console.log(qty, '=======>', price)
    if (price) {
        if (qty) result = price * qty;
        else result = price;
        if (taxes) result += taxes;
        if (other_fee) result += other_fee;
        result = formatSpacerNumber(result, ',', 3);
    }
    return (
        <span>{result}</span>
    );
}*/

export const formatSpacerNumber = (str, delim = ',', fix = 2) => {//\u00a0
    if (str) {
        let st = parseFloat(str).toFixed(fix)
        if (st >= 1000) return st.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delim);
        else return st;
    } else return '';
};

export const FloatNumber = (props) => {
    const {source, fix = 2, delim = ','} = props;
    const record = useRecordContext(props);
    let par = get(record, source, '');
    if (par) par = formatSpacerNumber(par, delim, fix);
    return (
        <span>{par ? par : null}</span>
    );
}

export function calcPassenger(dt) {
    let count = 0
    let arr = []
    dt.forEach(it => {
        if (arr.indexOf(it.id) < 0) {
            arr.push(it.id)
            count += 1
        }
    })
    return count
}

const FloatNumberPaid = (props) => {
    const {source = null, column = 'none', fix = 2, delim = ','} = props;
    const record = useRecordContext(props);
    let payment_type = get(record, 'payment_type', null)
    let transaction_type = get(record, 'transaction_type', null)
    let par = null;
    let per = null;
    let costPar = null
    let fx = fix;
    let passengers = get(record, 'passengers', null)
    if (passengers) passengers = calcPassenger(passengers) //passengers.length;
    else passengers = 0;
    let title = '';
    if (transaction_type.indexOf("non_flight")>=0) {
        let non_flight_transactions = get(record, 'non_flight_transactions', null)
        if (non_flight_transactions) {
            non_flight_transactions?.forEach((it) => {
                title += it.value.value + ', ';
            })
        }
    }
    switch (column) {
        case 'cost':
            per = 0
            par = get(record, 'cost_total_dollar', '');
            /*if (transaction_type === "non_flight") {
                par = get(record, 'cost_total_dollar', '');
            } else if (payment_type === 'cash') {
                par = get(record, 'cost_ticket_tax_amount_dollar', '');
                per = get(record, 'cost_per_passenger_amount', null);
                if (per && passengers) per = formatSpacerNumber(per);
            } else {
                par = get(record, 'cost_miles_price_dollar', '');
                fx = 3
            }*/
            break;
        case 'price':
            if (transaction_type.indexOf("non_flight")>=0) {
                par = get(record, 'sale_total_dollar', '');
            } else if (payment_type === 'cash') {
                par = get(record, 'sale_amount_dollar', '');
                per = get(record, 'sale_per_passenger_amount', null);
                if (per && passengers) per = formatSpacerNumber(per);
            } else {
                par = get(record, 'sale_miles_price_dollar', '');
                fx = 3
            }
            break;
        case 'taxes':
            if (transaction_type.indexOf("non_flight")>=0) {
                par = null
            } else if (payment_type === 'cash') par = '';
            else par = get(record, 'sale_amount_dollar', '');
            break;
        case 'extra':
            if (transaction_type.indexOf("non_flight")>=0) {
                par = null;
            } else {
                par = get(record, 'sale_ticket_cc_fee_dollar', '');
                costPar = get(record, 'cost_ticket_cc_fee_amount_dollar', '');
            }
            return (
                <div style={{display: "grid"}}>
                    {par ? <span title={title}>sale: {par.toFixed(2)}</span> : null}
                    {costPar ? <span title={title}>cost: {costPar.toFixed(2)}</span> : null}
                </div>
            )

        case 'total':
            par = get(record, 'sale_total_dollar', '');
            break;
        case 'profit':
            par = get(record, 'sale_total_dollar', null);
            // if (par) {
            let par2 = get(record, 'travel_credit_dollar', null);
            //if (par2)
            par = par + par2;
            par2 = get(record, 'cost_total_dollar', null);
            //if (par2)
            par = par - par2;
            // }
            if (par === null) par = 0
            break;
        default:
            par = get(record, source, '');
            break;
    }

    if (par) par = formatSpacerNumber(par, delim, fx);

    if (per)
        return (
            <div style={{display: "grid"}}>
                <span>{par}</span>
                {passengers > 1
                    ? <span>({per} x {passengers})</span>
                    : null
                }
            </div>
        );
    else
        return (
            <span title={title}>{par}</span>
        );
}

const GetSupplier = (props) => {
    const {classes} = props;
    const record = useRecordContext(props);
    let payment_type = get(record, 'payment_type', null);
    if (payment_type === 'cash')
        return (
            <ReferenceField source="supplier_id" reference="supplierData" label={'Supplier Name'} link={false}
                            cellClassName={classes.program_title_st} sortBy={'supplierData:title'}>
                <TextField source="title"/>
            </ReferenceField>
        )
    else return (
        <ReferenceField source="program_account_id" reference="supplier" label={'Supplier Name'} link={false}
                        cellClassName={classes.program_title_st} sortBy={'supplier:supplier_title'}>
            <TextField source="supplier_title"/>
        </ReferenceField>
    )
}

export const GetString = (props) => {
    const {source, title, titleText = false} = props;
    const record = useRecordContext(props);
    let src = get(record, source, '---');
    let tit = get(record, title, '---');
    return (
        <span title={titleText ? titleText : tit}>{src}</span>
    );
}
/*const IsLoadXero = (props) => {
    const {resource, classes} = props;
    const record = useRecordContext(props);
    let transaction_type = get(record, 'transaction_type', null);
    if (transaction_type === 'refund')
    return (
    <SelectAndSaveChoices
        label={'Is Load Xero'}
        source="is_load_refund"
        statuses={commonTrueFalse}
        resource={resource}
        textAlign={'center'}
        showTextValue={true}
        width={'2em'}
        edit={true}
        color={'green'}
        cellClassName={classes.load_paid_st} headerClassName={classes.load_paid_st}
        //link={(record, reference) => `/my/path/to/${reference}/${record.id}`}
    />
    )
    else return null;
}*/
export const GetInvoice = (props) => {
    const {source, form = false} = props;
    const record = useRecordContext(props);
    let src = get(record, source, null);

    if (src === null) return null;

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        window.location.href = `#/invoice/${src.id}`
        // redirect(`/invoice/91/`)
    };
    if (form)
        return (
            <Link
                size="small"
                color="primary"
                style={{display: "grid", fontSize: 16, fontWeight: "bold"}}
                //href={`#/invoice/${src.id}`}
                onClick={handleClick}
            >
                <span>{src.full_number ? `Invoice: ${src.full_number}` : ''}</span>
                <div>
                    <span>{src.date ? `${moment(src.date).format("D-MMM-YY")}` : ''}</span>
                    <span>{src.total_amount ? `, $${formatSpacerNumber(src.total_amount)}` : ''}</span>
                </div>

            </Link>
        );
    else
        return (
            <Link
                size="small"
                color="primary"
                style={{display: "grid", width: '100%'}}
                //href={`#/invoice/${src.id}`}
                onClick={handleClick}
            >
                <span>{src.full_number ? `${src.full_number}` : ''}</span>
                <span>{src.date ? `${moment(src.date).format("D-MMM-YY")}` : ''}</span>
                <span>{src.total_amount ? `$ ${formatSpacerNumber(src.total_amount)}` : ''}</span>
            </Link>
        );
}
export const GetIssuedAirline = (props) => {
    //const {classes} = props;
    const record = useRecordContext(props);
    //let ticket_type = get(record, 'ticket_type', null);
    let transaction_type = get(record, 'transaction_type', null);
    let non_flight_transactions = get(record, 'non_flight_transactions', null);

    switch (transaction_type) {
        case 'non_flight_refund':
        case 'non_flight_changing':
        case 'non_flight':
            return (
                <Box display={'grid'}>
                    {
                        non_flight_transactions && non_flight_transactions.map((it, ind) => {
                            let val = get(it, 'value.value', '');
                            return <span key={ind}>{val}</span>
                        })}
                </Box>

            )
        default:
            return (
                <TextFieldBold source="issued_airline_details.title" label={'Issued airline'}/>
            )
    }
}
const GetDataRouteFlight = (props) => {
    const {type} = props;
    const record = useRecordContext(props);
    let ticket_type = get(record, 'ticket_type', null);
    let transaction_type = get(record, 'transaction_type', null);
    let non_flight_transactions = get(record, 'non_flight_transactions', null)
    switch (type) {
        case 'data':
            if (transaction_type.indexOf("non_flight")>=0) {
                let count = 0
                if (non_flight_transactions) {
                    non_flight_transactions?.forEach((it) => {
                        if (it.date || it.date_to) count += 1;
                    })
                }
                if (count > 0)
                    return (
                        <ArrayField source="non_flight_transactions">
                            <SingleFieldList style={{display: "grid"}} linkType={false}>
                                <Box display={'grid'}>
                                    <DateFieldComp source="date" showTime={false}
                                                   locale={'us'}/>
                                    <DateFieldComp source="date_to" showTime={false}
                                                   locale={'us'}/>
                                    {count > 1 && <Divider/>}
                                </Box>
                            </SingleFieldList>
                        </ArrayField>
                    )
                else return null
            } else {
                switch (ticket_type) {
                    case 'single':
                        return (
                            <DateFieldComp source="travel_date" showTime={false}
                                           locale={'us'}/>
                        )
                    case 'rtn':
                        return (
                            <Box style={{display: "grid"}}>
                                <DateFieldComp source="travel_date" showTime={false}
                                               locale={'us'}/>
                                <DateFieldComp source="travel_date_return" showTime={false}
                                               locale={'us'}/>
                            </Box>
                        )
                    case 'multi_city':
                        return (
                            <ArrayField source="legs">
                                <SingleFieldList style={{display: "grid"}} linkType={false}>
                                    <DateFieldComp source="travel_date" showTime={false}
                                                   locale={'us'}/>
                                </SingleFieldList>
                            </ArrayField>
                        )
                    default:
                        return null

                }
            }
        case 'route':
            if (transaction_type.indexOf("non_flight")>=0){
                return(
                    <Box display={'grid'}>
                    {non_flight_transactions &&
                        non_flight_transactions.map((it, ind) => {
                            let val = get(it, 'route', '');
                            return <span key={ind}>{val}</span>
                        })
                    }
                </Box>
                )
            }
            switch (ticket_type) {
                case 'single':
                    return (
                        <TextField source={`route`}/>
                    )
                case 'rtn':
                    return (
                        <Box display={'grid'}>
                            <div><TextField source={`from_place`}/> - <TextField source={`to_place`}/></div>
                            <div><TextField source={`to_place`}/> - <TextField source={`from_place`}/></div>
                        </Box>
                    )
                case 'multi_city':
                    return (
                        <ArrayField source="legs">
                            <SingleFieldList style={{display: "grid"}} linkType={false}>
                                <TextField source="route"/>
                            </SingleFieldList>
                        </ArrayField>

                    )
                default:
                    return null
            }
        case 'flight':
            switch (ticket_type) {
                case 'single':
                    return (
                        <TextField source={`flight_number`}/>
                    )
                case 'rtn':
                    return (
                        <Box style={{display: "grid"}}>
                            <TextField source={`flight_number`}/>
                            <TextField source={`flight_number_return`}/>
                        </Box>
                    )
                case 'multi_city':
                    return (
                        <ArrayField source="legs">
                            <SingleFieldList style={{display: "grid"}} linkType={false}>
                                <TextField source="flight_number"/>
                            </SingleFieldList>
                        </ArrayField>
                    )
                default:
                    return null
            }
        default:
            return null
    }
}

export const SelectAndSaveChoicesTypeTransaction = (props) => {
    const {
        source,
        label,
        statuses,
        resource,
        textAlign,
        showTextValue,
        width,
        backgroundColor = '#eee',
    } = props;
    const record = useRecordContext(props);
    let canceled = get(record, 'canceled', '')
    let transaction_type = get(record, 'transaction_type', '')
    let transactions = get(record, 'transactions', [])
    let draft = get(record, 'draft', null)
    if (transactions.find(x => (x.transaction_type === 'refund' || x.transaction_type === 'changing'))) transaction_type = 'refund'
    return (
        <SelectAndSaveChoices
            // cellClassName={classes.cost_st}
            label={label}
            source={source}
            statuses={statuses}
            resource={resource}
            textAlign={textAlign}
            showTextValue={showTextValue}
            width={width}
            backgroundColor={
                transaction_type.indexOf('refund')>=0 || transaction_type.indexOf('changing')>=0
                    ? 'darkorange'
                    : backgroundColor
            }
            icon={draft
                ? <EditIcon style={{fontSize: 16, color: 'blue'}}/>
                : canceled
                    ? <CancelIcon style={{color: 'red'}}/>
                    : null}
        />
    );
}

/*const GetDraft = (props) => {
    const record = useRecordContext(props);
    return (
        record.draft
            ? <EditIcon style={{fontSize: 16, color: "red"}}/>
            : null

    )
}*/
export const BookingColumnsForList = (resource, locale, classes) => {

    let canAccess = canAccess2({resource: 'booking', action: 'field'});
    let columns = {
        id:
            <Box display={'grid'} sortBy={'id'} label={'ID'} headerClassName={classes.headerShortWidthBooking}
                 cellClassName={classes.headerShortWidthBooking}>
                <NumberField source="id"/>
                {/*<TextField source={'booking_id_external'} className={classes.booking_id_external}/>*/}
            </Box>,
        /*draft: <GetDraft headerClassName={classes.draftIcon} cellClassName={classes.draftIcon}/>,*/
        transaction_type: /*<TextField source={`payment_type`} label={'Payment type'} textAlign={"center"}/>,*/
            <SelectAndSaveChoicesTypeTransaction
                // cellClassName={classes.cost_st}
                label={'Transaction type'}
                source="transaction_type"
                statuses={transactionType}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'10em'}
            />,
        payment_type: /*<TextField source={`payment_type`} label={'Payment type'} textAlign={"center"}/>,*/
            <SelectAndSaveChoices
                label={'Payment type'}
                source="payment_type"
                statuses={paymentType}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'8em'}
            />,
        /*<SelectAndSaveChoices
                label={'Payment type'}
                source="payment_type"
                statuses={paymentType}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
            />,*/
        customer:
        /*<ReferenceField source="customer_id" reference="customer" label={'Customer'} link={false}
                        cellClassName={classes.customer_st} sortBy={'customer:name'}>
            <TextFieldBold source="name"/>
        </ReferenceField>,*/
            <TextFieldBold source="customer_details.name" label={'Customer'} cellClassName={classes.customer_st}
                           sortBy={'customer:name'}/>,
        /*project:
            <ReferenceField source="project_id" reference="projects" sortBy={'projects:name'}
                            link={false} cellClassName={classes.project_st}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="name"
                />
            </ReferenceField>,*/
        booking_date: <DateFieldComp source="booking_date" label={'Booking Date'}
                                     headerClassName={classes.date_st} cellClassName={classes.date_st} showTime={true}
                                     locale={'us'}/>,
        issued_airline_id:
            <GetIssuedAirline
                label={'Issued airline'}
                headerClassName={classes.airline_st}
                cellClassName={classes.airline_st}
                sortBy={'airline:title'}
            />,
        /*<TextFieldBold source="issued_airline_details.title" label={'Issued airline'}
                       headerClassName={classes.airline_st} cellClassName={classes.airline_st}
                       sortBy={'airline:title'}/>,*/
        cost: <FloatNumberPaid column={'cost'} label={'Cost'}
                               headerClassName={classes.costHead_st}
                               cellClassName={classes.costHead_st} textAlign={"right"}/>,
        price: <FloatNumberPaid column={'price'} label={'Price'} cellClassName={classes.priceHead_st}
                                textAlign={"right"}/>,
        quantity: <FloatNumber fix={0} delim={'\u00a0'} source={`quantity`} label={'Qty (mi)'}
                               cellClassName={classes.quantity_st}
                               textAlign={"right"}/>,
        taxes: <FloatNumberPaid column={'taxes'} label={'Taxes'} cellClassName={classes.price_st} textAlign={"right"}/>,
        extra: <FloatNumberPaid column={'extra'} label={'Fees/ Luggage/ Etc'}
                                headerClassName={classes.price_st_extra} cellClassName={classes.price_st_extra}
                                textAlign={"right"}/>,
        total: <FloatNumberPaid column={'total'} headerClassName={classes.price_st} cellClassName={classes.price_st}
                                label={'Total'} textAlign={"right"}/>,

        /*price:
            <Box label={'Price (mi)'} style={{display: "grid"}}>
                <FloatNumber source={'cost_miles_price_dollar'} label={'Cost'}
                             headerClassName={classes.costHead_st}
                             cellClassName={classes.cost_st} textAlign={"right"}/>
                <FloatNumber source={'sale_miles_price_dollar'} label={'Price'}
                             cellClassName={classes.sales_price_st}
                             textAlign={"right"}/>
            </Box>,
        quantity: <FloatNumber fix={0} delim={'\u00a0'} source={`quantity`} label={'Qty (mi)'} cellClassName={classes.quantity_st}
                             textAlign={"right"}/>,
        cost_miles_amount_dollar: <FloatNumber source={'cost_miles_amount_dollar'} label={'Cost (mi)'}
                                               textAlign={"right"}/>,
        cost_ticket_tax_amount_dollar: <FloatNumber source={'cost_ticket_tax_amount_dollar'} label={'Tax'}
                                                    cellClassName={classes.total_st}
                                                    textAlign={"right"}/>,
        cost_ticket_cc_fee_amount_dollar: <FloatNumber source={'cost_ticket_cc_fee_amount_dollar'} label={'CC Fees'}
                                                       cellClassName={classes.total_st}
                                                       textAlign={"right"}/>,
        cost_total_dollar: <FloatNumber source={'cost_total_dollar'} label={'Total'}
                                        headerClassName={classes.costHead_st}
                                        cellClassName={classes.cost_st} textAlign={"right"}/>,
        sale_total_dollar: <FloatNumber source={'sale_total_dollar'} label={'Sale'} cellClassName={classes.total_st}
                                        textAlign={"right"}/>,*/

        col1: <TextField source={'none'} label={''} cellClassName={classes.razd} headerClassName={classes.razd}/>,

        title:
            <ArrayField source="passengers" label={'Title'} sortable={false} cellClassName={classes.passenger_title_st}>
                <SingleFieldList style={{display: "grid"}} linkType={false}>
                    <TextField source="title"/>
                </SingleFieldList>
            </ArrayField>,
        l_name:
            <ArrayField source="passengers" label={'Passenger Family Name'} sortable={false}
                        cellClassName={classes.passenger_st} headerClassName={classes.passenger_st}>
                <SingleFieldList style={{display: "grid"}} linkType={false}>
                    <TextField source="l_name"/>
                </SingleFieldList>
            </ArrayField>,
        f_name:
            <ArrayField source="passengers" label={'Passenger First Name'} sortable={false}
                        cellClassName={classes.passenger_st} headerClassName={classes.passenger_st}>
                <SingleFieldList style={{display: "grid"}} linkType={false}>
                    <TextField source="f_name"/>
                </SingleFieldList>
            </ArrayField>,
        /*title:
            <ReferenceManyField reference="bookings_passenger" target="booking_id" label={'Title'} sortable={false}
                                cellClassName={classes.passenger_st}>
                <SingleFieldList style={{display: "grid"}}>
                    <ReferenceField source="passenger_id" reference="passenger">
                        <TextField source="title"/>
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>,
        f_name:
            <ReferenceManyField reference="bookings_passenger" target="booking_id"
                                label={'Passenger First Name Initial'} sortable={false}
                                cellClassName={classes.passenger_st}>
                <SingleFieldList style={{display: "grid"}}>
                    <ReferenceField source="passenger_id" reference="passenger">
                        <TextField source="f_name"/>
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>,
        l_name:
            <ReferenceManyField reference="bookings_passenger" target="booking_id" label={'Passenger Name'}
                                sortable={false} cellClassName={classes.passenger_st}>
                <SingleFieldList style={{display: "grid"}}>
                    <ReferenceField source="passenger_id" reference="passenger">
                        <TextField source="l_name"/>
                    </ReferenceField>
                </SingleFieldList>
            </ReferenceManyField>,*/

        col2: <TextField source={'none'} label={''} cellClassName={classes.razd} headerClassName={classes.razd}/>,

        pnr:
            <ArrayField
                source="references" label={'PNR / Booking Ref'} sortable={false}
                cellClassName={classes.pnr_st}
                style={{display: 'block'}}
            >
                <SingleFieldList linkType={false}>
                    {/*<Box style={{display: 'flex'}}>
                        <Box style={{width: '100%'}}><TextField source="pnr"/></Box>
                        <Box>(<TextField source="airline.code"/>)</Box>
                    </Box>*/}
                    <Box><TextField source="pnr"/> (<TextField source="airline.code"/>)</Box>
                </SingleFieldList>
            </ArrayField>,
        /*pnr:
            <ReferenceManyField reference="bookings_reference" target="booking_id" label={'PNR / Booking Ref'}
                                link={false}
                                sortable={false} cellClassName={classes.pnr_st} style={{display: 'block'}}>
                <SingleFieldList>
                    <Box style={{display: 'flex'}}>
                        <Box style={{width: '100%'}}><TextField source="pnr"/></Box>
                        <Box>
                            <ReferenceField source="airline_id" reference="airline">
                                <>{' ('}<TextField source="code"/>{')'}</>
                            </ReferenceField>
                        </Box>
                    </Box>
                </SingleFieldList>
            </ReferenceManyField>,*/
        /*ticket_number:
            <ArrayField source="passengers" label={'Ticket number'} sortable={false}
                        cellClassName={classes.ticket_number_st}>
                <SingleFieldList style={{display: "grid"}} linkType={false}>
                    <Box>
                        <TextField source="ticket_number"/></Box>
                </SingleFieldList>
            </ArrayField>,*/
        ticket_number:
            <ArrayField source="passengers" label={'Ticket number'} sortable={false}
                        cellClassName={classes.ticket_number_st}>
                <SingleFieldList style={{display: "grid"}} linkType={false}>
                    <Box><TextField source="airline_ticket_code"/>-<TextField source="ticket_number"/></Box>
                </SingleFieldList>
            </ArrayField>,
        /*ticket_number:
            <ReferenceManyField reference="bookings_passenger" target="booking_id" link={false}
                                label={'Ticket number'} sortable={false}
                                cellClassName={classes.ticket_number_st}>
                <SingleFieldList style={{display: "grid"}}>
                    <Box><TextField source="airline_ticket_code"/>-<TextField source="ticket_number"/></Box>
                    {/!*<GetString source="ticket_number" title="ticket_number"/>*!/}
                </SingleFieldList>
            </ReferenceManyField>,*/
        travel_date: /*<DateFieldComp source="travel_date" label={'Travel date'} showTime={false}
                                    locale={'us'} cellClassName={classes.date_st}/>,*/
            <GetDataRouteFlight type={'data'} label={'Travel date'} cellClassName={classes.date_st}
                                sortBy={'travel_date'}/>,
        from_to_place:
            <Box style={{display: "grid"}} label={'From / To'} cellClassName={classes.route_st} sortBy={'from_place'}>
                <TextField source={`from_place`}/>
                <TextField source={`to_place`}/>
            </Box>,
        route: /*<TextField source={`route`} label={'Travel Route'} cellClassName={classes.route_st}/>,*/
            <GetDataRouteFlight type={'route'} label={'Travel Route'} cellClassName={classes.route_st}
                                sortBy={'route'}/>,

        /*ticket_type: <TextField source={`ticket_type`} label={'Single / Rtn'} cellClassName={classes.ticket_type_st}/>,*/
        ticket_type:
            <SelectAndSaveChoices
                label={'Ticket type'}
                source="ticket_type"
                statuses={ticketType}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'7em'}
            />,
        operated_airline_id:
        /*<ReferenceField source="operated_airline_id" reference="airline" label={'Operated airline'} link={false}
                        sortBy={'airline:title'} headerClassName={classes.airline_st}
                        cellClassName={classes.airline_st}>
            <GetString source="title" title={'code'}/>
        </ReferenceField>,*/
            <TextFieldBold source="operated_airline_details.title" label={'Operated airline'}
                           headerClassName={classes.airline_st} cellClassName={classes.airline_st}
                           sortBy={'airline:title'}/>,
        flight_number: /*<TextField source={`flight_number`} label={'Flight Number'}
                                  cellClassName={classes.flight_number_st}/>,*/
            <GetDataRouteFlight type={'flight'} label={'Flight Number'} cellClassName={classes.flight_number_st}
                                sortBy={'flight_number'}/>,
        /*ticket_class: <TextField source={`ticket_class`} label={'Class'} cellClassName={classes.ticket_class_st}/>,*/
        ticket_class:
            <SelectAndSaveChoices
                label={'Class'}
                source="ticket_class"
                statuses={ticketClass}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'7em'}
            />,
        /*miles_only: <TextField source={`ticket_class`} label={'Class'} cellClassName={classes.miles_only_st}/>,*/
        notes: <TextField source={`notes`} label={'Notes'} cellClassName={classes.notes_st}/>,
        url: <TextField source={`url`} label={'Url'} cellClassName={classes.notes_st}/>,

        col3: <TextField source={'none'} label={''} cellClassName={classes.razd} headerClassName={classes.razd}/>,

        supplier_id: <GetSupplier classes={classes} label={'Supplier Name'}
                                  cellClassName={classes.supplier} headerClassName={classes.supplier}/>,
        //Supplier Booking Ref
        booking_code: <TextField source={'booking_code'} label={'Supplier Booking Ref'}
                                 cellClassName={classes.booking_ref} headerClassName={classes.booking_ref}/>,
        program_id:
            <ReferenceField source="program_id" reference="programs" label={'Program Name'} link={false}
                            cellClassName={classes.program_title_st} headerClassName={classes.program_title_st}
                            sortBy={'programs:title'}>
                <TextField source="title"/>
            </ReferenceField>,
        /*<ReferenceField source="supplier_id" reference="supplierData" label={'Supplier Name'}
                        cellClassName={classes.program_title_st} sortBy={'supplierData:title'}>
            <TextField source="title"/>
        </ReferenceField>,*/
        /*account_number:
            <ReferenceField source="supplier_id" reference="supplier" label={'Account number'}
                            cellClassName={classes.account_number_st} sortBy={'supplier:account_number'}>
                <TextField source="account_number"/>
            </ReferenceField>,*/
        account_f_name:
            <ReferenceField source="program_account_id" reference="supplier" label={'Account First Name'} link={false}
                            headerClassName={classes.username_st} cellClassName={classes.username_st}
                            sortBy={'supplier:f_name'}>
                <TextField source={`f_name`}/>
            </ReferenceField>,
        account_l_name:
            <ReferenceField source="program_account_id" reference="supplier" label={'Account Last Name'} link={false}
                            headerClassName={classes.username_st} cellClassName={classes.username_st}
                            sortBy={'supplier:l_name'}>
                <TextField source="l_name"/>
            </ReferenceField>,
        account_number:
            <ReferenceField source="program_account_id" reference="supplier" label={'Account number'} link={false}
                            headerClassName={classes.username_st} cellClassName={classes.username_st}
                            sortBy={'supplier:account_number'}>
                <TextField source="account_number"/>
            </ReferenceField>,
        card_id:
            <ReferenceField source="card_id" reference="creditCard" label={'Credit Card'} link={false}
                            headerClassName={classes.credit_card} cellClassName={classes.credit_card}
                            sortBy={'creditCard:card_id'}>
                {/*<TextField source="last_cc"/>*/}
                <>*<GetString source="last_cc" title="description"/></>
            </ReferenceField>,
        card_miles_id:
            <ReferenceField source="card_miles_id" reference="creditCard" label={'Credit Card (Miles)'} link={false}
                            headerClassName={classes.credit_card} cellClassName={classes.credit_card}
                            sortBy={'creditCard:card_miles_id'}>
                {/*<TextField source="last_cc"/>*/}
                <>*<GetString source="last_cc" title="description"/></>
            </ReferenceField>,
        card_tax_id:
            <ReferenceField source="card_tax_id" reference="creditCard" label={'Credit Card (Tax)'} link={false}
                            headerClassName={classes.credit_card} cellClassName={classes.credit_card}
                            sortBy={'creditCard:card_tax_id'}>
                {/*<TextField source="last_cc"/>*/}
                <>*<GetString source="last_cc" title="description"/></>
            </ReferenceField>,
        card_fees_id:
            <ReferenceField source="card_fees_id" reference="creditCard" label={'Credit Card (Fees)'} link={false}
                            headerClassName={classes.credit_card} cellClassName={classes.credit_card}
                            sortBy={'creditCard:card_fees_id'}>
                {/*<TextField source="last_cc"/>*/}
                <>*<GetString source="last_cc" title="description"/></>
            </ReferenceField>,
        login:
            <ReferenceField source="program_account_id" reference="supplier" label={'Login'} link={false}
                            headerClassName={classes.username_st} cellClassName={classes.username_st}
                            sortBy={'supplier:login'}>
                <TextField source="login"/>
            </ReferenceField>,
        password:
            <ReferenceField source="program_account_id" reference="supplier" label={'Password'} link={false}
                            cellClassName={classes.password_st} sortBy={'supplier:password'}>
                {/*<TextField source="password"/>*/}
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    copyClipBoard={true}
                    source="password"
                    label={'Password'}
                    pasw={true}
                    bold={false}
                />
            </ReferenceField>,
        email_login:
            <ReferenceField source="program_account_id" reference="supplier" label={'Email'} link={false}
                            headerClassName={classes.username_st} cellClassName={classes.username_st}
                            sortBy={'supplier:email_login'}>
                <EmailField source="email_login"/>
            </ReferenceField>,
        email_password:
            <ReferenceField source="program_account_id" reference="supplier" label={'Email Password'} link={false}
                            headerClassName={classes.password_st} cellClassName={classes.password_st}
                            sortBy={'supplier:email_password'}>
                {/*<TextField source="email_password"/>*/}
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    copyClipBoard={true}
                    source="email_password"
                    label={'Email Password'}
                    pasw={true}
                    bold={false}
                />
            </ReferenceField>,
        /*cost:
            <ReferenceField source="program_account_id" reference="supplier" label={'Cost pr'}
                            cellClassName={classes.supplier_notes_st} sortBy={'supplier:cost'}>
                <TextField source="cost"/>
            </ReferenceField>,
        currency_id:
            <ReferenceField source="program_account_id" reference="supplier" label={'Currency'}>
                <ReferenceField source="currency_id" reference="currency" label={'Currency'} sortBy={'currency:code'}>
                    <TextField source="code"/>
                </ReferenceField>
            </ReferenceField>,*/
        col4: <TextField source={'none'} label={''} cellClassName={classes.razd} headerClassName={classes.razd}/>,

        is_load:
            <SelectAndSaveChoices
                label={'Exported to Xero'}
                source="is_load"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                cellClassName={classes.load_paid_st} headerClassName={classes.load_paid_st}
            />,

        // is_load_xero: <IsLoadXero label={'Exported Refund to Xero'} classes={classes} resource={resource}/>,

        paid: /*<BooleanField source={`is_paid`} label={'Is Paid'} cellClassName={classes.paid_st}/>,*/
            <SelectAndSaveChoices
                label={'Is Paid'}
                source="is_paid"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                is_field_no_edit={'invoice.id'}
                color={'green'}
                cellClassName={classes.load_paid_st} headerClassName={classes.load_paid_st}
                //link={(record, reference) => `/my/path/to/${reference}/${record.id}`}
            />,

        invoice:
            <GetInvoice source={'invoice'} label={"Invoice"} cellClassName={classes.invoice}
                        headerClassName={classes.invoice}/>,
        /*<Box label={'Invoice'} link={(record) => `/invoice/${record.id}`}>
            #<TextField source={'invoice.id'}/>
            <TextField source={'invoice.is_paid'}/>
        </Box>,*/

        travel_credit:
            <ReferenceManyField reference="travelCredit" target="booking_id"
                                label={'Travel Credit'} sortable={false}
                                cellClassName={classes.price_st}
                                headerClassName={classes.price_st}

            >
                <SingleFieldList style={{display: 'grid'}}>
                    <Box style={{display: 'grid'}}>
                        <FloatNumber source={'amount_dollar'}/>
                        <DateFieldComp source="expire_date" showTime={false} locale={'us'}/>
                    </Box>
                </SingleFieldList>
            </ReferenceManyField>,
        travel_credit_dollar:
            <FloatNumber source={'travel_credit_dollar'} headerClassName={classes.price_st}
                         cellClassName={classes.price_st}
                         label={'Used credit'} textAlign={"right"}/>,

        profit: <FloatNumberPaid column={'profit'} headerClassName={classes.price_st} cellClassName={classes.price_st}
                                 label={'Profit'} textAlign={"right"}/>,

        updated_at: <DateFieldComp source="updated_at" label={'Updated'} cellClassName={classes.date_st}/>,
        /*created_at: <DateFieldComp source="created_at" label={'Created'} cellClassName={classes.date_st}/>,*/
    }
    if (!canAccess) delete columns.profit
    return columns;
};
