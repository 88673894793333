//import * as React from 'react';
//import {TextInput} from 'react-admin';

export const universalTopFilters = (resource, locale) => {
    let dt = [];
    // dt.push(<TextInput label="Search" source="q" alwaysOn />)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!" />);
    // dt.push(<TextInput label="Customer" source="customer"/>)
    // dt.push(<TextInput label="Passanger name" source="passanger_name"/>)
    // dt.push(<TextInput label="Airline" source="airline"/>)
    // dt.push(<TextInput label="Program Name" source="programName"/>)
    return dt;
}
