//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    AutocompleteInput,
    FilterLiveSearch,
    NumberField,
    ReferenceField, ReferenceInput,
    SimpleList,
    TextField,
    useLocale,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../index.css';
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {DateFieldComp, FilterDataAside, TextFieldButton} from "../_common/CompReact";
import CardComp from "../_common/CardComp";
import TrueFalseFilters from "../_common/TrueFalseFilters";
import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
import {commonTrueFalse} from "../utils/choices";
import {FloatNumber, GetString} from "../booking/BookingColumnsForList";
import HandlerCreateCsvInvoice from "../utils/HandlerCreateCsvInvoice";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch label={'Search'}/>
                <FilterDataAside fields={'created_at'} name={'created'}/>
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                <TrueFalseFilters field={'is_paid'} label={'Is paid'}/>
                <TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="project_id" reference="projects" alwaysOn>
            <AutocompleteInput optionText="name" resettable emptyText={'All progects'}/>
        </ReferenceInput>);*/
    dt.push(<ReferenceInput source="customer_id" reference="customer" alwaysOn>
            <AutocompleteInput optionText="name" resettable emptyText={'All customers'}/>
        </ReferenceInput>);
    return dt;
}

const ColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        /*project:
            <ReferenceField source="project_id" reference="projects" sortBy={'projects:name'}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="name"
                />
            </ReferenceField>,*/
        customer:
            <ReferenceField source="customer_id" reference="customer" sortBy={'customer:name'}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="name"
                />
            </ReferenceField>,
        full_number: <TextField source={'full_number'} Label={'Full number'}/>,
        card_id: <ReferenceField source="card_id" reference="creditCard" label={'Credit Card'} link={false}
                            headerClassName={classes.credit_card} cellClassName={classes.credit_card}
                            sortBy={'creditCard:card_id'}>
                {/*<TextField source="last_cc"/>*/}
                <>*<GetString source="last_cc" title="description"/></>
            </ReferenceField>,
        date: <DateFieldComp source="date" label={'Date'} textAlign={'center'} cellClassName={classes.date_st}/>,
        qty_of_bookings: <NumberField source={'qty_of_bookings'} label={'Qty bookings'}/>,
        total_amount: <FloatNumber source={'total_amount'} label={'Total'} textAlign={'right'}/>,
        /*is_paid: <BooleanField source="is_paid" label={'Is paid'}/>,*/
        is_paid: /*<BooleanField source={`is_paid`} label={'Is Paid'} cellClassName={classes.paid_st}/>,*/
            <SelectAndSaveChoices
                label={'Is Paid'}
                source="is_paid"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
            />,
        is_load: /*<BooleanField source={`is_paid`} label={'Is Paid'} cellClassName={classes.paid_st}/>,*/
            <SelectAndSaveChoices
                label={'Exported to Xero'}
                source="is_load"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
            />,

        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

export const InvoiceList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={' '}
            handlerForCheckBox={<HandlerCreateCsvInvoice label={'Export to Xero selected'}  table={'invoice'}/>}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes)}
                permissions={permissions}
                asideFilter={true}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
                handlerForAllTable={<HandlerCreateCsvInvoice label={'Export to Xero unexported'}  table={'invoice'}/>} //'Export to Xero unexported'
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}