import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {PassengerList} from "./PassengerList";
import {PassengerEdit} from "./PassengerEdit";
import {PassengerCreate} from "./PassengerCreate";

const resource = {
    list: PassengerList,
    create: PassengerCreate,
    edit: PassengerEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;