//import * as React from "react";

export const config = {
    //keycloakDomain: process.env.REACT_APP_AUTH_DOMAIN,
    apiUrl: process.env.REACT_APP_API_URL,
    bookmanID: process.env.REACT_APP_BOOKMAN_ID,
    bookmanCardID: process.env.REACT_APP_BOOKMAN_CARD_ID,
    cognito: true,//false,
    auth: true,//false  // false is allowed only when cognito: false
}

export const localesConfig = {
    defaultLocale: 'en',
    locales: ['en', 'ru'],
    languages: [
        {locale: "en", name: "English 🇬🇧"},
        {locale: "ru", name: "Русский"},
    ]
}
export const apiPathConfig = {
    getUrl: (name, type = '') => {
        // const params = apiPathConfig[name]
        // console.log('getUrl====>', name, type, params)
        switch (name) {
            //case 'universal': return config.apiUrl + 'api/react-admin/main/CurrencyCrossRates';
            case 'customer': return config.apiUrl + 'booking/admin/customer';
            case 'currency': return config.apiUrl + 'booking/admin/currency';

            case 'airline': return config.apiUrl + 'booking/admin/airline';
            case 'new_airline': return config.apiUrl + 'booking/admin/airline';

            case 'bank': return config.apiUrl + 'booking/admin/bank';

            case 'bookings_passenger': return config.apiUrl + 'booking/admin/bookings_passenger';
            case 'new_bookings_passenger': return config.apiUrl + 'booking/admin/bookings_passenger';

            case 'passenger': return config.apiUrl + 'booking/admin/passenger';
            case 'booking': return config.apiUrl + 'booking/admin/booking';
            case 'supplier': return config.apiUrl + 'booking/admin/program_account';

            case 'bookings_reference': return config.apiUrl + 'booking/admin/bookings_reference';
            case 'new_bookings_reference': return config.apiUrl + 'booking/admin/bookings_reference';

            case 'programs': return config.apiUrl + 'booking/admin/program';
            case 'supplierData':
            case 'supplierDataIsMiles':
            case 'supplierDataNotMiles':
                return config.apiUrl + 'booking/admin/supplier';
            case 'hiddenCity': return config.apiUrl + 'booking/admin/hidden_city';
            case 'creditCard': return config.apiUrl + 'booking/admin/card';
            case 'refund': return config.apiUrl + 'booking/admin/refund';
            case 'travelCredit': return config.apiUrl + 'booking/admin/travel_credit';
            case 'travel_credit_reports': return config.apiUrl + 'booking/admin/travel_credit';
            case 'invoice': return config.apiUrl + 'booking/admin/invoice';
            case 'invoice_booking': return config.apiUrl + 'booking/admin/invoice_booking';
            case 'history': return config.apiUrl + 'booking/admin/history';

            case 'non_flight_transactions': return config.apiUrl + 'booking/admin/non_flight_transactions_values';
            case 'bookings_non_flight_transactions': return config.apiUrl + 'booking/admin/bookings_non_flight_transactions';

            case 'projects': return config.apiUrl + 'booking/admin/project';

            default: return config.apiUrl;//+getUrlByName(name);

        }
    },

    getApiUrl: () => config.apiUrl,
    getAuth: () => config.auth,
    getCognito: () => config.cognito,
    // getPublicApiUrl: () => {
    //     const parts = config.apiUrl.split('//')
    //     const part1 = parts[1].replace('/api', '/public')
    //     return [parts[0], part1].join('//')
    // },
}

export default config
