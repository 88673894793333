import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {InvoiceList} from "./InvoiceList";
import {InvoiceEdit} from "./InvoiceEdit";
import {InvoiceCreate} from "./InvoiceCreate";

const resource = {
    list: InvoiceList,
    create: InvoiceCreate,
    edit: InvoiceEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;