import * as React from 'react';
import {useCallback, useState} from 'react';
//import DownloadIcon from '@material-ui/icons/GetApp';
import {
    //fetchRelatedRecords,
    useDataProvider,
    useNotify,
    useListContext,
} from 'ra-core';
import {Button} from "@material-ui/core";
import {apiPathConfig} from "./config";
import {fetchJson as httpClient} from "./keycloak";
import {useRedirect, Confirm} from "react-admin";

const BulkHandlerCreateInvoice = (props) => {
    const {
        onClick,
        label,
        //icon=false,
        //exporter: customExporter,
    } = props;
    const {
        //exporter: exporterFromContext,
        resource,
        selectedIds,
    } = useListContext(props);

    //const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const getData = async (data) => {
        const url = apiPathConfig.getApiUrl()
        //let report_url = `booking/admin/currency`;
        let report_url = `api/common/invoice/create-from-booking-ids`;

        try {
            //const response = await httpClient(`${url}${report_url}`);
            const response = await httpClient(`${url}${report_url}`, {
                method: 'post',
                body: JSON.stringify(data)
                /*body: JSON.stringify({
                    b64: base64
                })*/
            })
            if (response.status >= 200 && response.status <= 300) {
                const {json: dt} = response
                const {message, invoice_id} = dt || {}
                //console.log('response=========>', dt);
                if (message) {
                    //alert(response.json.message)
                    notify(message, {type: 'info', autoHideDuration: 8000,});
                }

                if (invoice_id) setinvoiceId(invoice_id); //redirect(`/invoice/${invoice_id}`)
            } else {

            }
        } catch (err) {
            console.log('err=======>', err);
            notify(err, {type: 'info',});
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }

    /*useEffect(() => {
         if (result) {
         }
     }, [result]);// eslint-disable-line*/

    const handleClick = useCallback(
        event => {
            //exporter &&
            dataProvider
                .getMany(resource, {ids: selectedIds})
                .then(({data}) => {
                        //console.log('=BulkHandlerCreateInvoice==data============>', data)
                        let dataBooking = [];
                        let dataInvoice = [];
                        let dataCuctomer = [];
                        //let dataTrType = [];
                        data.forEach(record => {
                            if (dataCuctomer.indexOf(record.customer_details.name) < 0)
                                dataCuctomer.push(record.customer_details.name);
                            if (record.invoice) dataInvoice.push(record.id);
                            /*else if (record.transaction_type === "non_flight")
                                dataTrType.push(record.id);*/
                            else dataBooking.push(record.id);
                            //return true
                        });
                        let mess = '';
                        if (dataInvoice.length > 0)
                            mess += `Invoices already exist: ${dataInvoice.join(', ')}`;
                        if (dataCuctomer.length > 1)
                            mess += ` Only one customer is needed: ${dataCuctomer.join(', ')}`;
                        /*if (dataTrType.length > 0)
                            mess += ` Transaction Type can not be "non_flight": ${dataTrType.join(', ')}`;*/
                        if (mess)
                            notify(mess,
                                {
                                    autoHideDuration: 15000,
                                    type: 'error',
                                }
                            );
                        else {
                            //notify('INVOICE READY', {type: 'info',});
                            getData(dataBooking);
                        }

                        /*exporter(
                            data,
                            fetchRelatedRecords(dataProvider),
                            dataProvider,
                            resource
                        )*/
                    }
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', {
                        type: 'warning',
                    });
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataProvider, notify, onClick, resource, selectedIds]
    );

    const [invoiceId, setinvoiceId] = useState(null);
    const handleDialogClose = () => setinvoiceId(null);
    const handleConfirm = () => {
        setinvoiceId(null);
        redirect(`/invoice/${invoiceId}`)
    };

    return (
        <>
            <Confirm
                isOpen={invoiceId}
                //loading={isLoading}
                title={`To Invoice`}
                content="Are you sure you want to move to this INVOICE?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            <Button
                style={{marginLeft: 24, marginTop: -4}}
                color={"primary"}
                onClick={handleClick}
                label={label}
            >
                {label}
            </Button>
        </>
    );
};

export default BulkHandlerCreateInvoice;
