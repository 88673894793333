import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../_common/EditComponentAdmin";
import SupplierDataEditCreate from "./SupplierDataEditCreate";

export const SupplierDataEdit = props => {
/*
    const {location} = props;
    const {search} = location;
    let idSource = '';
    if (search) {
        idSource = search.split('idSource=')[1] ? search.split('=')[1] : '';
    }
*/

    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            onSuccess={() => props.history.goBack()}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <SupplierDataEditCreate/>
        </EditComponentAdmin>
    )
};