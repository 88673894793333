import * as React from 'react';
import {
    NumberField,
    TextField,
    useLocale,
    AutocompleteInput, ReferenceInput, ReferenceField, TextInput, BooleanField,
    BooleanInput,
} from 'react-admin';

import {EditableDatagrid, RowForm, DeleteWithConfirmIconButton} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import useMyStyles from "../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {Box, CardContent} from "@material-ui/core";
import CardComp from "../_common/CardComp";
import {DateFieldComp, FilterDataAside} from "../_common/CompReact";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="booking_id" reference="booking" alwaysOn>
        <AutocompleteInput
            optionText={record => (record && record.id ? `${record.route} #${record.id}` : 'All Booking')}
            resettable
            //emptyText={'All airlines'}
        />
    </ReferenceInput>);
    dt.push(<ReferenceInput source="passenger_id" reference="passenger" alwaysOn>
        <AutocompleteInput
            optionText={record => (record &&
            record.title ? `${record.title} ${record.f_name} ${record.l_name}` : 'All passengers')}
            resettable
            //emptyText={'All airlines'}
        />
    </ReferenceInput>);
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch/>*/}
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};


const ListEditForm = (props) => {
    // const {classes, locale} = props;
    const {classes} = props;
    return (
        <RowForm {...props}>
            <NumberField source="id" headerClassName={classes.headerShortWidth}/>
            <ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'} label={'Route'}>
                <Box display={'grid'}>
                    <TextField source="route"/>
                </Box>
            </ReferenceField>
            <BooleanInput source={`is_main`} label={'Display'}/>
            {/*<ReferenceField source="airline_id" reference="booking" sortBy={'airline:code'} label={'Airline'}>
                <Box display={'grid'}>
                    <TextField source="code"/>
                </Box>
            </ReferenceField>*/}
            <ReferenceInput source="airline_id" reference="airline" label={'Airline'} sort={{field: 'title', order: 'ASC'}} fullWidth>
                <AutocompleteInput
                    resettable
                    optionText={record => (record && `${record.title} (${record.code})`)}/>
            </ReferenceInput>
            <TextInput source="pnr" label={'PNR'}/>
            <DateFieldComp source="created_at" label={'create'} textAlign={'center'} showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>
            <DateFieldComp source="updated_at" label={'update'} textAlign={'center'} showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>


            {/*<ReferenceInput source="booking_id" reference="booking" fullWidth>
                <AutocompleteInput
                    resettable
                    optionText={record => (record && `${record.pnr} ${record.route}`)}/>
            </ReferenceInput>
            <ReferenceInput source="passenger_id" reference="passenger" fullWidth>
                <AutocompleteInput
                    resettable
                    optionText={record => (record && `${record.title} ${record.l_name} ${record.f_name}`)}/>
            </ReferenceInput>*/}
        </RowForm>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

const CustomAction = (props) => (
    <>
        {/*<EditRowButton />*/}
        <DeleteWithConfirmIconButton mutationMode="undoable"/>
    </>
);

export const BookingsRefferense = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={' '}
            actions={<TableActions
                preference={`${resource}${".list.columns"}`}
                select={false}
                create={true}
                filter={true}
            />}
            // is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            <EditableDatagrid
                actions={<CustomAction/>}
                classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                //noDelete={true}
                // initialRowMode="edit"
                rowClick="edit"
                mutationMode="pessimistic" //Подтвердить удаление
                undoable
                createForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={false}/>}
                editForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={true}/>}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'} label={'Route'}>
                    <Box display={'grid'}>
                        <TextField source="route"/>
                    </Box>
                </ReferenceField>
                <BooleanField source={`is_main`} label={'Display'} textAlign={"center"}/>
                <ReferenceField source="airline_id" reference="airline" sortBy={'airline:code'} label={'Airline'}>
                    <Box display={'grid'}>
                        <TextField source="code"/>
                    </Box>
                </ReferenceField>
                <TextField source="pnr" label={'PNR'}/>
                <DateFieldComp source="created_at" label={'create'} textAlign={'center'} showTime={false}
                               locale={'us'} cellClassName={classes.date_st}/>
                <DateFieldComp source="updated_at" label={'update'} textAlign={'center'} showTime={false}
                               locale={'us'} cellClassName={classes.date_st}/>
            </EditableDatagrid>
        </ListComponentAdmin>
    )
};