import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../_common/EditComponentAdmin";
import SupplierEditCreate from "./SupplierEditCreate";
import {useRedirect} from "react-admin";

export const SupplierEdit = props => {
    const {location} = props;
    const {search} = location;
    const redirect = useRedirect()
    let supplierId = '';
    let programId = '';
    if(search){
        let url = new URL('https://test/' + search);
        supplierId = url.searchParams.get('supplierId')
        programId = url.searchParams.get('programId')
    }

    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            // onSuccess={() => props.history.goBack()}
            onSuccess={() => (supplierId || programId) ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <SupplierEditCreate supplierId={supplierId} programId={programId}/>
        </EditComponentAdmin>
    )
};