import {
    minValue,
    maxValue,
    email,
    maxLength,
    minLength,
    //regex,
    required,
} from "react-admin";

export const validateReq = [required()];

export const validateLoginRec = [required(), minLength(4), maxLength(30)];
export const validateStatusName = [required(), minLength(2), maxLength(30)];
export const validateFirstName = [required(), minLength(2), maxLength(50)];
export const validateCard = [required(), minLength(5), maxLength(5)];
export const validateFix3 = [required(), minLength(3), maxLength(3)];
export const validateNameRec = [required(), minLength(2), maxLength(100)];
export const validateFullNameRec = [required(), minLength(2), maxLength(100)];

export const validateAddressReq = [required(), minLength(2), maxLength(300)];
export const validateAddress = [minLength(2), maxLength(300)];

export const validatePositionRec = [required(), minLength(2), maxLength(100)];
export const validateTitleRec = [required(), minLength(2), maxLength(250)];
export const validateSubtitle = [minLength(2), maxLength(250)];
export const validateDescription = [minLength(2), maxLength(270)];


// export const validatePhoneRec = [required(), minLength(2), maxLength(70)];

export const validateCountry = [minLength(2), maxLength(50)];
export const validateState = [minLength(2), maxLength(50)];
export const validateCity = [minLength(2), maxLength(50)];
export const validateNotes = [maxLength(400)];

export const validateEmailReq = [required(), email()];
export const validateEmail = [email()];
export const validatePhoneReq = [required(), minLength(8), maxLength(24)];

export const validateSumaRec = [required(), minValue(1), maxValue(10000000000)];
// const validateAge = [number(), minValue(18), maxValue(118)];
//export const validateZipCode = [regex(/^\d{5}$/, 'Must be a valid Zip Code (5 digits)')];
export const validateZipCode = [minLength(3), maxLength(50)];
// const validateSex = choices(['m', 'f'], 'Must be Male or Female');

