import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../_common/EditComponentAdmin";
import CustomerEditCreate from "./CustomerEditCreate";
import {useRedirect} from "react-admin";

export const CustomerEdit = props => {
    const {location} = props;
    const {search} = location;
    const redirect = useRedirect()
    let customerId = '';
    let bookingId = '';
    if (search) {
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
        bookingId = url.searchParams.get('bookingId')
    }
    // const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            transform={data => {
                    /*if(data.is_company){
                        //data.b_day = null
                        data.f_name = null
                        data.l_name = null
                        data.title = null
                    }*/
                    return ({...data})
                }}
            onSuccess={() => bookingId
                ? bookingId === '0' ? redirect(`/booking/create`) : redirect(`/booking/${bookingId}/`)
                : redirect('list', props.basePath)//props.history.goBack()
            }
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <CustomerEditCreate customerId={customerId} bookingId={bookingId}/>
        </EditComponentAdmin>
    )
};