import * as React from 'react';
import {
    AutocompleteInput,
    FormDataConsumer,
    ReferenceInput, useRedirect,
    TextInput
} from "react-admin";
import get from "lodash/get";
import set from "lodash/set";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import useMyStyles from "../utils/useMyStyles";
import {SupplierListForTab} from "./SupplierListForTab";
import {GetFieldById} from "../_common/CompReact";
import {validateFirstName, validateReq} from "../utils/validateTable";
// import AirlineBooking from "./AirlineForBooking";

/*import {
    Edit,
} from 'react-admin';*/


const SupplierForBooking = (props) => {
    const redirect = useRedirect()
    const classes = useMyStyles();
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {

                const {id} = formData;

                let program_id = get(formData, 'program_id', null)
                let program_account_id = get(formData, 'program_account_id', null)
                if (!program_id) set(formData, 'program_account_id', null)

                let payment_type = get(formData, 'payment_type', null)

                let supplier_id = get(formData, 'supplier_id', null)

                let booking_code = get(formData, 'booking_code', null)
                set(formData, 'booking_code', booking_code ? booking_code.replaceAll('-', '') : booking_code)

                return (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom className={classes.booking_title}>
                                Suppliers
                            </Typography>
                        </Grid>
                        {payment_type === 'cash'
                            ?
                            <>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                source="supplier_id" reference="supplierData"
                                                sort={{field: 'title', order: 'ASC'}}
                                                validate={validateReq}
                                                filter={{is_miles: false}}
                                                label={'Suppliers (is not miles)'} fullWidth>
                                                <AutocompleteInput resettable
                                                                   optionText={record => (record && `${record.title} ${record.is_miles ? ' (is miles)' : ''}`)}
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            <Button
                                                style={{marginTop: -12, marginLeft: 24}}
                                                onClick={() => {
                                                    localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                                                    redirect(`/supplierDataNotMiles/create/?bookingId=${id}`)
                                                }}
                                                color="primary"
                                                label={'Create New'}
                                            >
                                                {`Create New Supplier`}
                                            </Button>
                                        </Grid>
                                        {supplier_id &&
                                            <Grid item xs={"auto"}>
                                                <Button
                                                    style={{marginTop: -12, marginLeft: 24}}
                                                    onClick={() => {
                                                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                                                        redirect(`/supplierDataNotMiles/${supplier_id}`)
                                                    }}
                                                    color="primary"
                                                    label={'Create New'}
                                                >
                                                    {`Edit Supplier`}
                                                </Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                {supplier_id &&
                                    <Grid item xs={12} style={{marginTop: -32}}>
                                        <b>Notes : </b>
                                        <GetFieldById id={supplier_id} resource={'supplierData'} bold={false}
                                                      field={'notes'}/>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        <Grid item xs={3}>
                                            <TextInput source={'booking_code'} label={'Supplier Booking Ref'}
                                                       validate={validateFirstName}
                                                       fullWidth/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: -32}}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        <Grid item xs={6}>
                                            <ReferenceInput
                                                sort={{field: 'description', order: 'ASC'}}
                                                source="card_id" reference="creditCard"
                                                validate={validateReq}>
                                                <AutocompleteInput
                                                    resettable
                                                    optionText={record => (record && `${record.description} *${record.last_cc}`)}
                                                    fullWidth
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        <Grid item>
                                            {/*<ReferenceInput source="supplier_id" reference="supplierData">
                                            <AutocompleteInput resettable
                                                               optionText={record => (record && `${record.title}`)}
                                            />
                                        </ReferenceInput>*/}
                                            <ReferenceInput
                                                sort={{field: 'title', order: 'ASC'}}
                                                source="program_id" reference="programs"
                                                validate={validateReq}
                                                label={'Program name'}>
                                                <AutocompleteInput resettable
                                                                   optionText={record => (record && `${record.title}`)}
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                        {program_id &&
                                            <Grid item>
                                                <ReferenceInput
                                                    sort={{field: 'account_name', order: 'ASC'}}
                                                    source="program_account_id" reference="supplier"
                                                    validate={validateReq}
                                                    filter={{
                                                        program_id: program_id,
                                                        'supplier:is_miles': true
                                                    }}
                                                    label={'Program account name'}>
                                                    <AutocompleteInput
                                                        resettable
                                                        //optionText={record => (record && `${record.login} (Dealers: ${record.supplier_title})`)}
                                                        optionText={record => (record && `${record.account_name}`)}
                                                    />
                                                </ReferenceInput>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <Button
                                                style={{marginTop: -12, marginLeft: 24}}
                                                //disabled={!data[resource].passenger_id}
                                                onClick={() => {
                                                    localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                                                    redirect(`/supplier/create`)
                                                }}
                                                color="primary"
                                                label={'Create New'}
                                            >
                                                {`Create New Program Account`}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        <Grid item xs={12}>
                                            <Box>
                                                {program_id && program_account_id &&
                                                    <Box display="grid" alignItems={"center"}>
                                                        <Box flex={1}>
                                                            <SupplierListForTab
                                                                resource={'supplier'}
                                                                basePath={`/supplier`}
                                                                booking_id={id}
                                                                program_account_id={program_account_id}
                                                            />
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/*{payment_type === 'miles_only' &&
                                    <Grid item xs={12}>
                                        <Grid container alignItems={"center"} spacing={2}>
                                            <Grid item xs={3}>
                                                <ReferenceInput source="issued_airline_id" reference="airline"
                                                                validate={validateReq}
                                                                label={'Issued airline'}>
                                                    <AutocompleteInput resettable
                                                                       optionText={record => (record && `(${record.code}) ${record.title}`)}
                                                                       fullWidth/>
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={12} style={{marginTop: -40}}>
                                                <AirlineBooking {...props}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }*/}
                            </>
                        }
                    </>
                )
            }}
        </FormDataConsumer>
    )
};
export default SupplierForBooking;
