const Permission = [
    {
        role: 'Admins',
        permissions: [
            {action: "*", resource: "*"},
        ]
    },
    {
        role: '_Admins',
        permissions: [
            {action: "", resource: "dashboard"},//Запрещен
            {action: ['list', 'edit', 'delete'], resource: "booking"},
            {action: "*", resource: "*"}
            /*{action: ['list'], resource: "*"},
            {action: "*", resource: "locating"},
            {action: "*", resource: "questions"},
            {action: "*", resource: "answers"},*/
        ]
    },
    {
        role: 'Managers',
        permissions: [
            {action: "", resource: "dashboard"},//Запрещен
            {action: ['list', 'edit', 'delete'], resource: "booking"},
            {action: "*", resource: "*"},
        ]
    },
    /*{
        role: 'Managers',
        permissions: [
            {action: "", resource: "debug"},//Запрещен
            {action: "*", resource: "events"},
            {action: "*", resource: "messages"},
            {action: "*", resource: "event-visitors"},
            {action: "*", resource: "profiles"},
        ]
    }*/
];

export function canAccess2(params) {

    const {resource, action} = params;
    let group = localStorage.getItem('bookmanCognitoGroups');
    let ret = false;
    if(group){
        let res = Permission.find(x => x.role === group);
        if(!res) res = {permissions: [{action: ['list'], resource: "*"}]};
        if(res){
            let permAll = res.permissions.find(x => x.resource === '*');
            let permRes = res.permissions.find(x => x.resource === resource);
            if(permRes){
                if(permRes.action === '*' || permRes.action.indexOf(action) >=0) ret = true;
            } else if(permAll){
                if(permAll.action === '*' || permAll.action.indexOf(action) >=0) ret = true;
            }
        }
    }
    return ret;

}

