//import * as React from 'react';
//import {TextInput} from 'react-admin';

import {AutocompleteInput, ReferenceInput} from "react-admin";
import * as React from "react";

export const SupplierTopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="program_id" reference="programs" alwaysOn>
            <AutocompleteInput optionText="title" resettable emptyText={'All Programs'}/>
        </ReferenceInput>);
    dt.push(<ReferenceInput source="supplier_id" reference="supplierData" alwaysOn label={'Dealers'} filter={{is_miles: true}}>
            <AutocompleteInput optionText="title" resettable emptyText={'All Dealers'}/>
        </ReferenceInput>);
    // dt.push(<DateInputComp source={"expiration_date_gte"} label={"Expiration date from"} validate={validateReq} fullWidth/>);
    // dt.push(<DateInputComp source={"expiration_date_lte"} label={"Expiration date to"} validate={validateReq} fullWidth/>);
    return dt;
}
