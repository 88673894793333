import {fetchJson as httpClient} from "./keycloak";
import {apiPathConfig} from "./config";
import {stringify} from 'query-string';
//import {getOrderingQuery} from "ra-data-django-rest-framework";
import localeAdapter from "./localeAdapter";

const getPaginationQuery = (pagination) => {
    return {
        skip: (pagination.page - 1) * pagination.perPage,
        limit: pagination.perPage,
        // page: pagination.page,
        // page_size: pagination.perPage,
    };
};

const getOrderingQueryMe = (sort) => {
    const {field, order} = sort;
    return {
        //sort: `${order === 'ASC' ? '' : '-'}${field}`,
        ordering: `${order === 'ASC' ? '' : '-'}${field}`,
    };
};

const getFilterQuery = (filter, resource) => {
    const {q: search, ...otherSearchParams} = filter;
    //console.log(resource, 'resourc====>', filter)
    if (resource === "booking" && search)
        return {
            ...otherSearchParams,
            for_invoice: search,
        };
    else
        return {
            ...otherSearchParams,
            search,
        };
};


const getOneJson = (resource, id, type = '') =>
    httpClient(`${apiPathConfig.getUrl(resource, type)}/${id}`).then(
        (response) => localeAdapter.toReactRow(response.json)
    );

/*const getOneJson = (resource, id, type='') =>
    httpClient(`${apiPathConfig.getUrl(resource, type)}/${id}/`).then(
        (response) => response.json
    );*/


export function getCognitoClient() {
    const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
    const user = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
    //const token = 'Bearer ' + localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${user}.accessToken`);

    let jwtToken = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${user}.accessToken`);
    if (jwtToken) {
        var base64Url = jwtToken.split('.')[1];
        var base64 = decodeURIComponent(atob(base64Url).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        jwtToken = JSON.parse(base64);
        localStorage.setItem('bookmanCognitoGroups', jwtToken['cognito:groups'][0]);
        //console.log('cognito:groups=====>', jwtToken['cognito:groups'][0]);
    } else {

    }
}

const dataProvider = {
    getList: async (resource, params) => {
        //console.log('getList===========>',resource, params)
        if (resource === "booking") {//Облагородить
            if (params.filter.dealer_id !== undefined) {
                params.filter.supplier_id = params.filter.dealer_id
                delete params.filter.dealer_id
            }
        }
        const query = {
            ...getFilterQuery(params.filter, resource),
            ...getPaginationQuery(params.pagination),
            //...getOrderingQuery(params.sort),
            ...getOrderingQueryMe(params.sort),
        };
        //const url = `${apiPathConfig.getUrl(resource)}/?${stringify(query)}`;
        const url = `${apiPathConfig.getUrl(resource)}?${stringify(query)}`;

        //console.log('data-provider url = '+url)

        const {json} = await httpClient(url);
        /*const dt = {
            count: 2,
            results: json,
        }*/
        return {
            data: localeAdapter.toReact(json.hasOwnProperty('results') ? json.results : json),
            // data: json.results,
            total: json.count,
        };
    },

    getOne: async (resource, params) => {
        const data = await getOneJson(resource, params.id, 'getOne');
        return {
            data,
        };
    },

    getMany: (resource, params) => {
        return Promise.all(
            params.ids.map(id => getOneJson(resource, id))
        ).then(data => ({data}));
    },

    getManyReference: async (resource, params) => {
        const query = {
            ...getFilterQuery(params.filter, resource),
            ...getPaginationQuery(params.pagination),
            // ...getOrderingQuery(params.sort),
            ...getOrderingQueryMe(params.sort),
            [params.target]: params.id,
        };
        const url = `${apiPathConfig.getUrl(resource)}?${stringify(query)}`;

        const {json} = await httpClient(url);
        return {
            data: localeAdapter.toReact(json.hasOwnProperty('results') ? json.results : json),
            total: json.count,
        };
    },

    update: async (resource, params) => {
        const {json} = await httpClient(`${apiPathConfig.getUrl(resource, 'update')}/${params.id}`, {
            method: 'PUT',//'PATCH', 'PUT'
            body: JSON.stringify(localeAdapter.toDjangoRow(params.data)),
        });
        return {data: localeAdapter.toReactRow(json)};
    },

    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiPathConfig.getUrl(resource)}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(localeAdapter.toDjango(params.data)),
                })
            )
        ).then(responses => (localeAdapter.toReact({data: responses.map(({json}) => json.id)}))),

    create: async (resource, params) => {
        const {json} = await httpClient(`${apiPathConfig.getUrl(resource, 'create')}`, {
            method: 'POST',
            body: JSON.stringify(localeAdapter.toDjangoRow(params.data)),
        });
        return {
            data: localeAdapter.toReactRow({...json}),
        };
    },

    delete: (resource, params) =>
        httpClient(`${apiPathConfig.getUrl(resource)}/${params.id}`, {
            method: 'DELETE',
        }).then(() => ({data: params.previousData})),

    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiPathConfig.getUrl(resource)}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(() => ({data: []})),
}


export default dataProvider
