import * as React from 'react';
import {
    NumberField, ReferenceField,
    //ReferenceField,
    TextField,
    //useRecordContext,
    //EmailField,
    //BooleanField,
} from 'react-admin';
import {DateFieldComp, TextFieldBold} from "../_common/CompReact";
//import {GetString} from "../booking/BookingColumnsForList";

export const ProgramsColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        title: <TextFieldBold source={`title`} label={'Program Name'}/>,
        airline_id: /*<TextFieldBold source={`title`} label={'Program Name'}/>,*/
            <ReferenceField source="airline_id" reference="airline" label={'Airline'}
                            sortBy={'airline:title'}>
                {/*<GetString source="title" title={'code'}/>*/}
                <TextField source={'title'}/>
            </ReferenceField>,
        program_accounts_count: <TextField source={`program_accounts_count`} label={'No. Accounts'}
                                           textAlign={"right"}/>,
        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};
