import * as React from 'react';

import {
    //CloneButton,
    CreateButton,
    FilterButton,
    TopToolbar,
    useTranslate,
    ExportButton,
} from 'react-admin';
import {SelectColumnsButton} from "@react-admin/ra-preferences";
import {ButtonFilterAside} from "./CompReact";
import {Box} from "@material-ui/core";

const TableActions = (
    {
        preference,
        columns,
        basePath,
        data,
        create = false,
        create2 = false,
        create2Label='create2Label',
        select = true,
        filter = true,
        asideFilter = false,
        exportCsv = false,
        showAsside = true,
        exporterFile = false,
        exporterlabel = 'Export',
        handlerForAllTable = false,
        handlerForAllTable2 = false,
    }
) => {

    const translate = useTranslate();
    /*const handleCreate = (event,to) => {
        // event.preventDefault();
        // event.stopPropagation();
        console.log('=to===========>', to)
        redirect(to);
    };*/
    //console.log('props==============>', props)
    return (
        <TopToolbar fullWidth>
            <Box display="flex" alignItems={"center"}>
                {asideFilter && <ButtonFilterAside showAsside={showAsside}/>}
                {filter && <FilterButton/>}
                {/*<CloneButton basePath={basePath} record={data} />*/}
                {create && <CreateButton basePath={basePath} record={data}/>}
                {create2 && <CreateButton to={create2} label={create2Label} record={data}/>}
                {/*{create2 &&
                    <Button size="small" color="primary" onClick={(event) => handleCreate(event,create2)}>
                    <AddIcon/>
                    {create2Label}
                </Button>}*/}
                {select && <SelectColumnsButton
                    preference={preference}
                    columns={columns}
                    label={translate('myLocal.columns')}
                />}
                {handlerForAllTable && handlerForAllTable}
                {handlerForAllTable2 && handlerForAllTable2}
                {exporterFile && <ExportButton exporter={exporterFile} label={exporterlabel}/>}
                {exportCsv && <ExportButton label={'Export'}/>}
            </Box>
        </TopToolbar>
    )
};
export default TableActions;
