import * as React from 'react';
import {CardContent} from '@material-ui/core';
import {FilterLiveSearch} from 'react-admin';

import CardComp from "../_common/CardComp";
import {FilterDataAside, FilterDataAside2} from "../_common/CompReact";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch label={'Search'}/>
                <FilterDataAside2 fields={'transaction_date'} name={'Transaction date'}/>
                {/*<FilterDataAside fields={'created_at'} name={'created'}/>*/}
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                {/*<TrueFalseFilters field={'paid'} label={'paid'}/>*/}
                {/*<DynamicFiltersChoices
                    reference={stateQuestion}
                    fieldsSearch={'state'}
                    fieldsNameForFilter={'name'}
                />*/}
            </CardContent>
        </CardComp>
    )
};

export default Aside;
