import * as React from 'react';

import {
    BulkDeleteButton,
} from 'react-admin';

import {EditableDatagrid} from "@react-admin/ra-editable-datagrid";

const MyEditableDatagridForReference = (props) => {
    const {
        children,
        hasBulkActions = true,
        noDelete = false,
    } = props;
    const {selectedIds} = props

    //console.log('props=====>', props)

    return (
        <>
            {!noDelete && hasBulkActions && selectedIds && selectedIds.length > 0 &&
                <BulkDeleteButton
                    {...props}
                    style={{float: "left"}}
                    label={`Delete Marked (${selectedIds.length})`}
                    //icon={false}
                    undoable={false} //Подтверждение удаления
                />
            }
            <EditableDatagrid {...props}
                              empty={false}
                              hasBulkActions={hasBulkActions}
                              noDelete={noDelete}
            >
                {children}
            </EditableDatagrid>
        </>
    )
};

export default MyEditableDatagridForReference;
