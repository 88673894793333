import * as React from 'react';
import {
    AutocompleteInput, ReferenceField, ReferenceInput,
    ReferenceManyField,
    TextField,
    TextInput, useRecordContext, useUpdate,
} from 'react-admin';
import {Grid, Typography} from "@material-ui/core";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {DateFieldComp, DateInputComp, NumberInputFloatComp} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";
import {FloatNumber, formatSpacerNumber} from "./BookingColumnsForList";
import {useEffect} from "react";
import get from "lodash/get";
//import set from "lodash/set";

const transform = (data) => {
    console.log('Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    if (data.date === undefined) data.date = null
    if (data.date_to === undefined) data.date_to = null
    if (data.date_to && !data.date) {
        data.date = data.date_to
        data.date_to = null
    }
    return data;
}

/*
non_flight - booking type transaction

we  just need

Customer
Passenger
Non-Flight Transaction Type: Transaction Type

Supplier
and notes
*/

const ListEditForm = (props) => {
    const {booking_id} = props;
    return (
        <RowForm  {...props}
                  transform={transform}
                  initialValues={{booking_id: booking_id}}
                  style={{verticalAlign: "top"}}
        >
            <ReferenceInput
                sort={{field: 'value', order: 'ASC'}}
                source="value_id" reference="non_flight_transactions"
                label={'Type'}
                validate={validateReq}>
                <AutocompleteInput
                    resettable
                    optionText="value"
                    // defaultValue={parseInt(customer_id) ? parseInt(customer_id) : null}
                />
            </ReferenceInput>
            <TextInput source={'notes'} label={'Notes'} multiline style={{maxWidth: 150}}/>
            <NumberInputFloatComp style={{maxWidth: 120}}
                                  source="cost_amount_dollar" label={'Amount'} validate={validateReq}/>
            <NumberInputFloatComp style={{maxWidth: 130}}
                                  source="amount_dollar" label={'Sale Amount ($)'} validate={validateReq}/>
            <TextInput source={'route'} label={'Route'} multiline style={{maxWidth: 150}}/>
            {/*<NumberInputFloatComp source="profit" label={'Profit ($)'}/>*/}
            <DateInputComp source={"date"} label={"Date"} style={{maxWidth: 130}}/>
            <DateInputComp source={"date_to"} label={"Date to"} style={{maxWidth: 130}}/>

            <GetProfit label={'Profit ($)'} textAlign={"right"} style={{maxWidth: 120}}/>
            {/*<FormDataConsumer>
                {({formData, ...rest}) => {
                    return (
                        <NumberInputFloatComp source={'amount_dollar'} Label={'Amount ($)'}/>
                    )
                }}
            </FormDataConsumer>*/}
        </RowForm>
    )

};

const GetProfit = (props) => {
    //const {classes} = props;
    const record = useRecordContext(props);
    let profit = 0;
    let cost_amount_dollar = get(record, 'cost_amount_dollar', null);
    let amount_dollar = get(record, 'amount_dollar', null);
    if (amount_dollar && cost_amount_dollar) profit = amount_dollar - cost_amount_dollar;
    return (
        <span>{formatSpacerNumber(profit)}</span>
    )
}

const DtGr = (props) => {
    const {booking_id, classes, data, setTotal, loaded} = props;

    useEffect(() => {
        if (loaded) {
            let sale_amount = 0;
            let cost_amount = 0;
            if (data) {
                //console.log('BookingNonFlightTransactions====loaded=======>', loaded)
                Object.entries(data).forEach(([key, value]) => {
                    sale_amount += value.amount_dollar;
                    cost_amount += value.cost_amount_dollar;
                });
            }
            setTotal({
                loaded: true,
                saleTotal: sale_amount,
                costTotal: cost_amount
            })
        }
    }, [data, loaded]) // eslint-disable-line


    return (
        <EditableDatagrid
            {...props}
            /*data={data}
            total={total}*/
            classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
            //noDelete={true}
            /*onChange={(data) => {
                console.log('===fdsBookingForm==========>', data)
                // localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
            }}*/
            mutationMode="pessimistic" //Подтвердить удаление
            undoable
            createForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
            editForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
            //rowStyle={postRowStyle}
        >
            <ReferenceField source="value_id" reference="non_flight_transactions" label={'Type'}
                            link={false} textAlign={"left"}>
                <TextField source="value"/>
            </ReferenceField>
            <TextField source={'notes'} label={'Notes'}/>
            <FloatNumber source={'cost_amount_dollar'} label={'Cost Amount ($)'} textAlign={"right"}/>
            <FloatNumber source={'amount_dollar'} label={'Sale Amount ($)'} textAlign={"right"}/>
            <TextField source={'route'} label={'Route'}/>
            <DateFieldComp source="date" label={'Date'}
                           headerClassName={classes.date_st} cellClassName={classes.date_st} showTime={false}
                           locale={'us'}/>
            <DateFieldComp source="date_to" label={'Date To'}
                           headerClassName={classes.date_st} cellClassName={classes.date_st} showTime={false}
                           locale={'us'}/>
            <GetProfit label={'Profit ($)'} textAlign={"right"}/>
        </EditableDatagrid>
    )
};

const BookingNonFlightTransactions = props => {
    const {booking_id, formData} = props;
    const classes = useMyStyles();
    // const [update, { isLoading, error }] = useUpdate();
    const [update] = useUpdate();
    const [total, setTotal] = React.useState({
        loaded: false,
        saleTotal: 0,
        costTotal: 0,
    });
    useEffect(() => {
        if (total.loaded) {
            if (formData.cost_total_dollar !== total.costTotal || formData.sale_total_dollar !== total.saleTotal) {
                //formEdit.change('sale_total_dollar', total.saleTotal)
                //formEdit.change('cost_total_dollar', total.costTotal)
                //console.log(booking_id, 'BookingNonFlightTransactions===========>', total)
                update(
                    'booking',
                    booking_id,
                    {sale_total_dollar: total.saleTotal, cost_total_dollar: total.costTotal},
                    {sale_total_dollar: formData.sale_total_dollar, cost_total_dollar: formData.cost_total_dollar},
                );
            }
        }
    }, [total]) // eslint-disable-line

    /*useEffect(() => {
        if (id) {
            setTimeout(function () {
                setStartExchangeRate({fee: true, tax: true, amountTax: true, amountFee: true,})
            }, 500);
        }
    }, [id]);// eslint-disable-line*/

    // const redirect = useRedirect()

    /*const { data, total, isLoading } = useGetList('travelCredit',
        { page: 1, perPage: 10 },
        {},
        { filter: {booking_id: booking_id}},
    );
    if (isLoading) return null;
    console.log('data======>', data)*/

    //let data = []; let total = 0;
    return (
        <>
            <Grid item xs={12} className={classes.booking_title}>
                <Typography variant="h6" gutterBottom>
                    Non Flight Cat.
                </Typography>
            </Grid>
            <div style={{width: "max-content", minWidth: 500, marginBottom: 16}}>
                <ReferenceManyField
                    //fullWidth
                    label=" "
                    reference="bookings_non_flight_transactions"
                    target="booking_id"
                >
                    <DtGr classes={classes} booking_id={booking_id} setTotal={setTotal}/>
                </ReferenceManyField>

                <Grid container style={{marginTop: 24, textAlign: "end"}}>
                    <Grid item xs={4}>
                        <div className={classes.cost_show_field_calc}>
                            <div>
                                <span style={{fontSize: 12}}>{'Cost Total Amount ($)'}</span>
                            </div>
                            <div>
                                <span>{formatSpacerNumber(total.costTotal)}</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.cost_show_field_calc}>
                            <div>
                                <span style={{fontSize: 12}}>{'Sale Total Amount ($)'}</span>
                            </div>
                            <div>
                                <span>{formatSpacerNumber(total.saleTotal)}</span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={4} style={{textAlign: "center"}}>
                        <div className={classes.cost_show_field_calc}>
                            <div>
                                <span style={{fontSize: 12}}>{'Profit ($)'}</span>
                            </div>
                            <div>
                                <span>{formatSpacerNumber(total.saleTotal - total.costTotal)}</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </div>
        </>
    )
}
export default BookingNonFlightTransactions;