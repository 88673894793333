import * as React from 'react';
import {
    TextInput, TextField, ReferenceManyField, NumberField,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {DateFieldComp, DateInputComp} from "../_common/CompReact";
import {validateFirstName, validateReq} from "../utils/validateTable";
import {Box} from "@material-ui/core";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {booking_id} = props;
    /*const {getValues} = useFormContext();
    console.log(booking_id, '=====OK======>', getValues("id"))*/
    return (
        <RowForm  {...props} initialValues={{booking_id: booking_id}}>
            {/*<DateInputComp source={"refunded_date"} label={"Refunded date"}/>*/}
            <div></div>
            <DateInputComp source={"hc_travel_date"} label={"Travel date"} validate={validateReq}/>
            <TextInput source="hc_route" label={'Route'} validate={validateFirstName}/>
            <TextInput source="hc_flight_number" label={'Flight number'} validate={validateFirstName}/>
            {/*<SelectInput source="flight_status" choices={hidden_city_flight_status} validate={validateReq}/>*/}
            {/*<NumberInputFloatComp source="refund" label={'Dead-end Refund'} validate={validateReq}/>*/}
        </RowForm>
    )
};

const HiddenCityForBooking2 = props => {
    const {booking_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    return (
        <div style={{width: "max-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                fullWidth
                label=" "
                reference="hiddenCity"
                target="booking_id"
            >
                <EditableDatagrid
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={true}
                    /*onChange={(data) => {
                        console.log('===fdsBookingForm==========>')
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    }}*/
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {/*<DateFieldComp source="refunded_date" label={'Refunded Date'} textAlign={"center"}
                                   cellClassName={classes.date_st} showTime={false} locale={'us'}/>*/}
                    <Box sortBy={'id'} label={'HC\u00a0ID'}>
                        {'HC\u00a0'}<NumberField source="id"/>
                    </Box>
                    <DateFieldComp source="hc_travel_date" label={'Travel Date'} textAlign={"center"}
                                   cellClassName={classes.date_st} showTime={false} locale={'us'}/>
                    <TextField source={`hc_route`} label={'Route'}/>
                    <TextField source={`hc_flight_number`} label={'Flight Number'}/>
                    {/*<SelectAndSaveChoices
                        label={'Flight Status'}
                        source="flight_status"
                        statuses={hidden_city_flight_status}
                        resource={'hiddenCity'}
                        textAlign={'center'}
                        showTextValue={true}
                        width={'11em'}
                    />*/}
                    {/*<FloatNumber source={`refund`} label={'Dead-end Refund'} cellClassName={classes.quantity_st}
                                 textAlign={"right"}/>*/}
                </EditableDatagrid>
            </ReferenceManyField>
        </div>
    )
}
export default HiddenCityForBooking2;