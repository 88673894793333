import * as React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    NumberInput,
    ReferenceInput, SelectInput, SimpleFormIterator, TextInput,
} from "react-admin";
import get from "lodash/get";
//import set from "lodash/set";
import {Box, Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import {GetFieldById, NumberInputFloatComp} from "../_common/CompReact";
import useMyStyles from "../utils/useMyStyles";
import {useEffect} from "react";
import {validateReq} from "../utils/validateTable";
import {calcPassenger, formatSpacerNumber} from "./BookingColumnsForList";
import {useSelector} from "react-redux";
import {apiPathConfig} from "../utils/config";
import Alert from '@material-ui/lab/Alert';
import {config} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
import {canAccess2} from "../utils/canAccess2";
/*import {
    Edit,
} from 'react-admin';*/

/*const convertStringToNumber = value => {
    const float = parseFloat(value);
    return isNaN(float) ? null : float;
};*/

/*const CustomNumberInput = (props) => {
    useEffect(() => {
        const handleWheel = (event) => {
            /!*if (document.activeElement.type === 'number') {
                event.preventDefault();
            }*!/
            if (document.activeElement === event.target && event.target.type === 'number') {
                event.preventDefault();
            }
        };
        window.addEventListener('wheel', handleWheel, {passive: false});
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);
    return <NumberInput {...props} />;
};*/

const SaleForBooking = (props) => {
    const {id, formData, formEdit, startExchangeRate, setStartExchangeRate, record} = props;
    const classes = useMyStyles();

    let canAccess = canAccess2({resource: 'booking', action: 'field'});

    const [calcOtherCosts, setCalcOtherCosts] = React.useState(true);
    const [isOtherCosts, setIsOtherCosts] = React.useState(false);

    const [calcRowTax, setCalcRowTax] = React.useState(false);
    const [calcRowFees, setCalcRowFees] = React.useState(false);
    const [calcRowMilesSale, setCalcRowMilesSale] = React.useState(false);

    const [amountCredit, setAmountCredit] = React.useState(0);
    const [arrayTravelCredit, setArrayTravelCredit] = React.useState([]);

    const [amountCreditCost, setAmountCreditCost] = React.useState(0);
    const [arrayTravelCreditCost, setArrayTravelCreditCost] = React.useState([]);
    const [profit, setProfit] = React.useState(0);

    const [perPassengerCost, setPerPassengerCost] = React.useState(formData.cost_per_passenger_amount ? true : false);
    const [perPassengerSale, setPerPassengerSale] = React.useState(formData.sale_per_passenger_amount ? true : false);
    const handleChangeCost = (event) => {
        //cost_per_passenger_amount
        //sale_per_passenger_amount
        setPerPassengerCost(event.target.checked);
    };
    const handleChangeSale = (event) => {
        setPerPassengerSale(event.target.checked);
    };

    let currencyData = useSelector((state) => state.admin.resources.currency.data)
    let countPassengers = calcPassenger(formData.passengers)//formData.passengers.length;

    let program_account_id = get(formData, 'program_account_id', null)
    let payment_type = get(formData, 'payment_type', null)

    let cost_ticket_cc_fee_currency_id = get(formData, 'cost_ticket_cc_fee_currency_id', null)
    if (cost_ticket_cc_fee_currency_id === null) {//defaultValue USD
        formEdit.change('cost_ticket_cc_fee_currency_id', 1)
        formEdit.change('cost_ticket_cc_fee_currency_exchange', 1)
    }
    let cost_ticket_tax_currency_id = get(formData, 'cost_ticket_tax_currency_id', null)
    if (cost_ticket_tax_currency_id === null) {//defaultValue USD
        formEdit.change('cost_ticket_tax_currency_id', 1)
        formEdit.change('cost_ticket_tax_currency_exchange', 1)
    }

    let nameCostTax = 'Taxes';
    if (payment_type === 'cash') nameCostTax = 'Ticket';

    useEffect(() => {
        /*let par = get(formData, 'sale_total_dollar', '');
        if (par) {
            if (formData.payment_type !== 'miles_only') {
                let par2 = get(formData, 'travel_credit_dollar', '');
                if (par2) par = par + par2;
            }
            let par3 = get(formData, 'cost_total_dollar', '');
            if (par3) par = par - par3;
            if (par) par = formatSpacerNumber(par);
        }*/
        let par = get(formData, 'sale_total_dollar', 0);
        // if (par) {
        if (formData.payment_type !== 'miles_only') {
            let par2 = get(formData, 'travel_credit_dollar', 0);
            par = par + par2;
        }
        let par3 = get(formData, 'cost_total_dollar', 0);
        par = par - par3;
        par = formatSpacerNumber(par);
        if (par === '') par = 0
        // }
        setProfit(par);
    }, [formData.sale_total_dollar, formData.travel_credit_dollar, formData.cost_total_dollar]) // eslint-disable-line

    useEffect(() => {
        if (startExchangeRate.fee) {
            let couuntPas = countPassengers ? countPassengers : 1;
            if (perPassengerCost) {
                if (formData.cost_ticket_tax_amount) {
                    let res = (formData.cost_ticket_tax_amount / couuntPas).toFixed(2);
                    formEdit.change('cost_per_passenger_amount', res)
                } else {
                    formEdit.change('cost_per_passenger_amount', formData.cost_ticket_tax_amount)
                }
            } else {
                if (formData.cost_per_passenger_amount) {
                    let res = (formData.cost_per_passenger_amount * couuntPas).toFixed(2);
                    formEdit.change('cost_ticket_tax_amount', res)
                } else {
                    formEdit.change('cost_ticket_tax_amount', formData.cost_per_passenger_amount)
                }
                formEdit.change('cost_per_passenger_amount', 0);
            }
        }
    }, [perPassengerCost]) // eslint-disable-line
    useEffect(() => {
        if (startExchangeRate.fee) {
            let couuntPas = countPassengers ? countPassengers : 1;
            if (perPassengerCost) {
                if (formData.cost_per_passenger_amount) {
                    let res = (formData.cost_per_passenger_amount * couuntPas).toFixed(2);
                    formEdit.change('cost_ticket_tax_amount', res)
                } else {
                    formEdit.change('cost_ticket_tax_amount', formData.cost_per_passenger_amount)
                }
            }

        }
    }, [formData.cost_per_passenger_amount]) // eslint-disable-line

    useEffect(() => {
        if (startExchangeRate.fee) {
            let couuntPas = countPassengers ? countPassengers : 1;
            if (perPassengerSale) {
                if (formData.sale_amount_dollar) {
                    let res = (formData.sale_amount_dollar / couuntPas).toFixed(2);
                    formEdit.change('sale_per_passenger_amount', res)
                } else {
                    //let res = (+formData.sale_amount_dollar / couuntPas).toFixed(2);
                    //formEdit.change('sale_per_passenger_amount', res)
                    formEdit.change('sale_per_passenger_amount', formData.sale_amount_dollar)
                }
            } else {
                if (formData.sale_per_passenger_amount) {
                    let res = (formData.sale_per_passenger_amount * couuntPas).toFixed(2);
                    formEdit.change('sale_amount_dollar', res)
                } else {
                    //let res = (+formData.sale_amount_dollar / couuntPas).toFixed(2);
                    //formEdit.change('sale_per_passenger_amount', res)
                    formEdit.change('sale_amount_dollar', formData.sale_per_passenger_amount)
                }
                formEdit.change('sale_per_passenger_amount', 0);
            }

        }
    }, [perPassengerSale]) // eslint-disable-line
    useEffect(() => {
        if (startExchangeRate.fee) {
            let couuntPas = countPassengers ? countPassengers : 1;
            if (perPassengerSale) {
                if (formData.sale_per_passenger_amount) {
                    let res = (formData.sale_per_passenger_amount * couuntPas).toFixed(2);
                    formEdit.change('sale_amount_dollar', res)
                } else {
                    formEdit.change('sale_amount_dollar', formData.sale_per_passenger_amount)
                }
            }
        }
    }, [formData.sale_per_passenger_amount]) // eslint-disable-line

    useEffect(() => {
        if (startExchangeRate.fee) {
            let couuntPas = countPassengers ? countPassengers : 1;
            if (perPassengerSale) {
                let res = (+formData.sale_amount_dollar / couuntPas).toFixed(2);
                formEdit.change('sale_per_passenger_amount', res)
            }
        }
    }, [formData.sale_amount_dollar]) // eslint-disable-line

    const getCostTravelCredit = async (id) => {
        const url = apiPathConfig.getApiUrl()
        let report_url = `booking/admin/booking_cost_travel_credit?booking_id=${id}`;
        try {
            const response = await httpClient(`${url}${report_url}`);
            let res = null
            if (response.status >= 200 && response.status <= 300) {
                //const {count = 0, results = []} = response.json || {};
                const {results = null, count = 0} = response.json || {};
                res = count === 0 ? null : results
                formEdit.change('booking_cost_travel_credit', res);
                formEdit.change('ori_booking_cost_travel_credit', res);
            }
        } catch (err) {
            console.log('err=======>', err);
            formEdit.change('booking_cost_travel_credit', null);
            formEdit.change('ori_booking_cost_travel_credit', null);
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }
    useEffect(() => {
        let costTravelCredit = 0
        if (formData.booking_cost_travel_credit && formData.booking_cost_travel_credit.length > 0) {
            // console.log('formData.booking_cost_travel_credit===========>', formData.booking_cost_travel_credit[0])
            if (formData.booking_cost_travel_credit.length === 1 && formData.booking_cost_travel_credit[0] === undefined) {
                // console.log('formData.booking_cost_travel_credit==2=========>', formData.booking_cost_travel_credit[0])
                formEdit.change(
                    'booking_cost_travel_credit',
                    [{
                        cost_travel_credit_id: formData.cost_travel_credit_id,
                        cost_travel_credit_dollar: formData.cost_travel_credit_dollar,
                    }]
                );
                costTravelCredit = formData.cost_travel_credit_dollar
            } else {
                formData.booking_cost_travel_credit.forEach((it, ind) => {
                    const {cost_travel_credit_dollar = 0, cost_travel_credit_id = null} = it || {}
                    if (cost_travel_credit_id) costTravelCredit += cost_travel_credit_dollar
                    if (ind === 0 && cost_travel_credit_id)
                        formEdit.change('cost_travel_credit_id', cost_travel_credit_id);
                })
            }
            formEdit.change('cost_travel_credit_dollar', costTravelCredit);
        }

    }, [formData.booking_cost_travel_credit]) // eslint-disable-line

    const getData = async (id = false, type) => {
        const url = apiPathConfig.getApiUrl()
        //http://fds.stemsc.com:8000/api/reports/travel_credits?airline=17&columns=customer,airline
        let report_url = `api/reports/travel_credits?owner=${id}&columns=passengers,owner,airline,travel_credit`;
        if (type === 'cost') //report_url = `api/reports/travel_credits?customer=${id}&owner=${config.bookmanID}&airline=${formData.issued_airline_id}&columns=customer,airline,travel_credit`;
            report_url = `api/reports/travel_credits?owner=${config.bookmanID}&airline=${formData.issued_airline_id}&columns=passengers,customer,airline,travel_credit`;
        try {
            // const response = await fetch(`${url}${report_url}`);
            const response = await httpClient(`${url}${report_url}`);

            //setIsCopy(true);
            //if (response.ok) {
            if (response.status >= 200 && response.status <= 300) {
                //const {count = 0, results = []} = response.json || {};
                const {results = []} = response.json || {};
                /*let actualData = await response.json();
                const {results = []} = actualData || {};*/
                let credit = 0;
                let arCredit = [];

                results.forEach((it) => {
                    if (it.travel_credit_id) {
                        //if (it.airline_id === formData.issued_airline_id)
                        let amount = 0;
                        let fl = false;
                        if (type === 'cost') {
                            if (it.travel_credit_id === record.cost_travel_credit_id) {
                                amount = it.amount + record.cost_travel_credit_dollar;
                                fl = true;
                            } else amount = it.amount;
                        } else {
                            if (it.travel_credit_id === record.travel_credit_id) {
                                amount = it.amount + record.travel_credit_dollar;
                                fl = true;
                            } else amount = it.amount;
                        }
                        if (it.amount) credit += it.amount
                        arCredit.push({
                            id: it.travel_credit_id,
                            name: <span
                                style={{color: fl && 'green'}}>
                                {`$ ${amount ? formatSpacerNumber(amount) : '0.00'} (${formatSpacerNumber(it.amount_dollar)})
                                 exp: ${it.expire_date} (${it.airline}) ${it.passengers}`}</span>,
                            //disabled: it.airline_id !== formData.issued_airline_id,
                            disabled: amount ? false : true,
                            amount: amount,
                        })
                    }
                });
                //console.log('response=======>', response, actualData);
                if (type === 'cost') {
                    setAmountCreditCost(credit);
                    setArrayTravelCreditCost(arCredit);
                } else {
                    setAmountCredit(credit);
                    setArrayTravelCredit(arCredit);
                }
            }
        } catch (err) {
            console.log('err=======>', err);
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }

    function calculateTotalDollar() {
        let sale_total_dollar = 0;
        if (formData.sale_amount_dollar && payment_type !== 'miles_only') sale_total_dollar += +formData.sale_amount_dollar;
        if (formData.sale_ticket_cc_fee_dollar && payment_type !== 'miles_only') sale_total_dollar += formData.sale_ticket_cc_fee_dollar;
        if (formData.sale_miles_amount_dollar && payment_type !== 'cash') sale_total_dollar += formData.sale_miles_amount_dollar;
        if (formData.travel_credit_dollar && payment_type !== 'miles_only') sale_total_dollar -= formData.travel_credit_dollar;
        if (calcRowMilesSale) setCalcRowMilesSale(false)
        else if (startExchangeRate.fee) {
            // formEdit.change('sale_total_dollar', sale_total_dollar ? sale_total_dollar.toFixed(2) : null);
            formEdit.change('sale_total_dollar', +sale_total_dollar.toFixed(2));
        }

        let cost_total_dollar = 0;
        if (payment_type !== 'miles_only') cost_total_dollar = formData.cost_ticket_tax_amount_dollar + formData.cost_ticket_cc_fee_amount_dollar;
        if (payment_type !== 'cash') cost_total_dollar += formData.cost_miles_amount_dollar;
        if (formData.cost_travel_credit_dollar && payment_type !== 'miles_only') cost_total_dollar -= formData.cost_travel_credit_dollar;
        // formEdit.change('cost_total_dollar', cost_total_dollar ? +cost_total_dollar.toFixed(2) : null)
        formEdit.change('cost_total_dollar', +cost_total_dollar.toFixed(2))
    }

    //console.log('startExchangeRate======>',startExchangeRate)
    useEffect(() => {
        if (startExchangeRate.fee) {
            calculateTotalDollar();
        }
    }, [formData.payment_type]);// eslint-disable-line

    useEffect(() => {
        if (payment_type !== 'cash') {
            if (formData.cost_miles_price_dollar && formData.quantity)
                formEdit.change('cost_miles_amount_dollar', formData.cost_miles_price_dollar * formData.quantity)
            else formEdit.change('cost_miles_amount_dollar', null)
        }
    }, [formData.cost_miles_price_dollar, formData.quantity]);// eslint-disable-line

    useEffect(() => {
        if (payment_type !== 'cash') {
            if (formData.sale_miles_price_dollar && formData.quantity)
                formEdit.change('sale_miles_amount_dollar', formData.sale_miles_price_dollar * formData.quantity)
            else formEdit.change('sale_miles_amount_dollar', null)
        }
    }, [formData.sale_miles_price_dollar, formData.quantity]);// eslint-disable-line

    useEffect(() => {
        calculateTotalDollar();
    }, [formData.sale_amount_dollar, formData.sale_ticket_cc_fee_dollar, formData.travel_credit_dollar]);// eslint-disable-line

    useEffect(() => {
        if (formData.sale_miles_price_dollar && formData.quantity)
            formEdit.change('sale_miles_amount_dollar', formData.sale_miles_price_dollar * formData.quantity)
        else formEdit.change('sale_miles_amount_dollar', null)

        calculateTotalDollar();
    }, [formData.cost_ticket_tax_amount_dollar, formData.cost_ticket_cc_fee_amount_dollar, formData.cost_miles_amount_dollar, formData.sale_miles_amount_dollar, formData.cost_travel_credit_dollar]);// eslint-disable-line

    const handle_sale_total_dollar = (event) => {
        event.preventDefault();
        //event.stopPropagation();
        let sale_total_dollar = event.target.value;
        let sum = 0;
        if (formData.sale_amount_dollar && payment_type !== 'miles_only') sum += +formData.sale_amount_dollar;
        if (formData.sale_ticket_cc_fee_dollar && payment_type !== 'miles_only') sum += formData.sale_ticket_cc_fee_dollar;
        //if (formData.sale_miles_amount_dollar && payment_type !== 'cash') sum += formData.sale_miles_amount_dollar;
        if (formData.travel_credit_dollar && payment_type !== 'miles_only') sum -= formData.travel_credit_dollar;

        let sale_miles_price_dollar = (sale_total_dollar - sum) / formData.quantity
        // if (sale_miles_price_dollar > 0) {
        formEdit.change('sale_miles_price_dollar', +sale_miles_price_dollar.toFixed(6));
        setCalcRowMilesSale(formData.sale_total_dollar)
        // }
    }

    useEffect(() => {
        if (payment_type !== 'miles_only') {
            let cost_ticket_tax_amount_dollar = null;
            if (formData.cost_ticket_tax_amount && formData.cost_ticket_tax_currency_id && formData.cost_ticket_tax_currency_exchange) {
                let cur = Object.values(currencyData).find((x) => x.id === formData.cost_ticket_tax_currency_id);
                if (cur) {
                    cost_ticket_tax_amount_dollar = cur.is_reverse_exchange_rate
                        ? formData.cost_ticket_tax_amount * formData.cost_ticket_tax_currency_exchange
                        : formData.cost_ticket_tax_amount * (1 / formData.cost_ticket_tax_currency_exchange);

                    if (calcRowTax) setCalcRowTax(false)
                    else formEdit.change('cost_ticket_tax_amount_dollar', cost_ticket_tax_amount_dollar ? +cost_ticket_tax_amount_dollar.toFixed(2) : 0)
                }
            } else {
                formEdit.change('cost_ticket_tax_amount_dollar', null)
            }
            //!!!if (startExchangeRate.amountTax)
            //!!!formEdit.change('sale_amount_dollar', cost_ticket_tax_amount_dollar ? +cost_ticket_tax_amount_dollar.toFixed(2) : null)
        }
    }, [formData.cost_ticket_tax_currency_id, formData.cost_ticket_tax_currency_exchange, formData.cost_ticket_tax_amount,]);// eslint-disable-line

    const handle_cost_ticket_tax_amount = (event) => {
        event.preventDefault();
        //event.stopPropagation();
        let cost_ticket_tax_amount_dollar = event.target.value;
        let cost_ticket_tax_currency_exchange = null;
        if (formData.cost_ticket_tax_amount && formData.cost_ticket_tax_currency_id && cost_ticket_tax_amount_dollar) {
            let cur = Object.values(currencyData).find((x) => x.id === formData.cost_ticket_tax_currency_id);
            if (cur) {
                cost_ticket_tax_currency_exchange = cur.is_reverse_exchange_rate
                    ? cost_ticket_tax_amount_dollar / formData.cost_ticket_tax_amount
                    : formData.cost_ticket_tax_amount / cost_ticket_tax_amount_dollar;

                formEdit.change('cost_ticket_tax_currency_exchange', cost_ticket_tax_currency_exchange ? +cost_ticket_tax_currency_exchange.toFixed(4) : 0)
                setCalcRowTax(cost_ticket_tax_amount_dollar)
            }
        }
    }

    useEffect(() => {
        if (payment_type !== 'miles_only' && startExchangeRate.amountFee) {
            let cost_ticket_cc_fee_amount_dollar = null;
            if (formData.cost_ticket_cc_fee_amount && formData.cost_ticket_cc_fee_currency_id && formData.cost_ticket_cc_fee_currency_exchange) {
                let cur = Object.values(currencyData).find((x) => x.id === formData.cost_ticket_cc_fee_currency_id);
                if (cur) {
                    cost_ticket_cc_fee_amount_dollar = cur.is_reverse_exchange_rate
                        ? formData.cost_ticket_cc_fee_amount * formData.cost_ticket_cc_fee_currency_exchange
                        : formData.cost_ticket_cc_fee_amount * (1 / formData.cost_ticket_cc_fee_currency_exchange);

                    if (calcRowFees) setCalcRowFees(false)
                    else formEdit.change('cost_ticket_cc_fee_amount_dollar', cost_ticket_cc_fee_amount_dollar ? +cost_ticket_cc_fee_amount_dollar.toFixed(2) : 0)
                }
            } else {
                formEdit.change('cost_ticket_cc_fee_amount_dollar', null)
            }
            // if (startExchangeRate.amountFee)
            //!!!formEdit.change('sale_ticket_cc_fee_dollar', cost_ticket_cc_fee_amount_dollar ? +cost_ticket_cc_fee_amount_dollar.toFixed(2) : null)
        }
    }, [formData.cost_ticket_cc_fee_currency_id, formData.cost_ticket_cc_fee_currency_exchange, formData.cost_ticket_cc_fee_amount,]);// eslint-disable-line

    useEffect(() => {
        if (id) {
            if (formData.other_costs && formData.other_costs.length > 0) {
                setIsOtherCosts(formData.other_costs)
            } else setIsOtherCosts(false)
            setTimeout(function () {
                setStartExchangeRate({fee: true, tax: true, amountTax: true, amountFee: true,})
            }, 1000);
        }
    }, [id]);// eslint-disable-line

    useEffect(() => {
        if (payment_type !== 'miles_only' && startExchangeRate.amountFee) {
            let cost_ticket_cc_fee_amount_dollar = null;
            if (formData.other_costs && formData.other_costs.length > 0) {
                //console.log('formData.other_costs=======>', formData.other_costs)
                let other_costs = []
                formData.other_costs.forEach((it, index) => {
                    let cc_fee_amount_dollar = 0;
                    let cur = null
                    if (!it) it = {cc_fee_currency_id: 1}

                    if (isOtherCosts === false) {
                        it.cc_fee_name = 'Fees/ Luggage/ Etc'
                        it.cc_fee_currency_exchange = formData.cost_ticket_cc_fee_currency_exchange
                        it.cc_fee_currency_id = formData.cost_ticket_cc_fee_currency_id
                        it.cc_fee_amount = formData.cost_ticket_cc_fee_amount
                        it.card_fees_id = formData.card_fees_id
                    }

                    if (it.cc_fee_currency_id) {
                        cur = Object.values(currencyData).find((x) => x.id === it.cc_fee_currency_id);
                        if (cur) {
                            if (
                                isOtherCosts[index] === undefined ||
                                (isOtherCosts && isOtherCosts[index].cc_fee_currency_id !== it.cc_fee_currency_id)
                            )
                                it.cc_fee_currency_exchange = cur.exchange_rate
                        }
                    }
                    if (it.cc_fee_amount && it.cc_fee_currency_id && it.cc_fee_currency_exchange) {
                        //let cur = Object.values(currencyData).find((x) => x.id === it.cc_fee_currency_id);
                        if (cur) {
                            cc_fee_amount_dollar = cur.is_reverse_exchange_rate
                                ? it.cc_fee_amount * it.cc_fee_currency_exchange
                                : it.cc_fee_amount * (1 / it.cc_fee_currency_exchange);
                        }
                    }
                    it.cc_fee_amount_dollar = cc_fee_amount_dollar ? cc_fee_amount_dollar.toFixed(2) : 0
                    other_costs.push(it)
                    cost_ticket_cc_fee_amount_dollar = cost_ticket_cc_fee_amount_dollar
                        ? cost_ticket_cc_fee_amount_dollar + cc_fee_amount_dollar
                        : cc_fee_amount_dollar
                })
                setIsOtherCosts(other_costs)

                if (calcOtherCosts) {
                    //console.log(calcOtherCosts, 'other_costs=======>', other_costs)
                    formEdit.change('other_costs', other_costs)
                    setCalcOtherCosts(false)
                    setTimeout(function () {
                        setCalcOtherCosts(true)
                    }, 5);
                }
                formEdit.change('cost_ticket_cc_fee_amount', 0)
                //formData.change('card_fees_id', 0)
                formEdit.change('cost_ticket_cc_fee_amount_dollar', cost_ticket_cc_fee_amount_dollar ? +cost_ticket_cc_fee_amount_dollar.toFixed(2) : 0)
                //!!!formEdit.change('sale_ticket_cc_fee_dollar', cost_ticket_cc_fee_amount_dollar ? +cost_ticket_cc_fee_amount_dollar.toFixed(2) : null)
            } else {
                formEdit.change('cost_ticket_cc_fee_amount_dollar', 0)
                //!!!formEdit.change('sale_ticket_cc_fee_dollar', 0)
                if (isOtherCosts && isOtherCosts.length > 0) {
                    formEdit.change('cost_ticket_cc_fee_currency_exchange', isOtherCosts[0].cc_fee_currency_exchange)
                    formEdit.change('cost_ticket_cc_fee_currency_id', isOtherCosts[0].cc_fee_currency_id)
                    formEdit.change('cost_ticket_cc_fee_amount', isOtherCosts[0].cc_fee_amount)
                    formEdit.change('card_fees_id', isOtherCosts[0].card_fees_id)
                }
                setIsOtherCosts(false)
            }
        }
    }, [formData.other_costs]);// eslint-disable-line

    const handle_cost_ticket_cc_fee_amount_dollar = (event) => {
        event.preventDefault();
        //event.stopPropagation();
        if (!formData.other_costs || formData.other_costs.length < 1) {
            let cost_ticket_cc_fee_amount_dollar = event.target.value;
            let cost_ticket_cc_fee_currency_exchange = null;
            if (formData.cost_ticket_cc_fee_amount && formData.cost_ticket_cc_fee_currency_id && cost_ticket_cc_fee_amount_dollar) {
                let cur = Object.values(currencyData).find((x) => x.id === formData.cost_ticket_cc_fee_currency_id);
                if (cur) {
                    cost_ticket_cc_fee_currency_exchange = cur.is_reverse_exchange_rate
                        ? cost_ticket_cc_fee_amount_dollar / formData.cost_ticket_cc_fee_amount
                        : formData.cost_ticket_cc_fee_amount / cost_ticket_cc_fee_amount_dollar;

                    formEdit.change('cost_ticket_cc_fee_currency_exchange', cost_ticket_cc_fee_currency_exchange ? +cost_ticket_cc_fee_currency_exchange.toFixed(4) : 0)
                    setCalcRowFees(cost_ticket_cc_fee_amount_dollar)
                }
            }
        }
    }

    useEffect(() => {
        if (formData.cost_ticket_tax_currency_id) {
            let cur = Object.values(currencyData).find((x) => x.id === formData.cost_ticket_tax_currency_id);
            let exchange_rate = cur ? cur.exchange_rate : null;
            if (exchange_rate && startExchangeRate.tax) {
                formEdit.change("cost_ticket_tax_currency_exchange", exchange_rate);
                //let elem = document.getElementById('cost_ticket_tax_currency_exchange')
                //if (elem) elem.focus()
                //elem.blur()
            }
        }
    }, [formData.cost_ticket_tax_currency_id]);// eslint-disable-line

    useEffect(() => {
        if (formData.cost_ticket_cc_fee_currency_id) {
            let cur = Object.values(currencyData).find((x) => x.id === formData.cost_ticket_cc_fee_currency_id);
            let exchange_rate = cur ? cur.exchange_rate : null;
            if (exchange_rate && startExchangeRate.fee) {
                formEdit.change('cost_ticket_cc_fee_currency_exchange', exchange_rate);
            }
        }
    }, [formData.cost_ticket_cc_fee_currency_id]);// eslint-disable-line

    useEffect(() => {
        if (startExchangeRate.fee) {
            formEdit.change('travel_credit_dollar', null);
            formEdit.change('travel_credit_id', null);
        }
        if (formData.customer_id) {
            //console.log('formData.customer_id===========>', formData.customer_id)
            getData(formData.customer_id, 'sale');
        } else {
            setArrayTravelCredit([])
        }
        getCostTravelCredit(id)
    }, [formData.customer_id]);// eslint-disable-line

    useEffect(() => {
        if (startExchangeRate.fee) {
            formEdit.change('cost_travel_credit_dollar', null);
            formEdit.change('cost_travel_credit_id', null);
        }
        if (formData.customer_id && formData.issued_airline_id) {
            //console.log('formData.customer_id===========>', formData.customer_id)
            getData(formData.customer_id, 'cost');
        } else {
            setArrayTravelCredit([])
        }
    }, [formData.customer_id, formData.issued_airline_id]);// eslint-disable-line

    useEffect(() => {
        let credit = 0
        if (formData.travel_credit_id) {
            let res = arrayTravelCredit.find((x) => x.id === formData.travel_credit_id)
            if (res) credit = res.amount;
        }
        setAmountCredit(credit);
    }, [formData.travel_credit_id, arrayTravelCredit]);// eslint-disable-line
    /*useEffect(() => {
        if (startExchangeRate.fee) {
            formEdit.change('travel_credit_dollar', null);
        }
    }, [formData.travel_credit_id]);// eslint-disable-line*/

    useEffect(() => {
        let credit = 0
        if (formData.cost_travel_credit_id) {
            let res = arrayTravelCreditCost.find((x) => x.id === formData.cost_travel_credit_id)
            if (res) credit = res.amount;
        }
        setAmountCreditCost(credit);
    }, [formData.cost_travel_credit_id, arrayTravelCreditCost]);// eslint-disable-line

    /*useEffect(() => {
        if (startExchangeRate.fee) {
            formEdit.change('cost_travel_credit_dollar', null);
        }
    }, [formData.cost_travel_credit_id]);// eslint-disable-line*/

    return (
        <>
            {/*<Grid item xs={12}/>*/}
            <Box style={{margin: 8, padding: 16, border: '2px solid cornflowerblue', width: '100%', borderRadius: 4}}>
                {/*<Grid item xs={1} className={classes.booking_title_no_borger} style={{display: "flex"}}>
                    <Typography variant="h6" gutterBottom>
                        Cost
                    </Typography>
                </Grid>
                <Grid item xs={11} className={classes.booking_title} style={{display: "flex"}}>
                    {payment_type !== 'miles_only' &&
                        <FormControlLabel
                            style={{marginTop: -5}}
                            control={
                                <Checkbox
                                    checked={perPassengerCost}
                                    onChange={handleChangeCost}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={`Per passenger (${countPassengers} persons)`}
                        />
                    }
                </Grid>*/}
                <Grid item xs={12} style={{display: "flex"}}>
                    <Grid container>
                        <Grid item xs={1}>
                            <Typography variant="h6" gutterBottom className={classes.variant6Bold}>
                                Cost
                            </Typography>
                        </Grid>
                        <Grid item xs={11} style={{display: "grid"}}>
                            {payment_type !== 'miles_only' &&
                                <FormControlLabel
                                    style={{marginTop: -5}}
                                    control={
                                        <Checkbox
                                            checked={perPassengerCost}
                                            onChange={handleChangeCost}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={`Per passenger (${countPassengers} persons)`}
                                />
                            }
                            {payment_type !== 'miles_only' && formData.cost_per_passenger_amount
                                ?
                                <div style={{fontSize: 14, color: 'cornflowerblue', marginTop: -8}}>Other costs and fees
                                    are absolut, not divider per passenger</div>
                                : null
                            }
                        </Grid>
                    </Grid>
                </Grid>


                {/*{payment_type === 'miles' &&
                    <Grid item xs={12}>
                        <Grid container alignItems={"center"} spacing={2}>
                            <Grid item xs={6}>
                                <ReferenceInput source="card_tax_id" reference="creditCard" label={'Credit Card (Tax)'}
                                                validate={validateReq}>
                                    <AutocompleteInput
                                        resettable
                                        optionText={record => (record && `${record.description} *${record.last_cc}`)}
                                        fullWidth
                                    />
                                </ReferenceInput>
                            </Grid>
                        </Grid>
                    </Grid>
                }*/}
                {payment_type !== 'cash' &&
                    <Grid item xs={12}>
                        <Grid container alignItems={"center"} spacing={2}>
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field}>
                                    <div>
                                        <span style={{fontSize: 12}}>{'Cost (mi)'}</span>
                                    </div>
                                    <div>
                                        <GetFieldById id={program_account_id} resource={'supplier'}
                                                      field={'cost'}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field}>
                                    <div>
                                        <span style={{fontSize: 12}}>{'Supplier'}</span>
                                    </div>
                                    <div>
                                        <GetFieldById id={program_account_id} resource={'supplier'}
                                                      field={'supplier_title'}/>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field}>
                                    <div>
                                        <span style={{fontSize: 12}}>{'Program '}</span>
                                    </div>
                                    <div>
                                        <GetFieldById id={program_account_id} resource={'supplier'}
                                                      field={'program_title'}/>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {payment_type !== 'cash' &&
                    <Grid item xs={12} style={{marginBottom: payment_type !== 'miles_only' && -32}}>
                        {`Miles`}
                        <Grid container alignItems={"center"} spacing={2}>
                            <Grid item xs={3}>
                                <NumberInputFloatComp source="cost_miles_price_dollar" label={'Price'}
                                                      validate={validateReq}
                                                      fullWidth/>
                            </Grid>
                            <Grid item xs={3}>
                                <NumberInput step={0} source="quantity" label={'Quantity'} validate={validateReq}
                                             fullWidth/>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field_calc}>
                                    <div>
                                        <span style={{fontSize: 12}}>{'Amount ($)'}</span>
                                    </div>
                                    <div>
                                        <span>{formData.cost_miles_amount_dollar && formatSpacerNumber(formData.cost_miles_amount_dollar, ',', 2)}</span>
                                    </div>
                                </div>
                            </Grid>
                            {/*{formData.cost_miles_amount_dollar &&
                                <Grid item xs={3}>
                                    <ReferenceInput source="card_miles_id" reference="creditCard"
                                                    label={'Credit Card (Miles)'}
                                                    validate={validateReq}>
                                        <AutocompleteInput
                                            resettable
                                            optionText={record => (record && `${record.description} *${record.last_cc}`)}
                                            fullWidth
                                        />
                                    </ReferenceInput>
                                </Grid>
                            }*/}
                        </Grid>
                    </Grid>
                }
                {payment_type !== 'miles_only' &&
                    <>
                        <Grid item xs={12} style={{marginBottom: -32}}>
                            {nameCostTax}
                            <Grid container alignItems={"center"} spacing={2}>
                                {/*<Grid item xs={1}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Tax, fee
                                    </Typography>
                                </Grid>*/}
                                {perPassengerCost
                                    ?
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp source="cost_per_passenger_amount"
                                                              label={'per Passenger Amount'}
                                                              validate={validateReq}
                                                              fullWidth/>
                                        {/*<TextInput label="cost_ticket_tax_amount" source="cost_ticket_tax_amount" type="number" parse={convertStringToNumber} />,*/}
                                    </Grid>
                                    :
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp source="cost_ticket_tax_amount" label={'Amount'}
                                                              validate={validateReq}
                                                              fullWidth/>
                                        {/*<TextInput label="cost_ticket_tax_amount" source="cost_ticket_tax_amount" type="number" parse={convertStringToNumber} />,*/}
                                    </Grid>
                                }
                                <Grid item xs={2}>
                                    <ReferenceInput source="cost_ticket_tax_currency_id" reference="currency"
                                                    validate={validateReq}
                                                    label={'Currency'}>
                                        <AutocompleteInput
                                            optionText={record => (record && (record.is_reverse_exchange_rate
                                                    ? `${record.code} / ${'USD'}`
                                                    : `${'USD'} / ${record.code}`)
                                            )}
                                            // optionText={record => (record && `(${record.code}) ${record.title}`)}
                                            fullWidth/>
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={1}>
                                    <NumberInputFloatComp source="cost_ticket_tax_currency_exchange"
                                                          validate={validateReq}
                                                          label={'Ccy exch'}
                                                          fullWidth/>
                                </Grid>
                                <Grid item xs={3}>
                                    <NumberInputFloatComp source="cost_ticket_tax_amount_dollar"
                                        // variant={"outlined"}
                                                          size="small"
                                        // color="primary"
                                                          label={'Amount ($)'}
                                                          fullWidth
                                                          onChange={handle_cost_ticket_tax_amount}
                                    />
                                    {/*<div className={classes.cost_show_field_calc}>
                                        <div>
                                                    <span
                                                        style={{fontSize: 12}}>{'Amount ($)'}</span>
                                        </div>
                                        <div>
                                            <span>{formData.cost_ticket_tax_amount_dollar && formatSpacerNumber(formData.cost_ticket_tax_amount_dollar, ',', 2)}</span>
                                        </div>
                                    </div>*/}
                                    {/*<NumberInputFloatComp source="cost_ticket_tax_amount_dollar" fullWidth/>*/}
                                </Grid>
                                {payment_type !== 'cash' &&
                                    formData.cost_ticket_tax_amount_dollar &&
                                    <Grid item xs={3}>
                                        <ReferenceInput
                                            sort={{field: 'description', order: 'ASC'}}
                                            source="card_tax_id" reference="creditCard"
                                            label={'Credit Card (Tax)'}
                                            validate={validateReq}
                                        >
                                            <AutocompleteInput
                                                resettable
                                                optionText={record => (record && `${record.description} *${record.last_cc}`)}
                                                fullWidth
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>

                        {formData.other_costs && formData.other_costs.length > 0
                            ? null
                            :
                            <Grid item xs={12} style={{marginBottom: -32}}>
                                Fees/ Luggage/ Etc
                                <Grid container alignItems={"center"} spacing={2}>
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp source="cost_ticket_cc_fee_amount"
                                                              label={'Amount'}
                                                              fullWidth/>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ReferenceInput source="cost_ticket_cc_fee_currency_id"
                                                        label={'Currency'}
                                                        reference="currency">
                                            <AutocompleteInput
                                                optionText={record => (record && (record.is_reverse_exchange_rate
                                                        ? `${record.code} / ${'USD'}`
                                                        : `${'USD'} / ${record.code}`)
                                                )}
                                                // optionText={record => (record && `(${record.code}) ${record.title}`)}
                                                fullWidth/>
                                        </ReferenceInput>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <NumberInputFloatComp source="cost_ticket_cc_fee_currency_exchange"
                                                              label={'Ccy exch'}
                                                              fullWidth/>
                                    </Grid>


                                    <Grid item xs={3}>
                                        <NumberInputFloatComp
                                            source="cost_ticket_cc_fee_amount_dollar"
                                            // variant={"outlined"}
                                            size="small"
                                            // color="primary"
                                            label={'Amount ($)'}
                                            fullWidth
                                            onChange={handle_cost_ticket_cc_fee_amount_dollar}
                                        />
                                    </Grid>

                                    {formData.cost_ticket_cc_fee_amount_dollar
                                        ?
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                sort={{field: 'description', order: 'ASC'}}
                                                source="card_fees_id" reference="creditCard"
                                                label={'Credit Card (Fees)'}
                                                validate={validateReq}
                                            >
                                                <AutocompleteInput
                                                    resettable
                                                    optionText={record => (record && `${record.description} *${record.last_cc}`)}
                                                    fullWidth
                                                />
                                            </ReferenceInput>
                                        </Grid>
                                        : null
                                    }
                                </Grid>
                            </Grid>
                        }


                        <Box p={'1em'} mt={'1em'} mb={'1em'}
                             style={{padding: "0 8px", border: '1px solid #ddd', borderRadius: 8}}>
                            {/*<span>Other Costs</span>*/}
                            <Grid item xs={12}>
                                <Grid container alignItems={"center"} spacing={2}>
                                    <Grid item xs={12}>
                                        <ArrayInput source="other_costs" label={'Other Costs'} fullWidth={true}>
                                            <SimpleFormIterator
                                                // addButton={<Button style={{color: 'blue'}}>Add Costs</Button>}
                                                // className={classes.SimpleFormIterator}
                                                className={"SimpleFormIterator"}
                                                disableReordering={true}
                                                inline={true}
                                                fullWidth={true}
                                                //disableAdd={ticket_type === 'rtn' && legs && legs.length > 0}
                                                //disableRemove={ticket_type === 'multi_city' && legs && legs.length < 3}
                                            >

                                                <TextInput
                                                    className={classes.other_costs_name}
                                                    source="cc_fee_name" label={'Name'}
                                                    validate={validateReq}
                                                    fullWidth
                                                />
                                                <NumberInputFloatComp
                                                    className={classes.other_costs_amount}
                                                    source="cc_fee_amount" label={'Amount'}
                                                    validate={validateReq}
                                                    fullWidth
                                                />
                                                <ReferenceInput
                                                    className={classes.other_costs_currency}
                                                    source="cc_fee_currency_id" label={'Currency'}
                                                    reference="currency"
                                                    validate={validateReq}
                                                    fullWidth
                                                >
                                                    <AutocompleteInput
                                                        optionText={record => (record && (record.is_reverse_exchange_rate
                                                                ? `${record.code} / ${'USD'}`
                                                                : `${'USD'} / ${record.code}`)
                                                        )}

                                                        // optionText={record => (record && `(${record.code}) ${record.title}`)}
                                                    />
                                                </ReferenceInput>
                                                <NumberInputFloatComp
                                                    className={classes.other_costs_currency}
                                                    source="cc_fee_currency_exchange"
                                                    label={'Ccy exch'}
                                                    fullWidth
                                                    validate={validateReq}
                                                />
                                                <NumberInputFloatComp
                                                    className={classes.other_costs_amount}
                                                    source="cc_fee_amount_dollar"
                                                    size="small"
                                                    label={'Amount ($)'}
                                                    onChange={handle_cost_ticket_cc_fee_amount_dollar}
                                                    fullWidth
                                                />
                                                <ReferenceInput
                                                    sort={{field: 'description', order: 'ASC'}}
                                                    className={classes.other_costs_card_id}
                                                    source="card_fees_id" reference="creditCard"
                                                    label={'Credit Card (Fees)'}
                                                    validate={validateReq}
                                                    fullWidth
                                                >
                                                    <AutocompleteInput
                                                        resettable
                                                        optionText={record => (record && `${record.description} *${record.last_cc}`)}
                                                    />
                                                </ReferenceInput>
                                            </SimpleFormIterator>
                                        </ArrayInput>
                                    </Grid>
                                    {/*<Grid item xs={1}>
                                    </Grid>*/}
                                </Grid>
                            </Grid>
                        </Box>

                        {formData.other_costs && formData.other_costs.length > 0
                            ?
                            <Grid item xs={12} style={{marginBottom: -32}}>
                                <Grid container alignItems={"center"} spacing={2}>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp
                                            source="cost_ticket_cc_fee_amount_dollar"
                                            // variant={"outlined"}
                                            size="small"
                                            // color="primary"
                                            label={'Amount Other Costs($)'}
                                            fullWidth
                                            onChange={handle_cost_ticket_cc_fee_amount_dollar}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>
                            </Grid>

                            : null
                        }

                        {arrayTravelCreditCost && arrayTravelCreditCost.length > 0
                            ?
                            <>
                                {(!formData.booking_cost_travel_credit || formData.booking_cost_travel_credit.length === 0) &&
                                    <Grid item xs={12}>
                                        <Grid container alignItems={"center"} spacing={2}>
                                            {/*<Grid item xs={2}/>*/}
                                            <Grid item xs={6}>
                                                <SelectInput source="cost_travel_credit_id"
                                                             choices={arrayTravelCreditCost}
                                                    //validate={validateReq}
                                                             label={'Bookman Travel Credit'}
                                                             fullWidth
                                                             resettable
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <NumberInputFloatComp
                                                    source="cost_travel_credit_dollar"
                                                    /* helperText={formData.cost_travel_credit_dollar > amountCreditCost
                                                         ? 'Credit limit exceeded' : ' '
                                                     }*/
                                                    label={<>Use Travel Credit (<span
                                                        style={{color: formData.cost_travel_credit_dollar > amountCreditCost && "red"}}>${amountCreditCost}</span>)</>}
                                                    // label={`Use Bookman Travel Credit ($ ${amountCreditCost})`}
                                                    fullWidth/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item xs={12} style={{marginTop: -30}}>
                                    <Grid container alignItems={"center"} spacing={2}>
                                        {/*<Grid item xs={2}/>*/}
                                        <Grid item xs={10}>
                                            <ArrayInput source="booking_cost_travel_credit"
                                                        label={'Other Bookman Travel Crtedits'} fullWidth={true}>
                                                <SimpleFormIterator
                                                    // addButton={<Button style={{color: 'blue'}}>Add Costs</Button>}
                                                    // className={classes.SimpleFormIterator}
                                                    // removeButton={<Button style={{color: 'blue'}}>rem</Button>}
                                                    className={"SimpleFormIterator"}
                                                    disableReordering={true}
                                                    inline={true}
                                                    fullWidth={true}
                                                    //disableAdd={ticket_type === 'rtn' && legs && legs.length > 0}
                                                    //disableRemove={ticket_type === 'multi_city' && legs && legs.length < 3}
                                                >

                                                    <SelectInput
                                                        source="cost_travel_credit_id" choices={arrayTravelCreditCost}
                                                        validate={validateReq}
                                                        //disable={true}
                                                        label={'Travel Credit'}
                                                        fullWidth
                                                        resettable
                                                        style={{width: 600}}
                                                    />
                                                    <NumberInputFloatComp
                                                        //className={classes.other_costs_amount}
                                                        style={{width: 160}}
                                                        source="cost_travel_credit_dollar" label={'Use Travel Credit'}
                                                        validate={validateReq}
                                                        fullWidth
                                                    />
                                                </SimpleFormIterator>
                                            </ArrayInput>
                                        </Grid>
                                        <Grid item xs={2}/>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} style={{marginBottom: 24}}>
                                <Grid container alignItems={"center"} spacing={2}>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Alert severity='info'>No Bookman Travel Credit is available
                                            {formData.cost_travel_credit_dollar ? `($ ${formatSpacerNumber(formData.cost_travel_credit_dollar)})` : ''}</Alert>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </>
                }

                {formData.booking_cost_travel_credit && formData.booking_cost_travel_credit.length > 0 &&
                    <Grid item xs={12} style={{marginBottom: 24}}>
                        <Grid container alignItems={"center"} spacing={2}>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field_calc}>
                                    <div>
                                        <span style={{fontSize: 12}}>{'Use Travel Credit ($)'}</span>
                                    </div>
                                    <div>
                              <span style={{fontWeight: "bold"}}>
                                  {formData.cost_travel_credit_dollar ? formatSpacerNumber(formData.cost_travel_credit_dollar, ',', 2) : 0}
                              </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={3}/>
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Grid container alignItems={"center"} spacing={2}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.cost_show_field_calc}>
                                <div>
                                    <span style={{fontSize: 12}}>{'Total ($)'}</span>
                                </div>
                                <div>
                              <span style={{fontWeight: "bold"}}>
                                  {formData.cost_total_dollar && formatSpacerNumber(formData.cost_total_dollar, ',', 2)}
                              </span>
                                </div>
                            </div>
                        </Grid>
                        {canAccess &&
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field_calc}>
                                    <div>
                                        <span style={{fontSize: 12}}>{'Profit ($)'}</span>
                                    </div>
                                    <div>
                              <span style={{fontWeight: "bold", color: profit < 0 && 'red'}}>
                                  {profit}
                              </span>
                                    </div>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>

            <Box style={{margin: 8, padding: 16, border: '2px solid cornflowerblue', width: '100%', borderRadius: 4}}>
                <Grid item xs={12} style={{display: "flex"}}>
                    <Grid container>
                        <Grid item xs={1}>
                            <Typography variant="h6" className={classes.variant6Bold} gutterBottom>
                                Sale
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            {payment_type !== 'miles_only' &&
                                <FormControlLabel
                                    style={{marginTop: -5}}
                                    control={
                                        <Checkbox
                                            checked={perPassengerSale}
                                            onChange={handleChangeSale}
                                            name="checkedB2"
                                            color="primary"
                                        />
                                    }
                                    label={`Per passenger (${countPassengers} persons)`}
                                />
                            }
                            {payment_type !== 'miles_only' && formData.sale_per_passenger_amount
                                ?
                                <div style={{fontSize: 14, color: 'cornflowerblue', marginTop: -8}}>Fees
                                    are absolut, not divider per passenger</div>
                                : null
                            }

                        </Grid>
                    </Grid>
                </Grid>

                {payment_type !== 'cash' &&
                    <Grid item xs={12} style={{marginBottom: -32}}>
                        Miles
                        <Grid container alignItems={"center"} spacing={2}>
                            {/*<Grid item xs={1}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Miles
                                    </Typography>
                                </Grid>*/}
                            <Grid item xs={3}>
                                <NumberInputFloatComp source="sale_miles_price_dollar" label={'Price'}
                                                      validate={validateReq}
                                                      fullWidth/>
                            </Grid>
                            <Grid item xs={3}>
                                <div className={classes.cost_show_field_calc}>
                                    <div>
                                        <span
                                            style={{fontSize: 12}}>{`Amount ($) for ${formData.quantity} miles`}</span>
                                    </div>
                                    <div>
                                        <span>{formData.sale_miles_amount_dollar && formatSpacerNumber(formData.sale_miles_amount_dollar, ',', 2)}</span>
                                    </div>
                                </div>
                                {/*<NumberInputFloatComp source="sale_miles_amount_dollar"
                                                              fullWidth/>*/}
                            </Grid>
                            {payment_type === 'miles_only' &&
                                <Grid item xs={3}>
                                    {/*<div className={classes.cost_show_field_calc}>
                                        <div>
                                            <span style={{fontSize: 12}}>{'Total ($)'}</span>
                                        </div>
                                        <div>
                                         <span style={{fontWeight: "bold"}}>
                                             {formData.sale_total_dollar && formatSpacerNumber(formData.sale_total_dollar, ',', 2)}
                                         </span>
                                        </div>
                                    </div>*/}
                                    <NumberInputFloatComp source="sale_total_dollar" label={'Total ($)'}
                                                          onChange={handle_sale_total_dollar}
                                                          fullWidth/>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }
                {payment_type !== 'miles_only' &&
                    <>
                        <Grid item xs={12} style={{marginBottom: -32}}>
                            {nameCostTax}, Fees/ Luggage/ Etc
                            <Grid container alignItems={"center"} spacing={2}>
                                {perPassengerSale
                                    ?
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp source="sale_per_passenger_amount" validate={validateReq}
                                                              label={`${nameCostTax} per Passenger Amount ($)`}
                                                              fullWidth/>
                                    </Grid>
                                    :
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp source="sale_amount_dollar" validate={validateReq}
                                                              label={`${nameCostTax} Amount ($)`}
                                                              fullWidth/>
                                    </Grid>
                                }
                                <Grid item xs={3}>
                                    <NumberInputFloatComp source="sale_ticket_cc_fee_dollar"
                                                          label={'Fees/ Luggage/ Etc Amount ($)'}
                                                          fullWidth/>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.cost_show_field_calc}>
                                        <div>
                                            <span style={{fontSize: 12}}>{'Amount ($)'}</span>
                                        </div>
                                        <div>
                                    <span style={{fontWeight: "bold"}}>
                                        {(+formData.sale_amount_dollar + formData.sale_ticket_cc_fee_dollar)
                                            ? formatSpacerNumber(+formData.sale_amount_dollar + formData.sale_ticket_cc_fee_dollar, ',', 2)
                                            : null
                                        }
                                    </span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>

                        {arrayTravelCredit && arrayTravelCredit.length > 0
                            ?
                            <Grid item xs={12} style={{marginBottom: -32}}>
                                <Grid container alignItems={"center"} spacing={2}>
                                    {/*<Grid item xs={3}>
                                    </Grid>*/}
                                    <Grid item xs={6}>
                                        <SelectInput source="travel_credit_id" choices={arrayTravelCredit}
                                            //validate={validateReq}
                                                     disable={true}
                                                     label={'Travel Credit'}
                                                     fullWidth
                                                     resettable
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <NumberInputFloatComp
                                            source="travel_credit_dollar"
                                            label={<>Use Travel Credit (<span
                                                style={{color: formData.travel_credit_dollar > amountCredit && "red"}}>${amountCredit}</span>)</>}
                                            //label={`Use Travel Credit ($ ${amountCredit})`}
                                            fullWidth/>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Grid item xs={12} style={{marginBottom: -8}}>
                                <Grid container alignItems={"center"} spacing={2}>
                                    <Grid item xs={3}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Alert severity='info'>No Travel Credit is available
                                            {formData.travel_credit_dollar ? `($ ${formatSpacerNumber(formData.travel_credit_dollar)})` : ''}</Alert>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Grid container alignItems={"center"} spacing={2}>
                                <Grid item xs={3}/>
                                <Grid item xs={3}/>
                                <Grid item xs={3}>
                                    {payment_type === 'cash'
                                        ?
                                        <div className={classes.cost_show_field} style={{marginTop: 8}}>
                                            <div>
                                                <span style={{fontSize: 12}}>{'Total ($)'}</span>
                                            </div>
                                            <div>
                                    <span style={{fontWeight: "bold"}}>
                                        {formData.sale_total_dollar && formatSpacerNumber(formData.sale_total_dollar, ',', 2)}
                                    </span>
                                            </div>
                                        </div>
                                        :
                                        <NumberInputFloatComp source="sale_total_dollar" label={'Total ($)'}
                                                              onChange={handle_sale_total_dollar}
                                                              fullWidth
                                            //class={'colorMyInput'}
                                            /*sx={{ color: 'red' }}*/
                                            /*sx={{
                                                backgroundColor: '#ffd60a',
                                                border: '3px solid #001d3d',
                                            }}*/
                                            /*classes={{
                                                root: classes.colorInput,
                                                //label: classes.label,
                                            }}*/
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </Box>
        </>
    )
};
export default SaleForBooking;
