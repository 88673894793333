import * as React from "react";
import "react-tabulator/lib/css/tabulator.min.css";
import MasterReport from "../masterReport";

const report_fields = ['customer', 'passengers', 'airline', 'owner', 'travel_credit', 'transaction',
    'expire_date', 'booking_date', 'last_update']
const report_group_by = ['customer', 'travel_credit', 'owner', 'airline',]

const variantsConfig = {
    // 'Travel credits': [[true, false, false, false, false, false], [false, false, false, false]],
    'Default': [[false, false, false, false, true, false, false, false, false], [false, false, false, false]],
    'Travel credits': [[true, true, true, true, true, true, true, true, true], [false, false, false, false]],
    'Details by customer': [[true, true, false, false, false, false], [true, false, false, false]],
}

const table_columns = [
    // eslint-disable-next-line no-undef
    {title: "Customer", field: "customer", width: 250, responsive: 2}, //hide this column first
    {title: "Passengers", field: "passengers", width: 250, responsive: 2}, //hide this column first
    {title: "Owner", field: "owner", width: 250, responsive: 2}, //hide this column first
    {title: "Airline", field: "airline", width: 150, responsive: 2}, //hide this column first
    {
        title: "Travel credit", field: "travel_credit", width: 350, responsive: 2,
        formatter: "link",
        formatterParams: {
            //label: "travel_credit",
            labelField: "travel_credit",
            urlField: "booking_id_tc",
            urlPrefix: "/#/booking/",
            //target: "_blank",
        }
    }, //hide this column first
    {
        title: "Transaction", field: "transaction", width: 150, responsive: 2,
        /*formatter: "link",
        formatterParams: {
            labelField: "transaction",
            urlField: "booking_id_tr",
            urlPrefix: "/#/booking/",
            target: "_blank",
        }*/
    }, //hide this column first
    {title: "Expire date", field: "expire_date", hozAlign: "center", width: 150, formatter: "date",},
    {title: "Booking date", field: "booking_date", hozAlign: "center", width: 150, formatter: "date",},
    {title: "Last update", field: "last_update", hozAlign: "center", width: 150, formatter: "date",},
    {
        title: "Credit amount",
        field: "amount_dollar",
        hozAlign: "right",
        width: 190,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {
        title: "Amount used",
        field: "amount_used",
        hozAlign: "right",
        width: 190,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    {
        title: "Amount remaining",
        field: "amount",
        hozAlign: "right",
        width: 190,
        formatter: "money",
        topCalc: "sum",
        topCalcFormatter: "money"
    },
    // {title:"Amount used", field:"amount_used", topCalc:"sum", topCalcParams:{
    //     precision:1,
    // }}
]

const filters = {
    // booking: ['Query', 'booking/admin/booking', (option) => `Booking ${option.id}`],
    airline: ['Query', false, 'booking/admin/airline', (option) => option.title],
    customer: ['Query', false, 'booking/admin/customer', (option) => option.name],
    owner: ['Query', false, 'booking/admin/customer', (option) => option.name],
    travel_credit: ['Query', false, 'booking/admin/travel_credit', (option) => `TC ${option.id}`],
    expire_date: ['DateTime', false,],
    date_from: ['DateTime', false,],
    date_to: ['DateTime', false,],
}


const TravelCredit = () => {

    return <MasterReport
        report_fields={report_fields}
        report_group_by={report_group_by}
        variantsConfig={variantsConfig}
        table_columns={table_columns}
        report_url='api/reports/travel_credits'
        filters={filters}
    />
};

export default TravelCredit
