import * as React from 'react';
import {
    //TextInput,
    DateInput,
    TabbedForm,
    FormTab,
    AutocompleteInput, ReferenceInput, BooleanInput, FormDataConsumer
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, TextFieldButton} from "../_common/CompReact";
//import useMyStyles from "../utils/useMyStyles";
import HistoryListForFilter from "../_common/HistoryListForFilter";
import InvoiceBooking from "./InvoiceBookinf";
import {validateReq} from "../utils/validateTable";
// import {useEffect} from "react";
// import {useNotify} from "ra-core";

import Alert from '@material-ui/lab/Alert';
//import {useForm} from "react-final-form";
//import {useEffect} from "react";
//import {config} from "../utils/config";
//import get from "lodash/get";
/*
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
*/
const InvEditCreate = props => {

    //const formEdit = useForm();
    const {record} = props;
    const {id, customer_id, total_amount, is_load = false, /*card_id = null*/} = record;

    /*useEffect(() => {//Карта по умолчанию
        if (card_id === null) {
            formEdit.change('card_id', parseInt(config.bookmanCardID))
        }
    }, []);// eslint-disable-line*/


    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"center"}>
                {/*<Box flex={id ? 1 : 2} style={{display: "flex"}} mr={'2em'}>
                    <ReferenceInput source="project_id" reference="projects" label={'Progect'}
                                    validate={validateReq}>
                        <AutocompleteInput resettable optionText="name" fullWidth/>
                    </ReferenceInput>
                </Box>*/}
                <Box flex={1} mr={'2em'}>
                    {id && <BooleanInput source="is_paid" label={'Is paid'} defaultValue={false}/>}
                </Box>
                <Box flex={1}>
                    {id && <BooleanInput source="is_load" label={'Exported to Xero'} defaultValue={false}/>}
                </Box>
                {/*<Box flex={1}>
                            </Box>*/}
            </Box>

            <Box display="flex" alignItems={"center"}>
                <Box flex={id ? 2 : 2} style={{display: "flex"}} mr={'2em'}>
                    {/*<Typography variant="h5" gutterBottom>ID: </Typography>*/}
                    {/*<NumberField source="id" variant="h5"/>*/}
                    <ReferenceInput source="customer_id" reference="customer" label={'Customer'}
                                    validate={validateReq}>
                        <AutocompleteInput resettable optionText="name" fullWidth/>
                    </ReferenceInput>
                </Box>
                {/*<Box flex={1} mr={'2em'}>
                    {id && <TextInput source={'number'} label={'Number'} validate={validateReq} fullWidth/>}
                </Box>*/}
                <Box flex={1} mr={'2em'}>
                    {id && <ReferenceInput source="card_id" reference="creditCard"
                                           label={'Credit Card'}
                        // validate={validateReq}
                    >
                        <AutocompleteInput
                            resettable
                            optionText={record => (record && `${record.description} *${record.last_cc}`)}
                            fullWidth
                        />
                    </ReferenceInput>
                    }
                </Box>
                <Box flex={1}>
                    {id && <DateInput source="date" label={'Date'}/>}
                </Box>
                {/*<Box flex={1}>
                            </Box>*/}
            </Box>
            {id && customer_id
                ?
                <>
                    <Typography variant="h6">Invoice Booking</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                const {customer_id: customerFormId} = formData;
                                return (
                                    <InvoiceBooking
                                        {...props}
                                        customerFormId={customerFormId}
                                        customer_id={customer_id}
                                        invoice_id={id}
                                        formData={formData}
                                        total_amount={total_amount}
                                        record={record}
                                        isSave={!is_load}
                                    />
                                )
                            }}
                        </FormDataConsumer>
                    </Box>
                </>
                :
                <Typography variant="subtitle1" color={"error"}>For further work fill in the CUSTOMER field
                    and save</Typography>
            }
        </Box>
    )
}
export const InvoiceEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    // const classes = useMyStyles();
    // const notify = useNotify();
    const {record} = props;
    const {id, is_load = false} = record;
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    /*
        const [countBooking, setCountBooking] = React.useState(0);
        const [arBooking, setArBooking] = React.useState([]);
        const getBooking = async (customer_id) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `booking/admin/booking`;
            let filter = `?customer_id=${customer_id}&is_invoiced=false&is_paid=false`;
            try {
                const response = await httpClient(`${url}${report_url}${filter}`);
                console.log(response);
                if (response.status >= 200 &&  response.status <= 300) {
                    setCountBooking(response.json.count);
                    setArBooking(response.json.results);
                    } else {

                    }
            } catch (err) {
                console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
            }
        }
    */
    /*useEffect(() => {
        console.log(id, 'Error===========>', is_load)
        if (id && is_load) {
            notify('No editing allowed!!! An export to XERO has already been made', {type: 'error', autoHideDuration: 5000,});
        }
    }, [id]);// eslint-disable-line*/

    return (
        <>
            {id &&
                <Box>
                    <Box display="flex" alignItems={"center"} p="1em">
                        <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                            <Box mr="0.5em">
                                <Typography variant="subtitle2" gutterBottom>Full Number</Typography>
                                <TextFieldButton
                                    //itsOriText={record => (`${record.last_name}`)}
                                    //copyClipBoard={true}
                                    source="full_number"
                                    //source2="f_name"
                                    //source3="l_name"
                                    backgroundColor={'#eee'}
                                />
                            </Box>
                        </Box>
                        <Box flex={1} align={'end'}>
                            <Box display="grid">
                                <div>{`Created: `}
                                    <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                                </div>
                                <div>{`Updated: `}
                                    <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                                </div>
                            </Box>
                        </Box>
                    </Box>
                    {is_load &&
                        <Alert severity="error" style={{color: "red", textAlign: "center"}}>
                            No editing allowed!!! An export to XERO has already been made
                        </Alert>
                    }
                </Box>
            }
            <TabbedForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} isSave={!is_load}/>}
                //redirect={redirect_url}
            >

                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <InvEditCreate {...props}/>
                </FormTab>
                {/*<FormTab label="History">
                    <HistoryListForFilter {...props} table={'passenger'} fullWidth/>
                </FormTab>*/}
                {id &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'invoice'} invoice_id={id} fullWidth/>
                    </FormTab>
                }
            </TabbedForm>
        </>
    )
};

export default InvoiceEditCreate
