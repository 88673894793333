import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export default function Variants(props) {
    const {variants, changeVariant} = props
    const [value, setValue] = React.useState(variants[0]);

    const handleChange = (event) => {
        setValue(event.target.value);
        changeVariant(event.target.value)
    };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Variants</FormLabel>
            <RadioGroup aria-label="variant" name="variant1" row value={value} onChange={handleChange}>
                {variants.map(v=>(<FormControlLabel value={v} control={<Radio/>} label={v}/>))}
            </RadioGroup>
        </FormControl>
    );
}