import * as React from 'react';
import {
    Create, useRedirect
} from 'react-admin';

import PostEditActions from "../_common/PostEditActions";
import SupplierDataEditCreate from "../supplierData/SupplierDataEditCreate";

export const SupplierDataCreate = props => {
    const {location} = props;
    const {search} = location;

    const redirect = useRedirect()
    let bookingId = '';
    if (search) {
        let url = new URL('https://test/' + search);
        bookingId = url.searchParams.get('bookingId')
    }

    const onSuccessData = (data) => {
        const {data: dataRes} = data;
        const {id: idRes} = dataRes || {};
        //console.log('DATA======>', idRes)
        if (bookingId && idRes) {
            redirect(`/booking/${bookingId}/?supplierId=${idRes}`)
        } else props.history.goBack();
    };
    return (
        <Create {...props}
            title={' '}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <SupplierDataEditCreate is_miles={'N'}/>
        </Create>
    )
};