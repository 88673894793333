// import * as React from 'react';
import React from 'react';
import {
    NumberField, TextField,
    useRecordContext,
    //useListContext,
    //CreateButton,
} from 'react-admin';

import {DateFieldComp, TextFieldBold} from "./CompReact";
import ListComponentAdmin from "../_common/ListComponentAdmin";
// import {useGetList} from "ra-core";
import DatagridComponentAdmin from "./DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
import {DataParser} from "../history/HistoryColumnsForList";
import {historyAction} from "../utils/choices";
import SelectAndSaveChoices from "./SelectAndSaveChoices";
import {Box} from "@material-ui/core";

const DtGr = props => {

    const classes = useMyStyles();
    const {booking_id, invoice_id} = props;

    /*useEffect(() => {
        let sum = '-';
        let count = '-';
        if (data) {
            let datafilter = Object.values(data).filter(x => x.status_type === filterInvestmentName)
            datafilter.map(v => {
                sum += v.amount;
                count += 1;
                return true;
            })
        }

        if (total_sum_ref.current) total_sum_ref.current.textContent = formatSpacerNumber(sum);
        const el = document.getElementById(`${filterName}_${filterInvestmentName}`);
        if (el) {
            const parentEl = el.querySelector('span');
            if (parentEl) parentEl.textContent = `${filterInvestmentName} (${count})`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);*/

    /*useEffect(() => {
        console.log('DtGr==========>', record)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);*/

    const postRowStyle = (record, index) => ({
        display: true,
    });

    return (
        <DatagridComponentAdmin
            {...props}
            // rowClick={(id) => `/investment/${id}/`}
            rowStyle={postRowStyle}
        >
            <Box label={'Username'} sortBy={'username'} style={{display: "grid"}}>
                <TextFieldBold source={'username'} label={'Username'}/>
                <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'} showTime={true}
                               cellClassName={classes.date_st}/>
            </Box>
            {(booking_id || invoice_id) &&
                <TextField source="table" label={'Table'}/>
            }
            <SelectAndSaveChoices
                label={'Action'}
                source="action"
                statuses={historyAction}
                resource={'history'}
                textAlign={'center'}
                showTextValue={true}
                width={'6em'}
            />
            {(booking_id || invoice_id) &&
                <NumberField source="table_id" label={'Table Id'} headerClassName={classes.headerShortWidth}/>
            }
            {/*<TextField source={'table'} label={'Table'}/>,
        <TextField source={'table_id'} label={'Table Id'}/>,*/}
            <DataParser label={'Changing Data'}/>
        </DatagridComponentAdmin>
    )
}

const HistoryListForFilter = props => {

    //const total_sum_ref = useRef();
    const {table = '', booking_id = false, invoice_id = false} = props;
    const record = useRecordContext();

    if(!record) return null;

    /*let filter = {table_id: record.id, table: table, invoice_id: record.id};
    if( booking_id) filter = {invoice_id: record.id};*/

    let filter = {table_id: record.id, table: table};
    if( booking_id) filter = {booking_id: record.id};
    else if( invoice_id) filter = {invoice_id: record.id};

    /*const filterInvestment = {
        pending: {status_type: 'pending'},//{pending: true},
        canceled: {status_type: 'canceled'},//{canceled: true},
        raised: {status_type: 'raised'},//{pending: false, canceled: false},
    }

    const {data: dataMenu, loading: isLoading} = useGetList(
        "investment-status",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
        filterInvestment[filterInvestmentName]
    );

    if (isLoading && dataMenu) return null;
    const filterStatus = Object.values(dataMenu).map((x) => x.id)

    if (filterStatus.length === 0) return <h5>Is not result for {filterInvestmentName}</h5>;*/

    return (
        <>

            {/*<CreateButton to={`/investment/create`} {...props}/>*/}
            <h4 style={{marginBottom: '-48px'}}>
                {`History`}
            </h4>
            <ListComponentAdmin
                {...props}
                //perPage={100}
                resource="history"
                hasCreate={false}
                exporter={false}
                filter={filter}
                title={' '}
                sort={{field: 'updated_at', order: 'DESC'}}
                is_bulkActionButtons={false}
            >
                <DtGr {...props} booking_id={booking_id} invoice_id={invoice_id}/>
            </ListComponentAdmin>
        </>
    )
}

export default HistoryListForFilter;
