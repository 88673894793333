//import * as React from 'react';
//import {TextInput} from 'react-admin';

import {NumberInput, SelectInput} from "react-admin";
import {DateInputComp} from "../_common/CompReact";
import * as React from "react";
import {historyAction, resourceTables} from "../utils/choices";

export const HistoryTopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<NumberInput label="Search Booking ID" source="booking_id" alwaysOn />)
    /*dt.push(<ReferenceInput source="booking_id" reference="booking" alwaysOn>
        <AutocompleteInput optionText={record => (record && record.id ? `${record.route} #${record.id}` : 'All Booking')} resettable emptyText={'All Booking'}/>
    </ReferenceInput>);*/
    dt.push(<SelectInput source="table" choices={resourceTables} resettable alwaysOn sx={{minWidth: 300}}/>)
    dt.push(<SelectInput source="action" choices={historyAction} resettable alwaysOn sx={{minWidth: 300}}/>)
    dt.push(<DateInputComp source={"updated_at_gte"} label={"Updated date from"}/>);
    dt.push(<DateInputComp source={"updated_at_lte"} label={"Updated date to"}/>);
    dt.push(<DateInputComp source={"created_at_gte"} label={"Created date from"}/>);
    dt.push(<DateInputComp source={"created_at_lte"} label={"Created date to"}/>);

    // dt.push(<TextInput label="Search" source="q" alwaysOn />)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!" />);
    // dt.push(<TextInput label="Customer" source="customer"/>)
    // dt.push(<TextInput label="Passenger name" source="passanger_name"/>)
    // dt.push(<TextInput label="Airline" source="airline"/>)
    // dt.push(<TextInput label="Program Name" source="programName"/>)
    return dt;
}
