import * as React from 'react';
import {
    //AutocompleteInput,
    //ReferenceInput,
    ReferenceField,
    TextInput,
    TextField, BooleanField
} from 'react-admin';

// import {Box, Button, Typography} from "@material-ui/core";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../_common/ListComponentAdmin";
// import TableActions from "../_common/TableActions";
import useMyStyles from "../utils/useMyStyles";
import {NumberInputFloatComp, TextFieldButton} from "../_common/CompReact";
// import {DeleteWithConfirmIconButton, EditRowButton} from "@react-admin/ra-editable-datagrid";
// import {DeleteWithConfirmIconButton} from "@react-admin/ra-editable-datagrid";
// import {useSelectedColumns} from "@react-admin/ra-preferences";

const ListEditForm = (props) => {
    // const classes = useMyStyles();
    return (
        <RowForm>
            {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
            <ReferenceField source="program_id" reference="programs" label={'Program Name'}
                            sortBy={'programs:title'}>
                <TextField source="title"/>
            </ReferenceField>
            <ReferenceField source="supplier_id" reference="supplierData" label={'Dealer'}
                            sortBy={'supplierData:title'}>
                <TextField source="title"/>
            </ReferenceField>
            <ReferenceField source="supplier_id" reference="supplierData" label={'Is miles'}
                            sortBy={'supplierData:is_miles'}>
                <BooleanField source="is_miles"/>
            </ReferenceField>
            <TextField source={`login`} label={'Login'}/>
            <TextFieldButton
                //itsOriText={record => (`${record.last_name}`)}
                //copyClipBoard={true}
                source="password"
                label={'Password'}
                //pasw={true}
                bold={false}
            />
            <TextInput source={`email_login`} label={'Email Login'} fullWidth/>
            <TextInput source="email_password" label={'Email Password'} fullWidth/>
            <NumberInputFloatComp source="cost" label={'Cost'} fullWidth/>
            {/*<ReferenceInput source="currency_id" reference="currency" label={"Currency"}>
                <AutocompleteInput resettable
                                   optionText={record => (record && `(${record.code}) ${record.title}`)}
                                   fullWidth/>
            </ReferenceInput>*/}
        </RowForm>
    );
};

export const SupplierListForTab = props => {
    const {program_account_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    /*const onSuccess = (data) => {
        //console.log(data.data.id,'data======>', data)
        redirect(`/${redirect(`/booking/${booking_id}`)}`);
    };*/
    const CustomAction = (props) => (
        <>
            {/*<MyEditButton path={'supplier'} fieldId={'id'}/>*/}
            {/*<EditRowButton resource={'supplier'}/>*/}
            {/*<DeleteWithConfirmIconButton
                //mutationMode="undoable"
                // mutationOptions={onSuccess}
                // onSuccess={onSuccess}
                resource={`bookings_passenger`}
                redirect={`/booking/${booking_id}`}
            />*/}
        </>
    );

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.customer_id !== undefined && record.supplier_id !== supplier_id && '#efe',
    });*/
    return (
        <>

            <ListComponentAdmin
                {...props}
                style={{width: 'max-content'}}
                //hasCreate={true}
                // style={{marginTop: -32}}
                title={' '}
                actions={false}
                /*actions={<TableActions
                    preference={`${resource}${".list.columns"}`}
                    select={false}
                    //create={true}
                    filter={false}
                />}*/
                is_bulkActionButtons={false}
                //filters={tableFilters}
                filter={{id: program_account_id}}
                sort={{field: 'id', order: 'DESC'}}
                pagination={false}
            >
                <EditableDatagrid
                    actions={<CustomAction/>}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={true}
                    // initialRowMode="edit"
                    //rowClick="edit"
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm  {...props} submitOnEnter={false}/>}
                    editForm={<ListEditForm {...props} submitOnEnter={false}/>}
                    //rowStyle={postRowStyle}
                >
                    {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
                    <ReferenceField source="program_id" reference="programs" label={'Program'}
                                    sortBy={'programs:title'}>
                        <TextField source="title"/>
                    </ReferenceField>
                    {/*<ReferenceField source="supplier_id" reference="supplierData" label={'Is miles'} link={false}
                                    sortBy={'supplierData:is_miles'}>
                        <BooleanField source="is_miles"/>
                    </ReferenceField>*/}
                    {/*<TextField source={`account_name`} label={'Account name'}/>,*/}
                    <TextField source={`f_name`} label={'Account First Name'}/>,
                    <TextField source={`l_name`} label={'Account Last Name'}/>,
                    <TextField source={`account_number`} label={'Account number'}/>
                    <TextField source={`login`} label={'Account Username'}/>
                    {/*<TextField source={`password`} label={'Password'}/>*/}
                    <TextFieldButton
                        //itsOriText={record => (`${record.last_name}`)}
                        copyClipBoard={true}
                        source="password"
                        label={'Password'}
                        pasw={true}
                        bold={false}
                    />
                    <TextField source={`pin`} label={'Pin'}/>
                    <TextField source={`email_login`} label={'Email Connected to Account'}/>
                    {/*<TextField source={`email_password`} label={'Email Password'}/>*/}
                    <TextFieldButton
                        //itsOriText={record => (`${record.last_name}`)}
                        copyClipBoard={true}
                        source="email_password"
                        label={'Email Password'}
                        pasw={true}
                        bold={false}
                    />
                    <TextField source={`cost`} label={'Cost (mi)'}/>
                    <ReferenceField source="supplier_id" reference="supplierData" label={'Dealer'} link={false}
                                    sortBy={'supplierData:title'}>
                        <TextField source="title"/>
                    </ReferenceField>
                    {/*<ReferenceField source="currency_id" reference="currency" label={'Currency'}
                                    sortBy={'currency:code'}>
                        <TextField source="code"/>
                    </ReferenceField>*/}
                </EditableDatagrid>
            </ListComponentAdmin>
        </>
    )
};