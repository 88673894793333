import * as React from 'react';
import {
    Create, useCreate, useRedirect
} from 'react-admin';

import CustomerEditCreate from "./CustomerEditCreate";
import PostEditActions from "../_common/PostEditActions";

export const CustomerCreate = props => {
    const {location} = props;
    const {search} = location;

    const redirect = useRedirect()
    let customerId = '';
    let bookingId = '';
    if (search) {
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
        bookingId = url.searchParams.get('bookingId')
    }

    const [create] = useCreate();
    const onSuccessData = (data) => {
        const {data: dataRes} = data;
        const {id: idRes, main_passenger_id} = dataRes || {};
        //console.log('DATA======>', idRes)
        if (idRes) {
            console.log('DATA=bookingId=====>', bookingId)
            if (bookingId) {
                console.log('DATA=bookingId2=====>', bookingId)
                if(bookingId === '0') {
                    redirect(`/booking/create/?customerId=${idRes}`)
                }
                else {
                    create('bookings_passenger', {booking_id: bookingId,  passenger_id: main_passenger_id})
                    redirect(`/booking/${bookingId}/?customerId=${idRes}`)
                }
            } else props.history.goBack();
        } else props.history.goBack();
    };

    return (
        <Create {...props}
                title={' '}
                transform={data => {
                    /*if(data.is_company){
                        //data.b_day = null
                        data.f_name = null
                        data.l_name = null
                        data.title = null
                    }*/
                    return ({...data})
                }}
                onSuccess={onSuccessData}
            /*onSuccess={() => bookingId
                ? redirect(`/booking/${bookingId}/?customerId=${customerId}`)
                : props.history.goBack()}*/
                actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <CustomerEditCreate customerId={customerId}/>
        </Create>
    )
};