import * as React from 'react';
import {
    BooleanInput,
    BooleanField,
    ReferenceManyField,
    TextInput,
    TextField,
} from 'react-admin';
import {Grid, Typography} from "@material-ui/core";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {DateFieldComp, DateInputComp, NumberInputFloatComp} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";
import {FloatNumber} from "./BookingColumnsForList";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const {booking_id} = props;

    return (
        <RowForm  {...props} initialValues={{booking_id: booking_id}} style={{verticalAlign: "top"}}>
            <DateInputComp source={"expire_date"} label={"Expire date"} validate={validateReq}/>
            <TextInput source={'credit_ticket_number'} label={"Credit ticket number"} validate={validateReq} />
            <NumberInputFloatComp source="amount_dollar" label={'Amount ($)'} validate={validateReq}/>
            <BooleanInput source="bookman_property" label={'Bookman Property'} defaultValue={false}
                          fullWidth/>
        </RowForm>
    )
};

const DtGr = (props) => {
    // const {booking_id, classes, total} = props;
    const {booking_id, classes} = props;
    return (
        <EditableDatagrid
            {...props}
            /*data={data}
            total={total}*/
            classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
            //noDelete={true}
            /*onChange={(data) => {
                console.log('===fdsBookingForm==========>', data)
                // localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
            }}*/
            mutationMode="pessimistic" //Подтвердить удаление
            undoable
            // createForm={total > 0 ? false : <ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
            createForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
            editForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
            //rowStyle={postRowStyle}
        >
            <DateFieldComp source="expire_date" label={'Expire Date'} textAlign={"center"}
                           cellClassName={classes.date_st} showTime={false} locale={'us'}/>
            <TextField source={'credit_ticket_number'} label={"Credit ticket number"} textAlign={"right"}/>
            <FloatNumber source={'amount_dollar'} label={'Amount ($)'} textAlign={"right"}/>
            <BooleanField source={'bookman_property'} label={'Bookman Property'} textAlign={"center"}/>
            {/*<BooleanFieldComp source="is_used" label={'Is used'} textAlign={"center"}/>*/}
        </EditableDatagrid>
    )
};

const TravelCreditForBooking2 = props => {
    const {booking_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    /*const { data, total, isLoading } = useGetList('travelCredit',
        { page: 1, perPage: 10 },
        {},
        { filter: {booking_id: booking_id}},
    );
    if (isLoading) return null;
    console.log('data======>', data)*/

    //let data = []; let total = 0;
    return (
        <>
            <Grid item xs={12} className={classes.booking_title}>
                <Typography variant="h6" gutterBottom>
                    Travel Credit
                </Typography>
            </Grid>
            <div style={{width: "max-content", minWidth: 500, marginBottom: 16}}>
                <ReferenceManyField
                    //fullWidth
                    label=" "
                    reference="travelCredit"
                    target="booking_id"
                >
                    <DtGr classes={classes} booking_id={booking_id}/>
                </ReferenceManyField>
            </div>
        </>
    )
}
export default TravelCreditForBooking2;