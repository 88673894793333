import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    //useRecordContext,
    TextField, useRecordContext,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp} from "../_common/CompReact";
import get from "lodash/get";
import {Grid} from "@material-ui/core";
import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
import {historyAction} from "../utils/choices";

import isEqual from "lodash/isEqual";

export const DataParser = (props) => {
    // const {source = null, column = 'none', fix = 2, delim = ','} = props;
    const record = useRecordContext(props);
    if (!record) return null;
    let data = get(record, 'data', null)
    let data_before = get(record, 'data_before', null)
    let act = get(record, 'action', null)

    data = Object.entries(data);
    let arrKey = ['id', 'updated_at', 'created_at']
    return (
        //<div style={{display: "grid"}}>
        <Grid container spacing={2} alignItems='center' style={{display: "grid"}}>
            <Grid item xs={12}>
                {data.map(([key, value]) => {
                    if (arrKey.indexOf(key) < 0) {
                        if (Array.isArray(value)) {
                            return (
                                <div style={{display: "grid"}}>
                                    {data_before && data_before[key] !== undefined && isEqual(data_before[key], value)
                                        ? null
                                        : <span style={{color: "gray"}}>{key}: </span>
                                    }

                                    {value.map((it, index) => {
                                        return (
                                            data_before && Array.isArray(data_before[[key]])
                                                ?
                                                data_before[key][index] !== undefined && isEqual(data_before[key][index], it)
                                                    ? null /*<span style={{marginLeft: 16}}>{JSON.stringify(it)}</span>*/
                                                    :
                                                    <>
                                                        {data_before[key][index] !== undefined &&
                                                            <span style={{
                                                                marginLeft: 16,
                                                                color: "red",
                                                                textDecoration: "line-through"
                                                            }}>{JSON.stringify(data_before[key][index])}</span>
                                                        }
                                                        <span style={{
                                                            marginLeft: 16,
                                                            color: "forestgreen"
                                                        }}>{JSON.stringify(it)}</span>
                                                    </>
                                                : <span
                                                    style={{
                                                        marginLeft: 16,
                                                        color: "forestgreen"
                                                    }}>{JSON.stringify(it)}</span>
                                        )
                                    })}
                                </div>
                            )
                        } else if (value !== 'Missing') {
                            return (
                                <div>
                                    {/*<span style={{color: "gray"}}>{key}: </span>*/}
                                    {act !== 'delete' && data_before
                                        ? data_before[key] !== undefined && data_before[key] === value
                                            ?
                                            null /*<span>{JSON.stringify(value)}</span>*/
                                            :
                                            <>
                                                <span style={{color: "gray"}}>{key}: </span>
                                                {data_before[key] !== undefined && <span
                                                    style={{
                                                        color: "red",
                                                        textDecoration: "line-through"
                                                    }}>{JSON.stringify(data_before[key])} </span>}
                                                <span style={{color: "forestgreen"}}>{JSON.stringify(value)} </span>
                                            </>
                                        :
                                        <>
                                            <span style={{color: "gray"}}>{key}: </span>
                                            <span style={{color: "forestgreen"}}>{JSON.stringify(value)}</span>
                                        </>

                                    }
                                </div>
                            )
                        } else return null;
                    } else return null;
                })
                }
            </Grid>
        </Grid>
        //</div>

    )

    /*return (
        <div style={{width: 200}}>{JSON.stringify(data)}</div>
    );*/
}

export const HistoryColumnsForList = (resource, locale, classes) => {
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        username: <TextField source={'username'} label={'Username'}/>,
        table: <TextField source={'table'} label={'Table'}/>,
        table_id: <TextField source={'table_id'} label={'Table Id'}/>,
        booking_id: <TextField source={'booking_id'} label={'Booking Id'}/>,
        action: /*<TextField source={'action'} label={'Action'}/>,*/
            <SelectAndSaveChoices
                label={'Action'}
                source="action"
                statuses={historyAction}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'6em'}
            />,
        data: <DataParser label={'Changing Data'}/>,/*<TextField source={'data'} label={'data'}/>,*/

        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'} showTime={true}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'} showTime={true}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};
