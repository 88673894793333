import * as React from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    useRedirect,
    TextInput,
    useRecordContext, ReferenceManyField, TextField, BooleanInput,
} from 'react-admin';

import {Box, Button} from "@material-ui/core";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {BooleanFieldComp, GetFieldById, TextFieldButton} from "../_common/CompReact";
import get from "lodash/get";


const ListEditForm = (props) => {
    const {customer_id, tmp_all_customers, booking_id} = props;
    //const classes = useMyStyles();
    return (
        <RowForm {...props} initialValues={{booking_id: booking_id}}>
            <BooleanInput source="is_main" label={'Is Main'} defaultValue={false} fullWidth/>
            <ReferenceInput
                sort={{field: 'l_name', order: 'ASC'}}
                //perPage={10}
                label={'Add Passenger'}
                source="passenger_id"
                reference="passenger"
                //onChange={handleChangeCategory}
                filter={!tmp_all_customers ? {customer_id: customer_id} : false}
                fullWidth
            >
                <AutocompleteInput
                    resettable
                    debounce={500}
                    //onChange={handleClick}
                    //???shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                    /*optionText={record => {
                        //console.log('record==============>', record)
                        return (record && `${record.title} ${record.f_name} ${record.l_name} ${record.is_main ? '(is main)' : ''}`)
                    }}*/
                    //optionText={record => (`${record.title} ${record.f_name} ${record.l_name}`)}
                    optionText="repr"
                />
            </ReferenceInput>

            <TextInput source={'ticket_number'} label={'Ticket number'}/>
            <TextInput source="notes" label={'Notes'}/>
        </RowForm>
    )
};

const CeateNew = (props) => {
    const redirect = useRedirect()
    const {customer_id, booking_id, formData} = props;

    //const [create, {isLoading, error}] = useCreate(resource, data);
    return (
        <Box display="flex" alignItems={"center"} p="1em">
            <Box>
                <Button
                    //disabled={!data[resource].passenger_id}
                    onClick={() => {
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                        redirect(`/passenger/create/?customerId=${customer_id}&bookingId=${booking_id}`)
                    }}
                    color="primary"
                    label={'Create New'}
                >
                    {`Create New Passenger `}
                </Button>
            </Box>
        </Box>
    )
};

/*
const CeateNew = (props) => {
    const redirect = useRedirect()
    const {customer_id, booking_id, formData} = props;
    let resource = 'bookings_passenger';
    const refresh = useRefresh();
    const [data, setData] = useState({[resource]: {booking_id: booking_id}})

    let tmp_all_customers = get(formData, 'tmp_all_customers', null)

    //const [create, {isLoading, error}] = useCreate(resource, data);
    const [create] = useCreate(resource, data[resource]);
    const handleClick = (event) => {
        // event.preventDefault();
        // event.stopPropagation();
        if (data[resource].passenger_id) {
            localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
            create();
            setData({[resource]: {booking_id: booking_id}});
            setTimeout(function () {
                refresh();
            }, 200);
        }
    };

    const handleChangeCategory = (event) => {
        // console.log('====2==========>', event);
        set(data, `${resource}.passenger_id`, event)
        setData(data);
        handleClick(event)
    };

    //console.log('==customer_id============>', customer_id);
    return (
        <Box display="flex" alignItems={"center"} p="1em">
            <Box mr="2em">
                <ReferenceInput
                    label={'Passenger'}
                    source="passenger_id"
                    reference="passenger"
                    onChange={handleChangeCategory}
                    filter={!tmp_all_customers ? {customer_id: customer_id} : false}
                    fullWidth
                >
                    <AutocompleteInput
                        resettable
                        onChange={handleClick}
                        optionText={record => (record && `${record.title} ${record.f_name} ${record.l_name} ${record.is_main ? '(is main)' : ''}`)}/>
                </ReferenceInput>
            </Box>
            <Box>
                <Button
                    //disabled={!data[resource].passenger_id}
                    onClick={() => {
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                        redirect(`/passenger/create/?customerId=${customer_id}&bookingId=${booking_id}`)
                    }}
                    color="primary"
                    label={'Create New'}
                >
                    {`Create New Passenger `}
                </Button>
            </Box>
        </Box>
    )
};
*/

const TextErrIfEmpty = ({source, err = ''}) => {
    const record = useRecordContext();
    let res = get(record, source, '');
    return (
        <span style={{color: !res && 'red'}}>
            {!res ? err : res}
        </span>
    );
};

export const BookingPassengerListForTab2 = props => {
    const {resource, booking_id, customer_id, formData} = props;
    const classes = useMyStyles();
    let airLineId = get(formData, 'issued_airline_id', '')
    let tmp_all_customers = get(formData, 'tmp_all_customers', null)
    return (
        <div style={{width: "max-content", minWidth: 500, marginTop: -16}}>
            <CeateNew resource={resource} customer_id={customer_id} booking_id={booking_id} formData={formData}/>
            <ReferenceManyField
                fullWidth
                label=" "
                reference="new_bookings_passenger"
                target="booking_id"
            >
                <EditableDatagrid
                    //actions={<CustomAction/>}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    /*noDelete={true}
                    onChange={(data) => {
                        localStorage.setItem('fdsBookingForm', JSON.stringify(formData));
                    }}*/
                    // initialRowMode="edit"
                    //rowClick="edit"
                    mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm  {...props} submitOnEnter={false} booking_id={booking_id}
                                               customer_id={customer_id} tmp_all_customers={tmp_all_customers}/>}
                    editForm={<ListEditForm {...props} submitOnEnter={false} booking_id={booking_id}
                                            customer_id={customer_id} tmp_all_customers={tmp_all_customers}/>}
                    //rowStyle={postRowStyle}
                >
                    <BooleanFieldComp sourse={'is_main'} label={'Is Main'}/>
                    <ReferenceField source="passenger_id" reference="passenger" label={'Passenger'} link={false}
                                    textAlign={"left"}>
                        <TextFieldButton
                            //itsOriText={record => (`${record.last_name}`)}
                            // copyClipBoard={true}
                            source="title"
                            source2="f_name"
                            source3="l_name"
                            if_field={'customer_id'}
                            if_value={customer_id}
                        />
                    </ReferenceField>
                    <Box label={'Ticket number'}>
                        {/*<TextField source={'airline_ticket_code'}/>*/}
                        <GetFieldById id={airLineId} resource={'airline'} field={'ticket_code'}/>
                        {'-'}
                        <TextErrIfEmpty source={'ticket_number'} err={'Ticket number is required'}/>
                    </Box>
                    <TextField source="notes" label={'Notes'}/>
                </EditableDatagrid>
            </ReferenceManyField>
        </div>
    )
};