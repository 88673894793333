import * as React from 'react';

import EditComponentAdmin from "../_common/EditComponentAdmin";
import BookingEditCreate from "./BookingEditCreate";
import {useNotify, useRedirect, useRefresh, TabbedForm, FormTab} from "react-admin";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import HistoryListForFilter from "../_common/HistoryListForFilter";
import Alert from "@material-ui/lab/Alert";
//import set from "lodash/set";

export const transformBookingDraft = (data) => {
    console.log(data.payment_type, 'Transform=Booking=DRAFT======>', data)
    let dt = transformBooking(data, true)
    return dt
}

export const transformBooking = (data, draft=false) => {
    console.log(data.payment_type, 'Transform=Booking=======>', data)
    localStorage.removeItem('fdsBookingForm');

    if(!data.id) data.draft = true
    else data.draft = draft

    if (data.other_costs && data.other_costs.length < 1) data.other_costs = null

    if (data.cost_total_dollar === null) data.cost_total_dollar = 0;
    if (data.sale_total_dollar === null) data.sale_total_dollar = 0;

    if (data.booking_code) data.booking_code = data.booking_code.replaceAll('-', '');
    if (data.travel_credit_dollar === null) data.travel_credit_dollar = 0;
    if (data.cost_travel_credit_dollar === null) data.cost_travel_credit_dollar = 0;
    if (data.payment_type === 'cash') {
        data.quantity = 1;
        data.cost_miles_amount_dollar = null;
        data.cost_miles_price_dollar = null;
        data.sale_miles_price_dollar = null;
        data.program_account_id = null;
        data.program_id = null;

        data.card_miles_id = null;
        //if(data.cost_ticket_tax_amount_dollar === null || data.cost_ticket_tax_amount_dollar === 0) data.card_tax_id = null;
        if (data.cost_ticket_cc_fee_amount_dollar === null || data.cost_ticket_cc_fee_amount_dollar === 0) data.card_fees_id = null;
        data.card_tax_id = null;

    } else if (data.payment_type === 'miles_only') {
        //data.issued_airline_id = null;
        data.operated_airline_id = null;
        data.card_id = null;

        if (data.cost_miles_amount_dollar === null || data.cost_miles_amount_dollar === 0) data.card_miles_id = null;
        data.card_fees_id = null;
        data.card_tax_id = null;

        data.cost_ticket_cc_fee_amount = null;
        data.cost_ticket_cc_fee_amount_dollar = null;
        data.cost_ticket_cc_fee_currency_exchange = null;
        data.cost_ticket_cc_fee_currency_id = null;
        data.cost_ticket_tax_amount = null;
        data.cost_ticket_tax_amount_dollar = null;
        data.cost_ticket_tax_currency_exchange = null;
        data.cost_ticket_tax_currency_id = null;

        data.sale_amount_dollar = null;
        data.sale_ticket_cc_fee_dollar = null;

        data.cost_per_passenger_amount = null;
        data.sale_per_passenger_amount = null;

        data.travel_credit_dollar = 0;
        data.travel_credit_id = null;
        data.cost_travel_credit_dollar = 0;
        data.cost_travel_credit_id = null;

        data.supplier_id = null;

        // data.ticket_number = null;
        data.legs = null;
        data.travel_date = null;
        data.travel_date_return = null;
        data.route = null;
        data.ticket_type = null;
        data.flight_number = null;
        data.flight_number_return = null;
        data.ticket_class = null;
    } else if (data.payment_type === 'miles') {
        data.supplier_id = null;
        data.card_id = null;
        if (data.cost_miles_amount_dollar === null || data.cost_miles_amount_dollar === 0) data.card_miles_id = null;
        if (data.cost_ticket_tax_amount_dollar === null || data.cost_ticket_tax_amount_dollar === 0) data.card_tax_id = null;
        if (data.cost_ticket_cc_fee_amount_dollar === null || data.cost_ticket_cc_fee_amount_dollar === 0) data.card_fees_id = null;
    }

    switch (data.ticket_type) {
        case 'multi_city':
            data.travel_date_return = null;
            data.flight_number_return = null;
            if (data.legs && data.legs.length > 0) {
                data.legs.forEach((it) => {
                    it.route = `${it.from_place} - ${it.to_place}`;
                })
                data.travel_date = data.legs[0].travel_date;
                data.flight_number = data.legs[0].flight_number;
                data.route = `${data.legs[0].from_place} - ${data.legs[data.legs.length - 1].to_place}`;//data.legs[0].route;
                data.from_place = data.legs[0].from_place;
                data.to_place = data.legs[data.legs.length - 1].to_place;
            }
            break;
        case 'rtn':
            data.legs = null;
            data.route = `${data.from_place} - ${data.to_place}`;
            break;
        case 'single':
            data.legs = null;
            data.travel_date_return = null;
            data.flight_number_return = null;
            data.legs = null;
            data.route = `${data.from_place} - ${data.to_place}`;
            break;
        default:
            break;
    }

    if (data.transaction_type === "non_flight") {
        delete data.cost_total_dollar;
        delete data.sale_total_dollar;
    }

    if (data.booking_cost_travel_credit && data.booking_cost_travel_credit.length > 0) {
        data.cost_travel_credit_id = null
        if (data.ori_booking_cost_travel_credit && data.ori_booking_cost_travel_credit.length > 0) {
            data.ori_booking_cost_travel_credit.forEach((it, ind) => {
                const {id = null} = it
                if (id && !data.booking_cost_travel_credit.find(x => x.id === id)) {
                    it.action = 'delete'
                    data.booking_cost_travel_credit.push(it)
                }
            })

        }
    } else {
        if (data.ori_booking_cost_travel_credit && data.ori_booking_cost_travel_credit.length > 0) {
            data.booking_cost_travel_credit = []
            data.ori_booking_cost_travel_credit.forEach((it, ind) => {
                it.action = 'delete'
                data.booking_cost_travel_credit.push(it)
            })
        } else delete data.booking_cost_travel_credit
    }


    /*let oriForm = localStorage.getItem('oriFdsBookingForm');
    if (oriForm && data.id !== undefined) {
        oriForm = JSON.parse(oriForm);
        Object.entries(oriForm).forEach(([key, value]) => {
            // console.log(data[key],'===oriForm=2====>', value);
            if (data[key] !== undefined && value === data[key]) {
                delete data[key];
            }
        });
    }*/
    return data;
}
const BlockEdit = props => {
    const {customerId, supplierId, record} = props
    const {is_load, invoice, draft} = record
    //console.log('=source=======>', props)
    return (
        <>
            {(is_load || invoice) &&
                <Alert severity="error" style={{color: "red", textAlign: "center"}}>
                    No editing allowed!!! This booking has been exported/added to invoice. Please obtain permission from
                    manager before editing.
                </Alert>
            }
            <TabbedForm
                {...props}
                style={{width: '100%'}}
                toolbar={
                    <PostEditDeleteActions
                        saveAndDraft={draft ? transformBookingDraft : false}
                        redirectDraft={'/booking'}
                        saveAndClose={'/booking'}
                        reload={true}
                        // isSave={(is_load || invoice) ? false : true}
                    />
                }
                //redirect={redirect_url}
            >
                <FormTab label="Common">
                    <BookingEditCreate {...props} customerId={customerId} supplierId={supplierId}/>
                </FormTab>
                <FormTab label="History">
                    <HistoryListForFilter {...props} booking_id={true} fullWidth/>
                </FormTab>
                {/*<FormTab label="History Passengers">
                    <HistoryListForFilter {...props} table={'bookings_passenger'} booking_id={true} fullWidth/>
                </FormTab>
                <FormTab label="History Travel Credit">
                    <HistoryListForFilter {...props} table={'travel_credit'} booking_id={true} fullWidth/>
                </FormTab>
                <FormTab label="History Hidden City">
                    <HistoryListForFilter {...props} table={'hidden_city'} booking_id={true} fullWidth/>
                </FormTab>
                <FormTab label="History References">
                    <HistoryListForFilter {...props} table={'bookings_reference'} booking_id={true} fullWidth/>
                </FormTab>*/}
            </TabbedForm>
        </>
    )
}

export const BookingEdit = props => {
    const {location} = props;

    const {search, pathname} = location;
    let customerId = '';
    let supplierId = '';
    if (search) {
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
        supplierId = url.searchParams.get('supplierId')
    }
    const redirect = useRedirect()
    const notify = useNotify();
    const refresh = useRefresh();

    const onError = (error) => {
        console.log('Error======>', error)
        notify(`Could not edit post: ${error.message}`);
        redirect(pathname);
        refresh();
    };

    const onSuccess = (data) => {
        notify(`Saved data`);
        redirect(pathname)
        refresh();
    };
    console.log('=source=======>', props)
    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            transform={transformBooking}
            // mutationOptions={{ onSuccess }}
            mutationOptions={{onError}}
            onSuccess={onSuccess}
            // onSuccess={() => idSource ? props.history.goBack() : redirect(pathname)}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            {/*<SimpleForm toolbar={<PostEditDeleteActions saveAndClose={'/booking'}/>}>*/}
            <BlockEdit {...props} customerId={customerId} supplierId={supplierId}/>
            {/*</SimpleForm>*/}
        </EditComponentAdmin>
    )
};