import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {HistoryList} from "./HistoryList";
import {HistoryEdit} from "./HistoryEdit";
import {HistoryCreate} from "./HistoryCreate";

const resource = {
    list: HistoryList,
    create: HistoryCreate,
    edit: HistoryEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;