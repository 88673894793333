import * as React from 'react';
import {
    //NumberField,
    TabbedForm,
    FormTab,
    TextInput,
    // useLocale,
    //NumberInput,
    //BooleanInput,
    AutocompleteInput, ReferenceInput, NumberInput, SelectInput, ReferenceField, BooleanInput, FormDataConsumer
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, DateInputComp, GetFieldById, NumberInputFloatComp, TextFieldButton} from "../_common/CompReact";
import {validateFirstName, validateNotes, validateReq} from "../utils/validateTable";
import useMyStyles from "../utils/useMyStyles";
import {hidden_city_flight_status} from "../utils/choices";
import HistoryListForFilter from "../_common/HistoryListForFilter";
//import get from "lodash/get";
// import {localesConfig} from "../utils/config";
//import {BasicTabs} from "../_common/BaseTab";

export const HiddenCityEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const classes = useMyStyles();
    const {record, bookingId} = props;
    const {id} = record;
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="1.5em">
                            <Typography variant="subtitle2" gutterBottom>Route</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="hc_route"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Booking</Typography>
                            <ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'}
                                            cellClassName={classes.route_st}>
                                <TextFieldButton
                                    //itsOriText={record => (`${record.last_name}`)}
                                    //copyClipBoard={true}
                                    backgroundColor={'#eee'}
                                    source="route"
                                />
                            </ReferenceField>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <Box p="1em" fullWidth>

                        <Box display="flex" alignItems={"center"}>
                            {bookingId
                                ?
                                <Box flex={1} mr={'2em'} mb={'1em'}>
                                    <div className={classes.cost_show_field}>
                                        <div>
                                            <span style={{fontSize: 12}}>{'Booking'}</span>
                                        </div>
                                        <div>
                                            <GetFieldById id={bookingId} resource={'booking'} field={'route'}/>
                                        </div>
                                    </div>
                                    <Box hidden><NumberInput source={'booking_id'} defaultValue={bookingId}/></Box>
                                    {/*<Typography variant="h6" gutterBottom>
                                        <GetFieldById id={customerId} resource={'customer'} field={'name'}/>
                                        <Box hidden><NumberInput source={'customer_id'} defaultValue={customerId}/></Box>
                                    </Typography>*/}
                                </Box>
                                :
                                <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                    {/*<Typography variant="h5" gutterBottom>ID: </Typography>*/}
                                    {/*<NumberField source="id" variant="h5"/>*/}
                                    <ReferenceInput source="booking_id" reference="booking" validate={validateReq}>
                                        <AutocompleteInput resettable
                                                           optionText={record => (record && record.id ? `${record.route} #${record.id}` : 'All Booking')}
                                                           fullWidth/>
                                    </ReferenceInput>
                                </Box>
                            }
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="hc_route" label={'Route'} validate={validateFirstName} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <TextInput source="hc_flight_number" label={'Flight number'}
                                           validate={validateFirstName} fullWidth/>
                            </Box>
                            <Box flex={1}>
                                <DateInputComp source={"hc_travel_date"} label={"Travel date"} validate={validateReq}
                                               fullWidth/>
                            </Box>
                        </Box>

                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'2em'}>
                                <SelectInput source="flight_status" choices={hidden_city_flight_status}
                                             validate={validateReq} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                <NumberInputFloatComp source="refund" label={'Dead-end Refund'} fullWidth/>
                            </Box>
                            <Box flex={1} mr={'2em'}>
                                {/*<DateInputComp source={"hc_travel_date"} label={"Travel date"} validate={validateReq}
                                               fullWidth/>*/}
                            </Box>
                            <Box flex={1}>
                                <DateInputComp source={"refunded_date"} label={"Refunded date"}
                                               fullWidth/>
                            </Box>
                        </Box>

                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                //let flight_status = get(formData, 'flight_status', null)
                                if (formData.flight_status !== 'refund_to_airlines_credits') return null;
                                return (
                                    <Box display="flex" alignItems={"center"}>
                                        <Box flex={1} mr={'2em'}>
                                            <DateInputComp source={"expire_date"} label={"Expire date"}
                                                           fullWidth validate={validateReq}/>
                                        </Box>
                                        <Box flex={1} mr={'2em'}>
                                            <BooleanInput source="bookman_property" label={'Bookman Property'}
                                                          defaultValue={false}
                                                          fullWidth/>
                                        </Box>
                                        <Box flex={1} mr={'2em'}>
                                        </Box>
                                        <Box flex={1}>
                                        </Box>
                                    </Box>
                                )
                            }}
                        </FormDataConsumer>


                        <Box display="flex" alignItems={"center"}>
                            <Box flex={1}>
                                <TextInput
                                    multiline
                                    source="notes"
                                    label={'Notes'}
                                    validate={validateNotes}
                                    fullWidth
                                />
                            </Box>
                        </Box>

                    </Box>
                </FormTab>
                {id && !bookingId &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'hidden_city'} fullWidth/>
                    </FormTab>
                }
            </TabbedForm>
        </>
    )
};

export default HiddenCityEditCreate
