import * as React from 'react';
import {
    //NumberField,
    TabbedForm,
    FormTab,
    // useLocale,
    //NumberInput,
    //BooleanInput,
    AutocompleteInput, ReferenceInput, NumberInput, ReferenceField, FormDataConsumer
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, DateInputComp, GetFieldById, NumberInputFloatComp, TextFieldButton} from "../_common/CompReact";
import {validateReq} from "../utils/validateTable";
import useMyStyles from "../utils/useMyStyles";
import set from "lodash/set";
// import get from "lodash/get";
import {useEffect} from "react";
// import {useForm} from "react-final-form";
import {useSelector} from "react-redux";

const MainBlock = (props) => {
    const {formData, bookingId, startForm, setStartForm} = props;
    const classes = useMyStyles();
    let currencyData = useSelector((state) => state.admin.resources.currency.data)

    useEffect(() => {
        console.log('setStartForm======>')
            setTimeout(function () {
                setStartForm(true)
            }, 500);
    }, []);// eslint-disable-line

    //console.log(startForm,'currencyData======>', currencyData)
    useEffect(() => {
        //console.log('currency_id======>', formData.currency_id)
        if (formData.currency_id) {
            let cur = Object.values(currencyData).find((x) => x.id === formData.currency_id);
            //console.log('cur======>', cur)
            let exchange_rate = cur ? cur.exchange_rate : null;
            if (exchange_rate && startForm) {
                //formEdit.change('currency_exchange', exchange_rate);
                //console.log('exchange_rate======>', exchange_rate)
                set(formData,'currency_exchange', exchange_rate);
                let elem = document.getElementById('currency_exchange')
                if (elem) elem.focus()
            }
        }
    }, [formData.currency_id]);// eslint-disable-line

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"center"}>
                {bookingId
                    ?
                    <Box flex={1} mr={'2em'} mb={'1em'}>
                        <div className={classes.cost_show_field}>
                            <div>
                                <span style={{fontSize: 12}}>{'Booking'}</span>
                            </div>
                            <div>
                                <GetFieldById id={bookingId} resource={'booking'} field={'route'}/>
                            </div>
                        </div>
                        <Box hidden><NumberInput source={'booking_id'} defaultValue={bookingId}/></Box>
                    </Box>
                    :
                    <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                        <ReferenceInput source="booking_id" reference="booking" validate={validateReq}>
                            <AutocompleteInput resettable optionText={record => (record && record.id ? `${record.route} #${record.id}` : 'All Booking')} fullWidth/>
                        </ReferenceInput>
                    </Box>
                }
                <Box flex={1} mr={'2em'}>
                    <DateInputComp source={"transaction_date"} label={"Transaction Date"} validate={validateReq}
                                   fullWidth/>
                </Box>

                <Box flex={1} mr={'2em'}>
                    <ReferenceInput source="currency_id" label={'Currency'} reference="currency" validate={validateReq}>
                        <AutocompleteInput resettable
                                           optionText={record => (record && (record.is_reverse_exchange_rate
                                                   ? `${record.code} / ${'USD'}`
                                                   : `${'USD'} / ${record.code}`)
                                           )}
                            // optionText={record => (record && `(${record.code}) ${record.title}`)}
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <NumberInputFloatComp source="currency_exchange"
                                          label={'Exchange Rate'}
                                          validate={validateReq} fullWidth/>
                </Box>
            </Box>

            <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr={'2em'}>
                    <NumberInputFloatComp source="amount" label={'Amount'} validate={validateReq} fullWidth/>
                </Box>
                <Box flex={1}>
                    <NumberInputFloatComp source="tax" label={'Tax'} validate={validateReq} fullWidth/>
                </Box>
            </Box>

        </Box>
    )
}
export const RefundEditCreate = props => {//Не работает
    //const classes = useMyStyles();
    //const formEdit = useForm();
    const {record, bookingId} = props;
    const {id} = record;
    const [startForm, setStartForm] = React.useState(false);
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Route</Typography>
                            <ReferenceField source="booking_id" reference="booking">
                                <TextFieldButton
                                    //itsOriText={record => (`${record.last_name}`)}
                                    //copyClipBoard={true}
                                    source="route"
                                    backgroundColor={'#eee'}
                                />
                            </ReferenceField>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            // debugger
                            return (
                                <MainBlock
                                    //id={id}
                                    formData={formData}
                                    setStartForm={setStartForm}
                                    startForm={startForm}
                                    bookingId={bookingId}
                                    {...rest}
                                />)
                        }}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </>
    )
};

export default RefundEditCreate
