import * as React from 'react';

import {
    DeleteButton,
    SaveButton,
    Toolbar, useNotify, useRedirect,
} from 'react-admin';
import {canAccess, usePermissions} from "@react-admin/ra-rbac";
import BackButton, {Reload} from "./BackButton";

const PostEditDeleteActions = (
    {
        redirectDraft = '/',
        saveAndDraft = false,
        reload = false,
        saveAndClose = '',
        action = 'all',
        goBack = false,
        isSave = true,
        ...props
    }) => {
    const {resource} = props;
    const {loading, permissions} = usePermissions();

    const redirect = useRedirect()
    const notify = useNotify();

    if (loading) return null;
    // const canDelete = canAccess({permissions, resource: 'tickets.delete', action: 'delete'})
    const canDelete = canAccess({permissions, resource: `${resource}`, action: 'delete'})
    //console.log('isSave=====>', isSave);
    if (action === 'withoutButtons') return null;
    return (
        <Toolbar {...props} style={{display: "flex", justifyContent: "space-between"}}>
           {/* <Box display={'flex'}>*/}
                {isSave && saveAndDraft &&
                    <SaveButton
                        style={{backgroundColor: 'brown'}}
                        label={'Save Draft'}
                        onSuccess={data => {
                            notify(`Saved data`);
                            if(redirectDraft) redirect(redirectDraft)
                        }}
                        submitOnEnter={true}
                        transform={saveAndDraft}
                    />
                }
                {isSave && <SaveButton/>}
            {/*</Box>*/}
            {isSave && saveAndClose &&
                <SaveButton
                    label={'Save & Close'}
                    onSuccess={data => {
                        notify(`Saved data`);
                        redirect(saveAndClose)
                    }}
                    submitOnEnter={true}
                    //onClick={() => alert('Saving...')}
                />
            }
            {reload &&
                <Reload/>
            }
            {goBack && <BackButton/>}
            {canDelete && action !== 'create' && <DeleteButton/>}
        </Toolbar>
    );
}

export default PostEditDeleteActions;
