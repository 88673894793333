import * as React from 'react';
import {CardContent} from '@material-ui/core';
import {FilterLiveSearch} from 'react-admin';

import {FilterDataAside, FilterDataAside2} from "../_common/CompReact";
// import {FilterList, FilterListItem} from 'react-admin';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
//import TrueFalseFilters from "../_common/TrueFalseFilters";
import {
    commonTrueFalse,
    commonYesNo,
    deletedTrueFalseAll,
    paymentType,
    ticketClass,
    ticketType
} from "../utils/choices";
import DynamicFiltersChoices from "../_common/DynamicFiltersChoices";
import CardCompHide from "../_common/CardCompHide";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardCompHide id="aside_card" resource={resource}>
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch label={'Search'}/>

                <DynamicFiltersChoices
                    label={'Deleted'}
                    reference={deletedTrueFalseAll}
                    fieldsSearch={'is_deleted'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    label={'Canceled'}
                    reference={commonTrueFalse}
                    fieldsSearch={'canceled'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    label={'Is Draft'}
                    reference={commonTrueFalse}
                    fieldsSearch={'draft'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    label={'Is Invoiced'}
                    reference={commonTrueFalse}
                    fieldsSearch={'is_invoiced'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    label={'Exported Xero'}
                    reference={commonYesNo}
                    fieldsSearch={'is_load'}
                    fieldsNameForFilter={'name'}
                />
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                <FilterDataAside2 fields={'booking_date'} name={'booking date'}/>
                <FilterDataAside2 fields={'travel_date'} name={'travel date'}/>
                <DynamicFiltersChoices
                    label={'Payment type'}
                    reference={paymentType}
                    fieldsSearch={'payment_type'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    label={'Ticket type'}
                    reference={ticketType}
                    fieldsSearch={'ticket_type'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    label={'Ticket class'}
                    reference={ticketClass}
                    fieldsSearch={'ticket_class'}
                    fieldsNameForFilter={'name'}
                />
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                {/*<FilterDataAside fields={'created_at'} name={'created'}/>*/}
                {/*<DynamicFiltersChoices
                    reference={commonYesNo}
                    fieldsSearch={'popular'}
                    fieldsNameForFilter={'name'}
                />
                <DynamicFiltersChoices
                    reference={commonYesNo}
                    fieldsSearch={'show'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<DynamicFiltersChoices
                    reference={stateQuestion}
                    fieldsSearch={'state'}
                    fieldsNameForFilter={'name'}
                />*/}
            </CardContent>
        </CardCompHide>
    )
};

export default Aside;
