import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import {downloadCSV} from "react-admin";
//import jsonExport from 'jsonexport/dist';
import moment from "moment";
import {formatSpacerNumber} from "../booking/BookingColumnsForList";
import {saveExcel} from "../_common/utils";
import {apiPathConfig} from "./config";
import {fetchJson as httpClient} from "./keycloak";

export const exporterCustomer = records => {
    let i = 1;
    const postsForExport = records.map(record => ({ //Object.values(records)
        ...record,
        is_company: record.is_company ? 'Yes' : '-',
        full_name: `${record.title ? record.title : ' '}. ${record.f_name ? record.f_name : ' '} ${record.l_name ? record.l_name : ' '}`,
        num: i++,
    }));
    const csv = convertToCSV({
        data: postsForExport,
        // select and order fields in the export
        fields: ['num', 'name', 'is_company', 'full_name', 'email', 'mobile', 'address']
    });
    downloadCSV(csv, 'customer'); // download as 'posts.csv` file
}

/*export const exporter2 = posts => {
    const postsForExport = posts.map(post => {
        const { backlinks, author, ...postForExport } = post; // omit backlinks and author
        postForExport.author_name = post.author.name; // add a field
        return postForExport;
    });
    jsonExport(postsForExport, {
        headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'posts'); // download as 'posts.csv` file
    });
};*/

export const exporterPassenger = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'customer_id', 'customer').then(customer => {
        //console.log('customer===========>', customer)
        let i = 1;
        const data = records.map(record => ({ //Object.values(records)
            ...record,
            bday: record.b_day ? moment(record.b_day).format('YYYY-MM-DD') : '',
            customer: customer[record.customer_id].name ? customer[record.customer_id].name : '',
            num: i++,
            is_main: record.is_main ? 'Yes' : '-'
        }));
        //console.log('data===========>', data)
        const csv = convertToCSV({
            data,
            fields: ['num', 'customer', 'is_main', 'title', 'l_name', 'f_name', 'bday'],
        });
        let name = 'passenger' + moment(new Date()).format('_YYYYMMDD_HH_mm')
        downloadCSV(csv, name);
    });
};

export const exporterBookingPassenger = (records, fetchRelatedRecords) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords(records, 'booking_id', 'booking').then(booking => {
        fetchRelatedRecords(records, 'passenger_id', 'passenger').then(passenger => {
            let i = 1;
            const data = records.map(record => ({
                ...record,
                ticket: `${record.airline_ticket_code ? record.airline_ticket_code : ''} - ${record.ticket_number ? record.ticket_number : ''}`,
                passenger: `${passenger[record.passenger_id].title}. ${passenger[record.passenger_id].f_name} ${passenger[record.passenger_id].l_name}`,
                route: booking[record.booking_id].route,
                num: i++,
            }));
            const csv = convertToCSV({
                data,
                fields: ['num', 'route', 'ticket', 'passenger'],
            });
            let name = 'booking_passenger' + moment(new Date()).format('_YYYYMMDD_HH_mm')
            downloadCSV(csv, name);
            /*return jsonExport(data, {
                headers: ['id', 'passenger', 'ticket_number'],
            }, (err, csv) => {
                downloadCSV(csv, 'booking_passenger');
            });*/
        });
    });
};

export const exporterBooking = (records, fetchRelatedRecords) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords(records, 'issued_airline_id', 'airline').then(airline => {
        //fetchRelatedRecords(records, 'passenger_id', 'passenger').then(passenger => {
        let i = 1;
        let data = [];
        records.map(record => {
            let tt = '';
            let ln = '';
            let fn = '';
            let en = '';
            let pnr = '';
            record.passengers.forEach((pas, index) => {
                en = '';//index < record.passengers.length-1 ? '\n' : '';
                if (index === 0) {
                    tt += pas.title + en;
                    fn += pas.f_name + en;
                    ln += pas.l_name + en;
                }
            })
            record.references.forEach((pas, index) => {
                en = ' ';//index < record.passengers.length - 1 ? '\n' : '';
                pnr += pas.pnr + en;
            })

            let dt = {
                Route: record.route,
                Travel_date: record.travel_date ? moment(record.travel_date).format('YYYY-MM-DD') : '',
                Airline: record.issued_airline_id ? airline[record.issued_airline_id].code : '',
                Title: tt,
                Passenger_Last_Name: ln,
                Passenger_First_Name: fn,
                PNR: pnr,
                Total_USD: formatSpacerNumber(record.sale_total_dollar, ',', 2),
                num: i++,
            }
            data.push(dt);
            return true
        });

        const csv = convertToCSV({
            data,
            fields: ['num', 'Travel_date', 'Airline', 'Route', 'Title', 'Passenger_First_Name', 'Passenger_Last_Name', 'PNR', 'Total_USD'],
        });
        let name = 'booking' + moment(new Date()).format('_YYYYMMDD_HH_mm')
        downloadCSV(csv, name);

        /*return jsonExport(data, {
            headers: ['id', 'passenger', 'ticket_number'],
        }, (err, csv) => {
            downloadCSV(csv, 'booking_passenger');
        });*/
        //});
    });
};
export const exporterBookingXlsx = (records, fetchRelatedRecords) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords(records, 'issued_airline_id', 'airline').then(airline => {
        //fetchRelatedRecords(records, 'passenger_id', 'passenger').then(passenger => {
        let i = 1;
        let data = [];
        records.map(record => {
            let tt = '';
            let ln = '';
            let fn = '';
            let en = '';
            let pnr = '';
            record.passengers.forEach((pas, index) => {
                en = index < record.passengers.length - 1 ? '\u000a' : '';
                // if (index === 0) {
                tt += pas.title + en;
                fn += pas.f_name + en;
                ln += pas.l_name + en;
                // }
            })
            record.references.forEach((pas, index) => {
                // en = index < record.passengers.length - 1 ? ' \r\n' : '';
                en = index < record.references.length - 1 ? '\u000a' : '';
                pnr += pas.pnr + en;
            })

            let dt = [
                i++,
                record.travel_date ? moment(record.travel_date).format('YYYY-MM-DD') : '',
                record.issued_airline_id ? airline[record.issued_airline_id].code : '',
                record.route,
                tt,
                fn,
                ln,
                pnr,
                formatSpacerNumber(record.sale_total_dollar, ',', 2),
            ]
            data.push(dt);
            return true
        });
        let textAlign = ['center', 'center', 'left', 'left', 'left', 'left', 'left', 'left', 'right'];
        let dataHeader = ['№', 'Travel date', 'Airline', 'Route', 'Title', 'Passanger First Name', 'Passanger Last Name', 'PNR', 'Total USD'];
        let name = 'booking' + moment(new Date()).format('_YYYYMMDD_HH_mm')
        saveExcel(dataHeader, textAlign, data, name);
        //});
    });
};
export const setInvoiceFromBooking = (records, fetchRelatedRecords) => {
    // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
    fetchRelatedRecords(records, 'issued_airline_id', 'airline').then(airline => {
        //fetchRelatedRecords(records, 'passenger_id', 'passenger').then(passenger => {
        let data = [];
        let dataInvoice = [];
        let dataCuctomer = [];
        records.map(record => {
            if(dataCuctomer.indexOf(record.customer_details.name) < 0)
                dataCuctomer.push(record.customer_details.name);
            if (record.invoice) dataInvoice.push(record.id);
            else data.push(record.id);
            return true
        });

        const getData = async (data) => {
            const url = apiPathConfig.getApiUrl()
            //let report_url = `booking/admin/currency`;
            let report_url = `api/common/invoice/create-from-booking-ids`;

            try {
                //const response = await httpClient(`${url}${report_url}`);
                const response = await httpClient(`${url}${report_url}`, {
                    method: 'post',
                    body: JSON.stringify(data)
                    /*body: JSON.stringify({
                        b64: base64
                    })*/
                })
                if (response.status >= 200 && response.status <= 300) {
                    const {json: dt} = response
                    if(dt && dt.message) alert(response.json.message)
                    // console.log('response=========>', response);
                } else {

                }
            } catch (err) {
                console.log('err=======>', err);
                alert(`ERROR: ${err}`)
            } finally {
                //console.log('finally=======>', 'finally');
            }
        }
        if (dataInvoice.length > 0)
            alert(`Invoices already exist: ${dataInvoice.join(', ')}`)
        else if(dataCuctomer.length > 1) alert(`Only one customer is needed: ${dataCuctomer.join(', ')}`)
        else getData(data);
    });
};
