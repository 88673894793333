import React from 'react';
import PropTypes from 'prop-types';
//import {makeStyles} from '@material-ui/core/styles';
//import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Profit from "./profit";
import Receivable from "./receivable";
import TravelCredit from "./travelCredit";
import Invoices from "./invoices";
import {canAccess2} from "../utils/canAccess2";
import Alert from '@material-ui/lab/Alert';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/*const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));*/

export default function ReportTabs(props) {
    //const classes = useStyles();
    const {type} = props;
    let canAccess = canAccess2({resource: 'dashboard', action: 'list'})
    const [value, setValue] = React.useState(canAccess ? 0 : 1);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    /*return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Profit" {...a11yProps(0)} />
                    <Tab label="Receivable" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Profit />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Receivable />
            </TabPanel>
        </div>
    );*/

    if (type === 'travel_credit_report')
        return (
            <Box sx={{width: '100%'}}
                 style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, backgroundColor: "white", padding:16}}>
                <TravelCredit/>
            </Box>
        );
    else
        return (
            <Box sx={{width: '100%'}}
                 style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, zIndex: 2, backgroundColor: "white"}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}
                     style={{backgroundColor: '#efefef', borderRadius: '4px 4px 0 0 '}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Receivable" {...a11yProps(0)} />
                        <Tab label="Profit" {...a11yProps(1)} />
                        <Tab label="Invoices" {...a11yProps(2)} />
                        {/*<Tab label="Travel credit" {...a11yProps(3)} />*/}
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    <Receivable/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {canAccess
                        ? <Profit/>
                        : <Alert severity='warning'>Access only with administrator rights (group "Admins")</Alert>
                    }
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Invoices/>
                </TabPanel>
                {/*<TabPanel value={value} index={3}>
                    <TravelCredit/>
                </TabPanel>*/}
            </Box>
        );
}