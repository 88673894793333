import * as React from 'react';
import {
    //NumberField,
    TabbedForm,
    FormTab,
    TextInput,
    // useLocale,
    //NumberInput,
    //BooleanInput,
    SelectInput,
    RadioButtonGroupInput, FormDataConsumer,
    TextField, NumberField, useRedirect, BooleanField,
} from 'react-admin';

import {Box, Button, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, TextFieldBold, TextFieldButton} from "../_common/CompReact";
import {isCompany, titleMrMs} from "../utils/choices";
import {validateEmail, validateReq} from "../utils/validateTable";
import get from "lodash/get";
import useMyStyles from "../utils/useMyStyles";
import ListComponentAdmin from "../_common/ListComponentAdmin";
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import HistoryListForFilter from "../_common/HistoryListForFilter";
//import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
// import {localesConfig} from "../utils/config";
//import {BasicTabs} from "../_common/BaseTab";


export const CustomerEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const classes = useMyStyles();
    const {record, bookingId} = props;
    const {id} = record;
    const redirect = useRedirect()
    // const locale = useLocale();
    console.log('bookingId=============>', bookingId)
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Name</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="name"
                                // source2="l_name"
                                // source3="f_name"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Created: `}
                                <DateFieldComp source="created_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Updated: `}
                                <DateFieldComp source="updated_at" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={true}/>}
                //redirect={redirect_url}
            >
                {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
                {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
                <FormTab label="Common">
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            let is_company = get(formData, 'is_company', null)
                            return (
                                <Box p="1em" fullWidth>
                                    <Box display="flex" alignItems={"center"}>
                                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                            {/*<BooleanInput source={'is_company'} label={'Is company'}
                                                          defaultValue={false}/>*/}
                                            <RadioButtonGroupInput  source="is_company" choices={isCompany} defaultValue={false} label={' '}/>
                                        </Box>
                                        <Box flex={1}>
                                        </Box>
                                    </Box>

                                    {is_company
                                        ?
                                        /*<Box display="flex" alignItems={"center"}>
                                            <Box flex={1}>
                                                <TextInput source="name" label={'Name'} validate={validateReq}
                                                           fullWidth/>
                                            </Box>
                                        </Box>*/
                                        <Box display="flex" alignItems={"center"}>
                                            <Box flex={1} mr={'2em'}>
                                                <TextInput source="name" label={'Name'} validate={validateReq}
                                                           fullWidth/>
                                                {/*<DateInputComp source={"b_day"} label={"Birthday"} fullWidth/>*/}
                                            </Box>
                                            <Box flex={1} mr={'2em'}>
                                                <SelectInput source="title" choices={titleMrMs} fullWidth/>
                                            </Box>
                                            <Box flex={1} mr={'2em'}>
                                                <TextInput source="f_name" label={'First Name'}
                                                           fullWidth/>
                                            </Box>
                                            <Box flex={1}>
                                                <TextInput source="l_name" label={'Last Name'}
                                                           fullWidth/>
                                            </Box>
                                        </Box>
                                        :
                                        <Box display="flex" alignItems={"center"}>
                                            <Box flex={1} mr={'2em'}>
                                                <TextInput source="name" label={'Name'}
                                                           fullWidth/>
                                                {/*<DateInputComp source={"b_day"} label={"Birthday"} fullWidth/>*/}
                                            </Box>
                                            <Box flex={1} mr={'2em'}>
                                                <SelectInput source="title" choices={titleMrMs} validate={validateReq} fullWidth/>
                                            </Box>
                                            <Box flex={1} mr={'2em'}>
                                                <TextInput source="f_name" label={'First Name'} validate={validateReq}
                                                           fullWidth/>
                                            </Box>
                                            <Box flex={1}>
                                                <TextInput source="l_name" label={'Last Name'} validate={validateReq}
                                                           fullWidth/>
                                            </Box>
                                        </Box>
                                    }

                                    <Box display="flex" alignItems={"center"}>
                                        <Box flex={2} style={{display: "flex"}} mr={'2em'}>
                                            <TextInput source="email" label={'Email'} validate={validateEmail}
                                                       fullWidth/>
                                        </Box>
                                        <Box flex={2}>
                                            <TextInput source="mobile" label={'Mobile'} fullWidth/>
                                        </Box>
                                    </Box>

                                    <Box display="flex" alignItems={"center"}>
                                        <Box flex={1} mr={'2em'}>
                                            <TextInput source="country" label={'Country'} fullWidth/>
                                        </Box>
                                        <Box flex={1} mr={'2em'}>
                                            <TextInput source="region" label={'Region'} fullWidth/>
                                        </Box>
                                        <Box flex={1} mr={'2em'}>
                                            <TextInput source="postal_code" label={'Postal Code'} fullWidth/>
                                        </Box>
                                        <Box flex={1}>
                                            <TextInput source="city" label={'City'} fullWidth/>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems={"center"}>
                                        <Box flex={1}>
                                            <TextInput source="address" label={'Address'} fullWidth/>
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems={"center"}>
                                        <Box flex={1}>
                                            <TextInput source="address2" label={'Address 2'} fullWidth/>
                                        </Box>
                                    </Box>

                                    {id &&
                                        <>
                                            <Typography variant="h6" gutterBottom className={classes.booking_title}>
                                                Passengers
                                            </Typography>
                                            <Button
                                                onClick={() => {redirect(`/passenger/create/?customerId=${id}`)}}
                                                color="primary"
                                                label={'Create New'}
                                            >
                                                {`Create New Passenger `}
                                            </Button>
                                            <Box display="flex" alignItems={"center"}>
                                                <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                                                    <ListComponentAdmin
                                                        {...props}
                                                        resource={'passenger'}
                                                        style={{width: 'max-content'}}
                                                        actions={false}
                                                        filter={{customer_id: id}}
                                                        label={' '}
                                                        is_bulkActionButtons={true}
                                                        sort={{field: 'id', order: 'DESC'}}
                                                    >
                                                        <DatagridComponentAdmin
                                                            {...props}
                                                            //rowClick="edit"
                                                            rowClick={(id) => redirect(`/passenger/${id}/?customerId=${id}`)}>
                                                        >
                                                            <NumberField source="id"
                                                                         headerClassName={classes.headerShortWidth}/>
                                                            <TextField source={`title`} label={'Title'}/>
                                                            <BooleanField source="is_main" label={'Is main'}/>
                                                            <TextFieldBold source={'f_name'} label={'First name'}/>/>
                                                            <TextFieldBold source={'l_name'} label={'Last name'}/>
                                                            <DateFieldComp source="b_day" label={'Birthday'}
                                                                           textAlign={'center'}
                                                                           cellClassName={classes.date_st}/>
                                                        </DatagridComponentAdmin>
                                                    </ListComponentAdmin>
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                </Box>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>
                {id && !bookingId &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'customer'} fullWidth/>
                    </FormTab>
                }
            </TabbedForm>
        </>
    )
};

export default CustomerEditCreate
