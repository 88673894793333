import * as React from 'react';

import {
    List,
} from 'react-admin';

import {canAccess, usePermissions} from "@react-admin/ra-rbac";
import PostPagination from "./PostPagination";
import PostBulkActionButtons from "./PostBulkActionButtons";

const ListComponentAdmin = (props) => {
    const {
        handlerForCheckBox = false,
        handlerForCheckBox2 = false,
        handlerForCheckBox3 = false,
        exporterlabel = false,
        exporterFile = false,
        children,
        resource,
        is_bulkActionButtons = true,
        pagination = true
    } = props;
    const {loading, permissions} = usePermissions();
    if (loading) return null;
    const canDelete =
        canAccess({permissions, resource: `${resource}`, action: 'delete'});
    return (
        <List {...props}
              //perPage={50}
              empty={false}
              pagination={pagination ? <PostPagination/> : false}
              bulkActionButtons={
                  canDelete && is_bulkActionButtons &&
                  <PostBulkActionButtons
                      exporterlabel={exporterlabel}
                      exporterFile={exporterFile}
                      handlerForCheckBox={handlerForCheckBox}
                      handlerForCheckBox2={handlerForCheckBox2}
                      handlerForCheckBox3={handlerForCheckBox3}
                  />
              }
        >
            {children}
        </List>
    )
};
export default ListComponentAdmin;
