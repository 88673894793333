import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {RefundList} from "./RefundList";
import {RefundEdit} from "./RefundEdit";
import {RefundCreate} from "./RefundCreate";

const resource = {
    list: RefundList,
    create: RefundCreate,
    edit: RefundEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;