//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
//import React, {Fragment} from 'react';
import {
    Datagrid,
    ReferenceManyField,
    SimpleList,
    TextField,
    useLocale,
    SimpleShowLayout,
} from 'react-admin';
import {Box, Grid} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';
import BookingListAside from "./BookingListAside";

import '../index.css';
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
import {BookingColumnsForList} from "./BookingColumnsForList";
import {BookingTopFilters} from "./BookingTopFilters";
import {exporterBookingXlsx} from "../utils/exporterFile";
import {DateFieldComp} from "../_common/CompReact";
import PostBulkActionButtons from "../_common/PostBulkActionButtons";
import BulkHandlerCreateInvoice from "../utils/BulkHandlerCreateInvoice";
import HandlerCreateCsvInvoice from "../utils/HandlerCreateCsvInvoice";

/*
https://stackoverflow.com/questions/56667689/how-to-make-react-admin-default-theme-rtl
import { create } from 'jss';
import rtl from 'jss-rtl';
//import JssProvider from 'react-jss/lib/JssProvider';
import { jssPreset } from '@material-ui/core/styles';
import {JssProvider} from "react-jss";
*/

/*const useImageFieldStyles = makeStyles(theme => ({
    image: {
        width: 50,
        height: 50,
    }
}));
//import {makeStyles} from '@material-ui/core/styles';*/
const PostShow = (props) => {
    const classes = useMyStyles();
    const {record, columns} = props;
    const {transactions} = record || {};
    return (
        <SimpleShowLayout>
            <Grid container style={{width: '100%', padding: "0 0 0 61px"}}>
                {/*<Grid container style={{width: '100%', padding: "0 0 0 8px"}}>*/}
                {/*{travel_credits && travel_credits.length > 0 &&
                    <Grid item style={{width: 350, marginRight: 24, marginBottom: 24}}>
                        <h4 style={{margin: '4px 0'}}>Travel Credit</h4>
                        <ReferenceManyField label="Books" reference="travelCredit" target="booking_id">
                            <Datagrid rowClick={(id) => `/travelCredit/${id}/`}>
                                <DateFieldComp source="expire_date" label={'Expire date'} textAlign={"center"}
                                               cellClassName={classes.date_st} showTime={false} locale={'us'}/>
                                <FloatNumber source={'amount_dollar'} label={'Amount ($)'} textAlign={"right"}/>
                                <TextField source={'is_used'} label={'Is used'}/>
                            </Datagrid>
                        </ReferenceManyField>
                    </Grid>
                }*/}
                {transactions && transactions.length > 0 &&
                    <Grid item>
                        <h4 style={{margin: '4px 0'}}>Changing / Refund</h4>
                        <ReferenceManyField
                            label="Books"
                            reference="booking"
                            target="transaction_id"
                            bulkActionButtons={<PostBulkActionButtons/>}
                            // sort={{field: 'id', order: 'DESC'}}
                            sort={{field: 'booking_date', order: 'DESC'}}
                        >
                            {/*<Datagrid rowClick={(id) => `/booking/${id}/`} hasBulkActions>*/}
                            <Datagrid
                                rowClick={(id) => `/booking/${id}/`}
                                classes={{
                                    headerCell: classes.headerCellPostShow,
                                    row: classes.row,
                                    rowCell: classes.rowCell
                                }}
                            >
                                {/*<TextFieldBold source="transaction_type"/>*/}
                                {columns}
                            </Datagrid>
                        </ReferenceManyField>
                    </Grid>
                }
                {/*{transactions && transactions.length > 0 &&
                    <Grid item style={{width: 512}}>
                        <h4 style={{margin: '4px 0'}}>Transactions</h4>
                        <ListComponentAdmin
                            {...props}
                            //style={{width: 'max-content'}}
                            title={' '}
                            actions={false}
                            //is_bulkActionButtons={true}
                            //filters={tableFilters}
                            filter={{transaction_id: recordId}}
                            sort={{field: 'id', order: 'DESC'}}
                            pagination={false}
                        >
                            <Datagrid rowClick={(id) => `/booking/${id}/`} hasBulkActions>
                                {columns}
                            </Datagrid>
                        </ListComponentAdmin>
                    </Grid>
                }*/}
                {/*{refunds && refunds.length > 0 &&
                    <Grid item style={{width: 512}}>
                        <h4 style={{margin: '4px 0'}}>Refund</h4>
                        <ReferenceManyField label="Books" reference="refund" target="booking_id">
                            <Datagrid rowClick={(id) => `/refund/${id}/`}>
                                <DateFieldComp source="transaction_date" label={'Transaction Date'} textAlign={"center"}
                                               cellClassName={classes.date_st} showTime={false} locale={'us'}/>
                                <div style={{display: "grid", textAlign: "right"}} label={'Amount sale ($)'}>
                                    <div><FloatNumber source={'amount_dollar'}/></div>
                                </div>
                                <FloatNumber source={'amount_cost_dollar'} label={'Amount cost ($)'} textAlign={"right"}/>
                                <div style={{display: "grid", textAlign: "right"}} label={'Tax ($)'}>
                                    <div><FloatNumber source={'tax_dollar'}/></div>
                                </div>
                            </Datagrid>
                        </ReferenceManyField>
                    </Grid>
                }*/}
            </Grid>
        </SimpleShowLayout>
    );
}


export const BookingList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;
    const locale = useLocale();

    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_deleted
            ? 'lightcoral'
            : record?.is_paid && '#efe',
        // backgroundColor: record?.is_paid && '#efe',
        opacity: !record?.is_deleted && record?.draft ? 0.5 : 1,
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: BookingColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    localStorage.removeItem('fdsBookingForm')
    //console.log('==============>', props)
    /*const [body] = document.getElementsByTagName('body');
    body.setAttribute('dir', 'rtl');
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });*/

    return (
        /*<JssProvider jss={jss}>*/
        /*<div style={{margin: "16px 0 -16px 0"}}>
            {allAirlines.map((it, index) => {
                return (
                    <Button
                        //variant={"outlined"}
                        //variant={airline === it.id ? 'contained' : "outlined"}
                        style={{
                            zIndex: 99,
                            marginRight: '4px',
                            color: "#3f51b5",
                            minWidth: it.id > 0 && 40,
                            maxWidth: it.id > 0 && 40
                        }}
                        variant={airline === it.id ? 'outlined' : false}
                        size="small"
                        color="primary"
                        onClick={(event) => {
                            localStorage.setItem('fdsBookingFilterAirline', it.id);
                            setAirline(it.id)
                        }}
                        title={it.title}
                    >
                        {it.code}
                    </Button>
                )
            })}
        </div>*/
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={' '}
            perPage={50}
            exporterFile={exporterBookingXlsx}
            exporterlabel={'Invoice export'}
            handlerForCheckBox={<BulkHandlerCreateInvoice label={'Create Invoice'}/>}
            handlerForCheckBox2={<HandlerCreateCsvInvoice label={'Export to Xero selected'} table={'booking'}
                                                          type={'bill'}/>}
            handlerForCheckBox3={<HandlerCreateCsvInvoice label={'Export Refund selected'} table={'booking'}
                                                          type={'refund'}/>}
            actions={<TableActions
                showAsside={false}
                create={true}
                create2={'/booking/create?type_flight=non_flight'}
                create2Label={'Create Non Flight'}
                preference={`${resource}list.columns`}
                columns={BookingColumnsForList(resource, locale, classes)}
                permissions={permissions}
                asideFilter={true}
                // filter={false}

                //exportCsv={true}
                exporterFile={exporterBookingXlsx}
                exporterlabel={'Invoice export'}
                //Export to Xero unexported
                handlerForAllTable={<HandlerCreateCsvInvoice label={'Export to Xero unexported'} table={'booking'}
                                                             type={'bill'}/>}
                handlerForAllTable2={<HandlerCreateCsvInvoice label={'Export Refund unexported'} table={'booking'}
                                                              type={'refund'}/>}
            />}
            filters={BookingTopFilters(resource, locale)}
            //filter={airline ? {issued_airline_id: airline} : false}
            //filterDefaultValues={{viewed: true}}
            // sort={{field: 'id', order: 'DESC'}}
            sort={{field: 'booking_date', order: 'DESC'}}
            aside={<BookingListAside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin
                    {...props} rowClick="edit"
                    rowStyle={postRowStyle}
                    expand={<PostShow columns={columns}/>}
                    isRowExpandable={row => (
                        //|| (row.travel_credits && row.travel_credits.length > 0)
                        (row && row.transactions && row.transactions.length > 0)
                    )}
                >
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                    <DateFieldComp source="created_at" label={'Created'} cellClassName={classes.date_st}/>
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}