import * as React from 'react';
import {
    useRedirect,
} from 'react-admin';

import EditComponentAdmin from "../_common/EditComponentAdmin";
import PassengerEditCreate from "./PassengerEditCreate";

export const PassengerEdit = props => {
    const {location} = props;
    const {search} = location;
    let customerId = '';
    if(search){
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
    }
    const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            //onSuccess={() => props.history.goBack()}
            onSuccess={() => customerId ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <PassengerEditCreate customerId={customerId}/>
        </EditComponentAdmin>
    )
};