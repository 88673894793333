import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from '@material-ui/core/TextField';
import Asynchronous from "./AsynchronousRequests";


const useStyles = makeStyles({
    // table: {
    //     minWidth: 100,
    // },
});


const DateTimeFilter = ({value, setValue, label= '---'}) => {
    const handleDateChange = (event) => {
        // debugger
        setValue(event.target.value);
    };
    // debugger
    return (<TextField
        fullWidth={true}
        // style={{padding: "0px"}}
        id="datetime-local"
        label={label}
        size={"small"}
        variant="outlined"
        type="datetime-local"
        defaultValue="2017-05-24T10:30"
        value={value} onChange={handleDateChange}
        InputLabelProps={{
            shrink: true,
        }}
    />)
}


function Filter(props) {
    const classes = useStyles();
    const {filterState, setFilterState, filters, table_columns=[]} = props
    // const filters = {
    //     booking: ['Query', '/admin/booking'],
    //     customer: ['Query', '/admin/customer'],
    //     date_from: ['DateTime',],
    //     date_to: ['DateTime',],
    // }
    // const [filterState, setFilterState] = React.useState({
    //     'booking': {use: true, value: '12'},
    //     'customer': {use: true, value: '12'},
    //     'date_from': {use: false, value: <X/>, compare: '>='},
    //     'date_to': {use: true, value: '2023-01-01', compare: '>='},
    // })
    // const [state, setState] = React.useState({})

    const getViewFilterComponent = (filter, table_columns, value, setValue) => {
        const label = table_columns.find(x => x.field === filter) ? table_columns.find(x => x.field === filter).title : filter;
        const filterParams = filters[filter]
        if (filterParams[0] === 'DateTime') return <DateTimeFilter value={value} setValue={setValue} label={label}/>

        if (filterParams[0] === 'Query') return <Asynchronous value={value} setValue={setValue} url={filterParams[2]}
                                                              getOptionLabel={filterParams[3]} label={label}/>

        return (
            <TextField
                fullWidth={true}
                label={label}
                size={"small"}
                variant="outlined"
                value={value}
                onChange={(event) => setValue(event.target.value)}
            />
        )
    }


    const handleChange = (event) => {
        setFilterState({
            ...filterState,
            [event.target.name]: {...filterState[event.target.name], use: event.target.checked}
        });
    };

    const handleChangeFilterValue = (name, v) => {
        setFilterState({
            ...filterState,
            [name]: {...filterState[name], value: v}
        });
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table" style={{width: 380}}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: 24}}>Use</TableCell>
                        {/*<TableCell>Field</TableCell>*/}
                        <TableCell align={'center'}>Filter</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(filterState).filter(i => filters[i] !== undefined).map(i => (<TableRow key={i}>
                        <TableCell>
                            <Checkbox
                                style={{'padding': "0px"}}
                                checked={filterState[i]['use']} onChange={handleChange} name={i}/>
                        </TableCell>
                        {/*<TableCell>{i}</TableCell>*/}
                        {/*{filterState[i].compare && <TableCell>{filterState[i].compare}</TableCell>}*/}
                        <TableCell
                            style={{padding: "0 16px"}}
                        >
                            {getViewFilterComponent(i, table_columns, filterState[i].value, (value) => handleChangeFilterValue(i, value))}
                        </TableCell>
                    </TableRow>))}
                    {/*<TableRow key={'row.desc'}>*/}
                    {/*    <TableCell><Checkbox checked={state['one']} onChange={handleChange} name={'one'}/></TableCell>*/}
                    {/*    <TableCell>booking</TableCell>*/}
                    {/*    <TableCell colSpan={2}>booking1</TableCell>*/}
                    {/*</TableRow>*/}

                    {/*<TableRow>*/}
                    {/*    <TableCell>use</TableCell>*/}
                    {/*    <TableCell>data_from</TableCell>*/}
                    {/*    <TableCell>{">, >="}</TableCell>*/}
                    {/*    <TableCell>2021-01-01</TableCell>*/}
                    {/*</TableRow>*/}
                    {/*<TableRow>*/}
                    {/*    <TableCell>use</TableCell>*/}
                    {/*    <TableCell>data_to</TableCell>*/}
                    {/*    <TableCell>{"<, <="}</TableCell>*/}
                    {/*    <TableCell>2023-01-01</TableCell>*/}
                    {/*</TableRow>*/}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default Filter
