import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {TravelCreditList} from "./TravelCreditList";
import {TravelCreditEdit} from "./TravelCreditEdit";
import {TravelCreditCreate} from "./TravelCreditCreate";

const resource = {
    list: TravelCreditList,
    create: TravelCreditCreate,
    edit: TravelCreditEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;