import * as React from 'react';
import {
    TextInput, TextField, ReferenceManyField,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";
// import {Box} from "@material-ui/core";

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../utils/useMyStyles";
import {TextFieldBold} from "../_common/CompReact";
import {validateFirstName, validateFix3} from "../utils/validateTable";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    /*const {getValues} = useFormContext();
    console.log(booking_id, '=====OK======>', getValues("id"))*/
    return (
        <RowForm  {...props}>
            <TextInput source={`title`} label={'title'} validate={validateFirstName} fullWidth/>
            <TextInput source={`code`} label={'code'} validate={validateFirstName} fullWidth/>
            <TextInput source={`ticket_code`} label={'Ticket Code'} validate={validateFix3} fullWidth/>
        </RowForm>
    )
};

const AirlineBooking = props => {
    const {booking_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()

    return (
        <div style={{width: "max-content", minWidth: 500, marginTop:16}}>
            <ReferenceManyField
                fullWidth
                label=" "
                reference="new_airline"
                target="title"
            >
                <EditableDatagrid
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} booking_id={booking_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <TextFieldBold source={`title`} label={'title'}/>
                    <TextField source={`code`} label={'code'}/>
                    <TextField source={`ticket_code`} label={'Ticket Code'}/>
                </EditableDatagrid>
            </ReferenceManyField>
        </div>
    )
}
export default AirlineBooking;