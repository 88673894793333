import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {BookingList} from "./BookingList";
import {BookingEdit} from "./BookingEdit";
import {BookingCreate} from "./BookingCreate";

const resource = {
    list: BookingList,
    create: BookingCreate,
    edit: BookingEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;