import {useRecordContext, useUpdate} from "react-admin";
import * as React from "react";
import AddCommentIcon from "@material-ui/icons/AddComment";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import CroppedLongField from "./CroppedLongField";
import {ClickAwayListener} from "@material-ui/core";
import {inputFormatStr} from "./utils";
import {empty} from "ramda";

export const EditCommentFieldFromList = (props) => {
    const record = useRecordContext(props);
    const {source, resource, component = 'text', long = '200px'} = props;
    const [update, {loading, error}] = useUpdate();
    //console.log('=============>', long);
    const {id} = record;
    const [showInput, setShowInput] = React.useState(false);
    const [value, setValue] = React.useState('');

    const handleOpenCommentInput = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowInput(true)
        setValue(record[source])
    }
    const handle = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setShowInput(false)
        if (record[source] !== value) {
            const data = {}
            data[source] = value
            update(resource, id, data, record)
        }
    }

    const compTextArea = () => {
        switch (component) {
            case 'textarea':
                return (
                    <textarea
                        //type={'numeric'}
                        rows={4}
                        onBlur={handle}
                        onChange={event => setValue(event.target.value)}
                        // defaultValue={value}
                        value={value}
                        style={{
                            width: long,
                            position: "absolute",
                            marginLeft: "-8px",
                            border: "1px solid #aaa",
                            borderRadius: "4px",
                            zIndex: 100,
                            // outline: 'none',
	                        // '-moz-appearance': 'none',
                        }}
                        // align={'center'}
                        // multiline={true}
                        //label={'Comment'}
                        // variant="filled"
                        // hiddenLabel={true}
                    />)
            default:
                return (
                    <input
                        type="text"
                        onBlur={handle}
                        onChange={event => setValue(inputFormatStr(event.target.value, component,2))}
                        // defaultValue={value}
                        value={value}
                        style={{
                            width: long,
                            height: "24px",
                            position: "absolute",
                            marginLeft: "-100px", //"-8px",
                            marginTop: 22,
                            border: "1px solid #aaa",
                            borderRadius: "4px",
                            zIndex: 100,
                        }}
                    />)
        }
    }

    return (
        <div
            style={{cursor: "pointer", textAlign: "right"}}
            toolbar={<PostEditDeleteActions action={'withoutButtons'}/>}
        >
            {loading
                ? <span style={{color: "green", fontWeight: "bold"}}>saving...</span>
                :  empty(record[source])
                    ? <AddCommentIcon onClick={() => setShowInput(true)} style={{maxWidth: '32px'}}/>
                    : component === 'textarea'
                        ? <CroppedLongField onClick={handleOpenCommentInput} source={source} long={15} style={{color: error && "red"}}/>
                        : <span onClick={handleOpenCommentInput} style={{color: error && "red"}}>
                            {record[source] ? record[source] : '---'}</span>
            }
            {showInput &&
                <ClickAwayListener onClickAway={() => {
                    setShowInput(false)
                }}>
                    {compTextArea()}
                </ClickAwayListener>
            }
        </div>
    )
}