import * as React from 'react';
import {
    Create, useNotify, useRedirect, useRefresh, SimpleForm
} from 'react-admin';

import BookingEditCreate from "./BookingEditCreate";
import PostEditActions from "../_common/PostEditActions";
import {transformBooking} from "./BookingEdit";

export const BookingCreate = props => {
    const {location, resource} = props;
    const {search, pathname} = location;
    let customerId = '';
    let type_flight = '';
    //let supplierId = '';
    if (search) {
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
        type_flight = url.searchParams.get('type_flight')
        //supplierId = url.searchParams.get('supplierId')
    }

    const redirect = useRedirect()
    const notify = useNotify();
    const refresh = useRefresh();
    const onError = (error) => {
        console.log('Error======>', error)
        notify(`Could not edit post: ${error.message}`);
        redirect(pathname);
        refresh();
    };
    const onSuccess = (data) => {
        // console.log(data.data.id,'data======>', data)
        // console.log(data.data.id,'url======>', `/${resource}/${data.data.id}/1`)
        notify(`Changes saved`);
        redirect(`/${resource}/${data.data.id}`);
    };

    return (
        <Create {...props}
            title={' '}
            transform={transformBooking}
            mutationOptions={{ onError }}
            onSuccess={onSuccess}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            // onSuccess={() => props.history.goBack()}
            actions={
                <PostEditActions/>
            }
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <SimpleForm>
                <BookingEditCreate customerId={customerId} type_flight={type_flight}/>
            </SimpleForm>
        </Create>
    )
};