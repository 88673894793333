//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    SimpleList,
    TextField,
    useLocale,
    //EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../_common/ListComponentAdmin";
import TableActions from "../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';
import SupplierDataListAside from "./ProgramsListAside";

import '../index.css';
import DatagridComponentAdmin from "../_common/DatagridComponentAdmin";
import useMyStyles from "../utils/useMyStyles";
import {ProgramsTopFilters} from './ProgramsTopFilters'
import {ProgramsColumnsForList} from "./ProgramsColumnsForList";

export const ProgramsList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: '#fefefe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ProgramsColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={' '}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ProgramsColumnsForList(resource, locale, classes)}
                permissions={permissions}
                asideFilter={true}
                // filter={false}
            />}
            filters={ProgramsTopFilters(resource, locale)}
            // filterDefaultValues={{is_miles: true}}
            //filter={{is_miles: false}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<SupplierDataListAside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props}  rowClick="edit">
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}