import * as React from "react";
import { useRecordContext } from 'react-admin';

const CroppedLongField = (props) => {
    const { source, long } = props;
    const record = useRecordContext(props);
    const value = record[source] !== null ? record[source].slice(0, long) : null
    return (
        <span {...props} title={record[source]}>{value}{record[source] !== value && '...'}</span>
    );
}

export default CroppedLongField;