import * as React from 'react';
import {
    NumberField,
    TabbedForm,
    FormTab,
    TextInput,
    // useLocale,
    NumberInput,
    BooleanInput,
} from 'react-admin';
/*import {
    TextInput,
    SimpleForm,
} from 'react-admin';*/
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../_common/PostEditDeleteActions";
import {DateFieldComp, DateInputComp, NumberInputFloatComp, TextFieldButton} from "../_common/CompReact";
//import {validateSumaRec} from "../utils/validateTable";
// import {localesConfig} from "../utils/config";
//import {BasicTabs} from "../_common/BaseTab";

export const UniversalEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const {record, idSource} = props;
    const {id} = record;
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)
    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="0.5em">
                            <Typography variant="subtitle2" gutterBottom>Customer</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="customer"
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{`Booking date: `}
                                <DateFieldComp source="booking_date" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                            <div>{`Travel date: `}
                                <DateFieldComp source="travel_date" showTime={false} variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            } <TabbedForm
            {...props}
            toolbar={<PostEditDeleteActions goBack={idSource ? true : false}/>}
            //redirect={redirect_url}
        >
            {/*<SimpleForm {...props} warnWhenUnsavedChanges>*/}
            {/*<FormTab label="FLIGHT TICKET / CHANGE">*/}
            <FormTab label="Booking">
                <Box p="1em" fullWidth>

                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="h5" gutterBottom>ID: </Typography>
                            <NumberField source="id" variant="h5"/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <BooleanInput source="is_paid" label={'Is paid'} fullWidth/>
                        </Box>
                        <Box flex={2}>
                            <TextInput source="customer" label={'customer'} fullWidth/>
                        </Box>
                    </Box>

                    <Typography variant="h6" gutterBottom>Booking</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} mr={'2em'}>
                            <DateInputComp source={"booking_date"} label={"booking date"} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="ccy" label={'CURRENCY'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="airline" label={'airline'} fullWidth/>
                        </Box>
                        <Box flex={1}></Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <NumberInputFloatComp source="cost" label={'cost'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <NumberInputFloatComp source="price" label={'price'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <NumberInput source="qty" label={'qty'} fullWidth/>
                        </Box>
                        <Box flex={1}>
                            <NumberInputFloatComp source="taxes" label={'taxes'} fullWidth/>
                        </Box>
                    </Box>
                </Box>
            </FormTab>
            <FormTab label="Passenger">
                <Box p="1em" fullWidth>
                    <Typography variant="h6" gutterBottom>Passenger</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <TextInput source="title" label={'title'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="passanger_name" label={'Passenger name'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="passanger_first_name_initial" label={'Passenger first name'} fullWidth/>
                        </Box>
                        <Box flex={1}/>
                    </Box>

                </Box>
            </FormTab>
            <FormTab label="Flight ticket">
                <Box p="1em" fullWidth>
                    <Typography variant="h6" gutterBottom>Flight ticket</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <TextInput source="booking_ref" label={'PNR / Booking Ref'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="ticket_number" label={'ticket number'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="travel_route" label={'travel route'} fullWidth/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source="single_rtn" label={'Single / Rtn'} fullWidth/>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <TextInput source="airline_find" label={'airline'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="flight_number" label={'flight number'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="class" label={'class'} fullWidth/>
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="miles_only" label={'miles only'} fullWidth/>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} mr={'2em'}>
                            <DateInputComp source={"travel_date"} label={"travel date"} fullWidth/>
                        </Box>
                        <Box flex={3}>
                            <TextInput source="notes_ticket" label={'notes ticket'} fullWidth/>
                        </Box>
                    </Box>
                </Box>
            </FormTab>
            <FormTab label="Programm">
                <Box p="1em" fullWidth>
                    <Typography variant="h6" gutterBottom>Flight ticket</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <TextInput source="programName" label={'program name'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="account_last_name" label={'account last name'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="account_first_name" label={'account first name'} fullWidth/>
                        </Box>
                        <Box flex={1}>
                            <NumberInput source="account_number" label={'account number'} fullWidth/>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <TextInput source="password" label={'password'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="dob" label={'dob'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="phone" label={'phone'} fullWidth/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source="cvc" label={'cvc'} fullWidth/>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="exp" label={'exp'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="email" label={'email'} fullWidth/>
                        </Box>
                        <Box flex={1} mr={'2em'}>
                            <TextInput source="email_pasw" label={'email password'} fullWidth/>
                        </Box>
                        <Box flex={1}>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1}>
                            <TextInput source="notes_program" label={'notes program'} multiline={true} fullWidth/>
                        </Box>
                    </Box>

                </Box>
            </FormTab>
        </TabbedForm>
        </>
    )
};

export default UniversalEditCreate
