import Excel from 'exceljs';
import {saveAs} from 'file-saver';
import moment from "moment";
import {formatSpacerNumber} from "../booking/BookingColumnsForList";
import get from "lodash/get";

export const inputHrefToString = (value, bk = false) => {
    let valueOri = value;
    /*let arHref = [];
    let valueMod = value.replace(/\s+|[[:^print:]]/gm, " "); //Убирает непечатные символы
    let arValue = valueMod.split(' ');
    arValue.map((it) => {
        if (it.indexOf('http') === 0 && arHref.indexOf(it)<0)
        {
            arHref.push(it);
            valueOri = valueOri.replaceAll(it, '<a target="_blank" style="color: #6870FF" href="' + it + '"  rel="noreferrer">' + it + '</a> ');
        }
    });
    if(bk) valueOri = valueOri.replaceAll("\n", '<br/>');*/

    /*let res = [];
    let test = str.replace(/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim, (m, link, text) => {
        res.push(link ? <a href={(link[0] === "w" ? "//" : "") + link} key={res.length}>{link}</a> : text)
    });
    console.log('====>', res);*/
    if (valueOri) {
        valueOri = valueOri.replace(/\b(http?\S+|[[:^print:]])/mg, '<a target="_blank"  href="$1">$1</a>');
        if (bk) valueOri = valueOri.replaceAll("\n", '<br/>');
    } else valueOri = '';
    return valueOri;
};

export const formatSpacerNumber2 = (str, delim = ',') => {//delim = '\u00a0'
    let val = str >= 1000
        ? str.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delim)
        : str;
    val = '$\u00a0' + val;
    return val;

};

export const inputFormatStr = (str, type, fixTo = 2) => {
    // console.log('str, type===>', str, type);
    let result = '';
    switch (type) {
        case 'del_space_all':
            result = str.replaceAll(' ', '');
            break;
        case 'del_space':
            result = str.replace(/^\s+/, '');
            break;
        case 'float':
            //.replace(/^0+/, ''); --- убивает нули в начале
            //.replace(/\b(0(?!\b))+/g, "") --- убивает нули в начале кроме одного ???
            //.replace(/\.(?=.*\.)/ig, '')--- убивает двойные точки
            if (str === '0') {
                result = '0';
            } else {
                result = str.replace(/[^\d,.]/ig, '').replace(',', '.').replace(/\.(?=.*\.)/ig, '').replace(/^0+/, '');
                if (result[0] === '.') result = '0' + result;
                if (result.indexOf('.') < 0) result = result.substring(0, result.length);
                else result = result.substring(0, result.indexOf('.') + (fixTo + 1));
                if (result.length > 13) {
                    let ttt = result.split('.');
                    ttt[0] = ttt[0].substr(0, 13);
                    if (ttt[1] !== undefined) result = ttt.join('.');
                    else result = ttt[0];
                }
            }
            break;
        case 'int':
            result = str.replace(/[^\d]/ig, '');
            if (result !== '') {
                result = String(parseInt(result.substr(0, 12)));
            }
            break;
        case 'AZ':
            result = str.replace(/[^\sA-Za-z]/ig, '').replace(/^\s+/, '').toUpperCase();
            break;
        case 'az_09':
            result = str.replace(/[^\dA-Za-z]/ig, '');
            break;
        case 'phone': //qiwi
            result = str.replace(/[^\d+]/ig, '').substring(0, 16);
            break;
        /*
                case 'webmoney': //webmoney
                    result = str.replace(/[^\w]/ig, '').substring(0, 16).toUpperCase();
                    break;
                case 'yandex': //yandex
                    result = str.replace(/[^\w]/ig, '').substring(0, 16).toUpperCase();
                    break;
                case 'mc': //mc
                    result = str.replace(/[^\d+]/ig, '').substring(0, 16);
                    break;
        */
        default :
            result = str;
            break;
    }

    return result;
};

//saveExcel(csvDataHeader, csvData, 'top_10_active_users')
export const saveExcel = async (columnsCsv, textAlign, dataCsv, workBookName) => {
    //const head = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    let columns = [];
    columnsCsv.forEach((it, ind) => {
        columns.push({header: it ? it : '', key: `key${ind}`})
    })
    let data = [];
    dataCsv.forEach((it, ind) => {
        let tmp = {};
        columns.forEach((it2, ind2) => {
            tmp[it2.key] = it[ind2];
        })
        data.push(tmp);
    })

    const workbook = new Excel.Workbook();
    const workSheetName = `${workBookName}-1`; //'Worksheet-1';
    // const workBookName = 'MyWorkBook';

    try {
        // const myInput = document.getElementById(myInputId);
        // const fileName = myInput.value || workBookName;
        const fileName = workBookName;

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(workSheetName);

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        worksheet.getRow(1).font = {bold: true};
        worksheet.getRow(1).height = 24;

        // let row = worksheet.getRow(1);
        //     row.fill = {
        //         type: 'pattern',
        //         pattern:'solid',
        //         fgColor:{argb:'#000000'},
        //         bgColor:{argb:'gray'}
        //     };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column, index) => {
            column.width = column.header.length + 5;
            column.alignment = {horizontal: textAlign[index], vertical: "middle", wrapText: true};
        });

        // loop through data and add each one to worksheet
        data.forEach(singleData => {
            worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({includeEmpty: false}, row => {
            // store each cell to currentCell
            const currentCell = row._cells;

            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCell.forEach(singleCell => {
                // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                const cellAddress = singleCell._address;
                if (singleCell._row._number === 1) {
                    worksheet.getCell(cellAddress).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        //fgColor: {argb: '90ED7D'},
                        fgColor: {argb: 'EEEEEE'},
                    };
                    worksheet.getCell(cellAddress).font = {
                        // name: 'Arial Black',
                        //color: {argb: 'FFFFFF'},
                        // family: 2,
                        //size: 12,
                        //italic: true,
                        bold: true,
                    };
                } else if (singleCell._row._number & 1) {
                    worksheet.getCell(cellAddress).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: 'FEFEFE'},
                    };
                }
                // apply border
                worksheet.getCell(cellAddress).border = {
                    top: {style: 'thin'},
                    left: {style: 'thin'},
                    bottom: {style: 'thin'},
                    right: {style: 'thin'}
                };
            });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
    } finally {
        // removing worksheet's instance to create new one
        workbook.removeWorksheet(workSheetName);
    }
};

export const saveInvoiceToExcel = async (results, record) => {
    console.log('record==========>', record)
    console.log('results==========>', results)
    const {full_number, date: dateInvoice, customer_details, is_paid} = record;
    const {
        f_name,
        l_name,
        is_company,
        name: invoiceName,
        email: invoiceEmail,
        mobile: invoicePhone,
        address: invoiceAdress
    } = customer_details || {};

    let dataCsv = [];
    let countPass = [];
    let invoiceTotal = 0;
    let i = 1;
    results.forEach(it1 => {
        let it = it1.booking_details;
        let passengers = [];
        countPass[i] = it.passengers.length;
        it.passengers.forEach(it2 => {
            passengers.push(`${it2.title} ${it2.f_name} ${it2.l_name}`);
        })

        let data = []
        let references = [];
        if (it.transaction_type.indexOf("non_flight")>=0) {
            if (it.non_flight_transactions) {
                it.non_flight_transactions.forEach((it2, ind) => {
                    data.push(`${moment(it2.date).format("D-MMM-YY")}`)
                    if (it2.date_to) data.push(` - ${moment(it2.date_to).format("D-MMM-YY")}`)
                })
            }
            it.passengers.forEach(it2 => {
                references.push(`${it2.ticket_number}`);
            })
        } else {
            if (it.ticket_type === 'multi_city') {
                if (it.legs) {
                    it.legs.forEach((it2) => {
                        data.push(moment(it2.travel_date).format("D-MMM-YY"))
                        //data.push(it2.travel_date)
                    })
                }
            } else {
                if (it.travel_date) data.push(moment(it.travel_date).format("D-MMM-YY"))
                if (it.travel_date_return) data.push(moment(it.travel_date_return).format("D-MMM-YY"))
            }
            if (it.references) {
                it.references.forEach(it2 => {
                    references.push(`${it2.pnr} (${it2.airline.code})`);
                })
            }
        }

        let issued_airline = it.issued_airline ? it.issued_airline.code : ''
        let route = []
        if (it.transaction_type.indexOf("non_flight")>=0) {
            route = ['non flight']
            issued_airline = ''
            let non_flight_transactions = get(it, 'non_flight_transactions', null)
            let ar = []
            console.log('non_flight_transactions==========>', non_flight_transactions)
            if (non_flight_transactions) {
                non_flight_transactions.forEach((it2) => {
                    ar.push(get(it2, 'value.value', ''))
                    let rt = get(it2, 'route', null)
                    if (rt) route.push(rt)
                })
                console.log('ar==========>', ar)
                issued_airline = ar.join('\n')
            }
        } else {
            if (it.ticket_type === 'rtn') {
                route.push(`${it.from_place} - ${it.to_place}`)
                route.push(`${it.to_place} - ${it.from_place}`)
            } else if (it.ticket_type === 'multi_city') {
                if (it.legs) {
                    it.legs.forEach((it2) => {
                        route.push(it2.route)
                    })
                }
            } else route = [it.route]
        }
        // console.log('issued_airline==========>', issued_airline)
        dataCsv.push([
            i,
            data.join('\n'), //it.travel_date ? moment(it.travel_date).format("D-MMM-YY") : '',
            issued_airline,
            route.join('\n'),
            passengers.join('\n'),
            references.join('\n'),
            formatSpacerNumber(it.sale_total_dollar),//'$\u00a0' + formatSpacerNumber(it.sale_total_dollar),
        ]);

        invoiceTotal += it.sale_total_dollar;
        i++;
    })
    invoiceTotal = formatSpacerNumber(invoiceTotal); //'$\u00a0' + formatSpacerNumber(invoiceTotal);
    dataCsv.push(['', '', '', '', '', 'Subtotal', invoiceTotal])
    dataCsv.push(['', '', '', '', '', 'Tax', '-'])
    dataCsv.push(['', '', '', '', '', 'Total', invoiceTotal])
    let numTotal = 3;
    //console.log('dataCsv=======>', dataCsv);
    //let textwidth = [3, 0, 0, 10, 10, 12, 14];
    // let textwidth = [3, 0, 0, 8, 10, 12, 12];
    let textwidth = [3, 0, 8, 5, 10, 12, 12];
    let textAlign = ['center', 'center', 'left', 'left', 'left', 'left', 'right'];
    let columnsCsv = ['№', 'Travel date', 'Airline', 'Route', 'Passengers', 'PNR ', 'Total $USD'];

    let invoiceDate = moment(dateInvoice).format("D-MMM-YY");
    let invoiceTo = is_company ? invoiceName : `${f_name} ${l_name}`;
    let bl0 = 'Executive Travel Exchange Ltd';
    let bl1 = 'Executive Travel Exchange Ltd';
    let bl2 = 'PO Box 27, Lawrence, NY 11559, USA';
    let bl3 = 'Phone/fax +1 718 377 1717';
    let bl4 = 'Bank Name: Bank of America';
    let bl5 = 'Bank Address: 222 Broadway, New York, NY 10038, USA';
    let bl6 = 'Account Name: ExecutiveTravel Exchange';
    let bl7 = 'Account Number: 483061613008';
    let bl8 = 'Routing Number: 021000322 (Paper&Electronic)';
    let bl9 = 'Routing Number: 026009593 (Wires)';
    let bl10 = 'Swift Code: BOFAUS3N';
    var headerRows = [
        [bl0, '', '', '', '', '', ''], // row by array
        [bl1, '', '', '', '', 'Invoice No', full_number], // row by array
        [bl2, '', '', '', '', 'Invoice to', invoiceTo], // row by array
        [bl3, '', '', '', '', '', ''], // row by array
        ['', '', '', '', '', 'Adress', invoiceAdress], // row by array
        [bl4, '', '', '', '', 'E-mail', invoiceEmail], // row by array
        [bl5, '', '', '', '', 'Phone', invoicePhone], // row by array
        [bl6, '', '', '', '', 'Invoice total $USD', invoiceTotal], // row by array
        [bl7, '', '', '', '', '', ''], // row by array
        [bl8, '', '', '', '', '', ''], // row by array
        [bl9, '', '', '', '', '', ''], // row by array
        [bl10, '', '', '', '', '', ''], // row by array
        ['Invoice Date', '', invoiceDate, '', '', '', ''], // row by array
        [], // row by array
    ];
    let backgroundColor = ['C13', 'D13', 'G8', 'G3'];
    let numHeader = headerRows.length + 1;
    let workBookName = `invoice_${full_number.replaceAll('/', '-')}` + moment(new Date()).format('_YYYYMMDD_HH_mm')
    //saveInvoiceToExcel(dataHeader, textAlign, dataCsv, name);
    //------------------------------------------------------------------

    //const head = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    let countRows = dataCsv.length + 1;
    let columns = [];
    columnsCsv.forEach((it, ind) => {
        //columns.push({header: it ? it : '', key: `key${ind}`})
        columns.push({header: it ? it : '', key: ind + 1})
    })
    let data = [];
    dataCsv.forEach((it, ind) => {
        let tmp = {};
        columns.forEach((it2, ind2) => {
            tmp[it2.key] = it[ind2];
        })
        data.push(tmp);
    })

    const workbook = new Excel.Workbook();
    const workSheetName = `${workBookName}-1`; //'Worksheet-1';
    // const workBookName = 'MyWorkBook';

    try {
        // const myInput = document.getElementById(myInputId);
        // const fileName = myInput.value || workBookName;
        const fileName = workBookName;

        // creating one worksheet in workbook
        const worksheet = workbook.addWorksheet(workSheetName);
        worksheet.pageSetup.margins = {
            left: 0.6, right: 0.5,
            top: 0.75, bottom: 0.75,
            header: 0.3, footer: 0.3
        };

        /*const imageId1 = workbook.addImage({
            filename: 'pay6152.jpg',
            extension: 'jpeg',
        });
        worksheet.addImage(imageId1, {
            tl: {col: 0, row: 0},
            ext: {width: 500, height: 200}
        });*/
        if (is_paid) {
            const imageSrc = '../img/Paid-PNG-Transparent-Image.png';
            const response = await fetch(imageSrc);
            const buffer = await response.arrayBuffer();
            let logo = workbook.addImage({
                buffer: buffer,
                extension: 'jpeg',
            });
            let col = countRows + numHeader - 3;
            worksheet.addImage(logo, `D${col}:E${col + 3}`);
        }

        /*worksheet.headerFooter.firstHeader = "Hello Exceljs";
        worksheet.headerFooter.firstFooter = "Hello World"
        const worksheet = workbook.addWorksheet(workSheetName, {
            //headerFooter: {firstHeader: "Hello Exceljs", firstFooter: "Hello World"}
            headerFooter: ["Hello Exceljs","Hello World"]
        });*/

        // add worksheet columns
        // each columns contains header and its mapping key from data
        worksheet.columns = columns;

        // updated the font for first row.
        //worksheet.insertRow(1, {id: 13, name: 'John Doe', dob: new Date(1970,1,1)});

        //-------------------------------------------------------
        // insert new rows and return them as array of row objects
        worksheet.insertRows(1, headerRows);
        worksheet.mergeCells('A1:G1');
        worksheet.mergeCells('A2:E2');
        worksheet.mergeCells('A3:E3');
        worksheet.mergeCells('A4:E4');
        worksheet.mergeCells('A5:E5');
        worksheet.mergeCells('A6:E6');
        worksheet.mergeCells('A7:E7');
        worksheet.mergeCells('A8:E8');
        worksheet.mergeCells('A9:E9');
        worksheet.mergeCells('A10:E10');
        worksheet.mergeCells('A11:E11');
        worksheet.mergeCells('A12:E12');

        worksheet.mergeCells('A13:B13');
        worksheet.mergeCells('C13:D13');
        worksheet.getCell('A1:C13').font = {
            bold: true,
        };
        //-------------------------------------------------------

        worksheet.getRow(numHeader).font = {bold: true};
        worksheet.getRow(numHeader).height = 24;
        worksheet.getRow(1).height = 36;

        // let row = worksheet.getRow(1);
        //     row.fill = {
        //         type: 'pattern',
        //         pattern:'solid',
        //         fgColor:{argb:'#000000'},
        //         bgColor:{argb:'gray'}
        //     };

        // loop through all of the columns and set the alignment with width.
        worksheet.columns.forEach((column, index) => {
            column.width = column.header.length + textwidth[index];
            column.alignment = {
                horizontal: textAlign[index],
                vertical: "middle",
                wrapText: true
            };
            column.font = {size: 10};
        });

        //---------------------------------------------
        for (let i = 1; i < (headerRows - 1); i++) {
            worksheet.getCell(`A${i}:C${i}`).alignment = {
                horizontal: 'left', wrapText: true
            };
            worksheet.getCell(`F${i}`).alignment = {
                horizontal: 'right', wrapText: true
            };
            worksheet.getCell(`G${i}`).alignment = {
                horizontal: 'right', wrapText: true
            };
        }
        worksheet.getCell(`A13:D13`).alignment = {
            horizontal: 'left', wrapText: true
        };
        worksheet.getCell(`C13:D13`).alignment = {
            horizontal: 'right', wrapText: true
        };
        //---------------------------------------------
        // loop through data and add each one to worksheet
        //console.log('=excel=====>', data)
        data.forEach(singleData => {
            //console.log('======>', singleData)
            worksheet.addRow(singleData);
        });

        // loop through all of the rows and set the outline style.
        worksheet.eachRow({includeEmpty: false}, row => {
            // store each cell to currentCell
            const currentCell = row._cells;
            //worksheet.getRow(row._number).height = 48;

            // loop through currentCell to apply border only for the non-empty cell of excel
            currentCell.forEach(singleCell => {
                // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
                const cellAddress = singleCell._address;
                //console.log('cellAddress=======>', cellAddress)
                if (singleCell._row._number === 1) {
                    worksheet.getCell(cellAddress).alignment = {
                        horizontal: 'left',
                        vertical: "middle",
                    };
                    worksheet.getCell(cellAddress).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: {argb: '16365C'},
                    };
                    worksheet.getCell(cellAddress).font = {
                        // name: 'Arial Black',
                        color: {argb: 'FFFFFF'},
                        // family: 2,
                        size: 16,
                        //italic: true,
                        bold: true,
                    };
                } else if (singleCell._row._number >= numHeader && singleCell._row._number < (countRows + numHeader - numTotal)) {
                    if (singleCell._row._number === numHeader) {
                        worksheet.getCell(cellAddress).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            //fgColor: {argb: '90ED7D'},
                            fgColor: {argb: 'EEEEEE'},
                        };
                        worksheet.getCell(cellAddress).font = {
                            // name: 'Arial Black',
                            //color: {argb: 'FFFFFF'},
                            // family: 2,
                            //size: 12,
                            //italic: true,
                            bold: true,
                        };
                    } else {
                        if (countPass[singleCell._row._number - numHeader] !== undefined && countPass[singleCell._row._number - numHeader] > 1)
                            worksheet.getRow(singleCell._row._number).height = countPass[singleCell._row._number - numHeader] * 16;
                        else worksheet.getRow(singleCell._row._number).height = 22;
                    }
                    // apply border
                    worksheet.getCell(cellAddress).border = {
                        top: {style: 'thin'},
                        left: {style: 'thin'},
                        bottom: {style: 'thin'},
                        right: {style: 'thin'}
                    };
                } else {
                    if (backgroundColor.indexOf(cellAddress) >= 0) {
                        worksheet.getCell(cellAddress).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            //fgColor: {argb: '90ED7D'},
                            fgColor: {argb: 'EEEEEE'},
                        };
                    }
                    worksheet.getCell(cellAddress).font = {
                        bold: true, size: 10,
                    };

                }
            });
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
    } finally {
        // removing worksheet's instance to create new one
        workbook.removeWorksheet(workSheetName);
    }
};

