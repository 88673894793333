import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    //useRecordContext,
    ReferenceField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldButton} from "../_common/CompReact";
import {FloatNumber} from "../booking/BookingColumnsForList";

export const RefundColumnsForList = (resource, locale, classes) => {
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        booking:
            <ReferenceField source="booking_id" reference="booking" sortBy={'booking:route'}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="route"
                />
            </ReferenceField>,

        transaction_date: <DateFieldComp source="transaction_date" label={'Transaction Date'} textAlign={"center"}
                                         cellClassName={classes.date_st} showTime={false} locale={'us'}/>,

        /*amount_dollar: /!*<FloatNumber source={'amount'} label={'Amount'} textAlign={"right"}/>,*!/
            <div style={{display: "grid", textAlign: "right"}} label={'Amount sale'}>
                <div>$ <FloatNumber source={'amount_dollar'}/></div>
                <FloatNumber source={'amount'}/>
            </div>,*/
        amount_dollar: <FloatNumber source={'amount_dollar'} label={'Amount sale ($)'} textAlign={"right"}/>,
        amount_cost_dollar: <FloatNumber source={'amount_cost_dollar'} label={'Amount cost ($)'} textAlign={"right"}/>,
        tax_dollar: <FloatNumber source={'tax_dollar'} label={'Tax ($)'} textAlign={"right"}/>,
        /*tax: /!*<FloatNumber source={'tax'} label={'Tax'} textAlign={"right"}/>,*!/
            <div style={{display: "grid", textAlign: "right"}} label={'Tax'}>
                <div>$ <FloatNumber source={'tax_dollar'}/></div>
                <FloatNumber source={'tax'}/>
            </div>,*/
        /*currency_id:
            <ReferenceField source="currency_id" reference="currency" sortBy={'currency:code'}>
                <TextFieldButton
                    //itsOriText={record => (`${record.last_name}`)}
                    //copyClipBoard={true}
                    source="code"
                />
            </ReferenceField>,*/
        /*currency_exchange: <FloatNumber source={'currency_exchange'} label={'Exchange Rate'} textAlign={"right"}
                                        fix={4}/>,*/
        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};
