import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../_common/EditComponentAdmin";
import HistoryEditCreate from "./HistoryEditCreate";

export const HistoryEdit = props => {
    const {location} = props;
    const {search} = location;
    let bookingId = '';
    if(search){
        let url = new URL('https://test/' + search);
        bookingId = url.searchParams.get('bookingId')
    }
    // const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            title={' '}
            onSuccess={() => props.history.goBack()}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <HistoryEditCreate bookingId={bookingId}/>
        </EditComponentAdmin>
    )
};