import * as React from 'react';
// import {Admin, createMuiTheme} from 'react-admin';
import {
    Admin,
    // ListGuesser,
    // EditGuesser
} from 'react-admin';
import dataProvider from "./utils/dataProvider";
// import dataProviderFake from "./utils/dataProviderFake";
// import simpleRestProvider from 'ra-data-simple-rest';
import i18nProvider from './utils/i18nProvider';
import MyLayout from "./layout/MyLayout";
import Dashboard from "./Dashboard";

import {Resource} from "@react-admin/ra-rbac";

/*-------COGNITO------------*/
// import {AuthProvider} from 'ra-cognito';
import {buildAuthProvider} from "react-admin-amplify";
import Amplify from 'aws-amplify';
import authProvider from './utils/authProvider';
// import PermIdentity from "@material-ui/core/SvgIcon/SvgIcon";
import universal from "./universal";
import {apiPathConfig} from "./utils/config";
import customer from "./customer";
import currency from "./currency";
import airline from "./airline";
import bank from "./bank";
import bookings_passenger from "./bookings_passenger";
import passenger from "./passenger";
import booking from "./booking";
import supplier from "./supplier";
import bookings_reference from "./bookings_reference";
import programs from "./programs";
import supplierData from "./supplierData";
import hiddenCity from "./hiddenCity";
import creditCard from "./creditCard";
import supplierDataNotMiles from "./supplierDataNotMiles";
import supplierDataIsMiles from "./supplierDataIsMiles";
import refund from "./refund";
import travelCredit from "./travelCredit";
import history from "./history";
import invoice from "./invoice";
import travel_credit_reports from "./travel_credit_reports";
import non_flight_transactions from "./non_flight_transactions";
import projects from "./projects";


if (apiPathConfig.getCognito) {
    const amplifyConfig = {
        Auth: {
            //storage: ICognitoStorage,
            //cookieStorage: ICookieStorageData,
            /*cookieStorage: {
                domain: "localhost",
                path: "/",
                expires: 365,
                secure: true
            },*/
            //?? identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab', //REQUIRED - Amazon Cognito Identity Pool ID
            region: process.env.REACT_APP_COGNITO_REGION, //'us-east-1',
            userPoolId: process.env.REACT_APP_COGNITO_POOL_ID, //'us-east-1_naqZRfsYi',
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, //'2t04thphs1r35np5gf367v4hdd',
            /*oauth: {
                   //domain: 'https://cc-react-admin.auth.eu-central-1.amazoncognito.com',
                   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                   redirectSignIn: 'http://localhost:3000/',
                   redirectSignOut: 'http://localhost:3000/',
                   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
               }*/
        },
    };

    Amplify.configure(amplifyConfig);
}

/*const showDefault = {
    list: ListGuesser,
    edit: EditGuesser,
}*/
// if (apiPathConfig.getAuth()) token = 'Token ' + JSON.parse(localStorage.getItem('auth')).key;
/*
localStorage.setItem('preferences', JSON.stringify(preferences));
*/

const App = () => (
    <Admin
        authProvider={apiPathConfig.getCognito()?
            buildAuthProvider({authGroups: ["Admins", "admin", 'Managers']}):
            authProvider}
        // authProvider={authProvider}
        //authProvider={buildAuthProvider({authGroups: ["Admins", "admin"]})}
        // dataProvider={dataProviderFake}
        dataProvider={dataProvider}
        // dataProvider={simpleRestProvider(process.env.REACT_APP_API_URL)}
        locale="en"
        //theme={myTheme}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        title="BookMan"
        dashboard={Dashboard}
        //loginPage={Login}
        disableTelemetry
    >

        <Resource name={'universal'} {...universal} options={{label: 'Tickets'}}/>
        <Resource name={'customer'} {...customer} options={{label: 'Customers'}}/>
        <Resource name={'currency'} {...currency} options={{label: 'Currencies'}}/>

        <Resource name={'airline'} {...airline} options={{label: 'Airlines'}}/>
        <Resource name={'new_airline'} options={{label: 'New Airline'}}/>

        <Resource name={'bank'} {...bank} options={{label: 'Banks'}}/>

        <Resource name={'bookings_passenger'} {...bookings_passenger} options={{label: 'Bookings Passenger'}}/>
        <Resource name={'new_bookings_passenger'} options={{label: 'Passenger (from passenger List)'}}/>

        <Resource name={'passenger'} {...passenger} options={{label: 'Passengers'}}/>
        <Resource name={'booking'} {...booking} options={{label: 'General Sales'}}/>
        <Resource name={'supplier'} {...supplier} options={{label: 'Program Account'}}/>

        <Resource name={'bookings_reference'} {...bookings_reference} options={{label: 'Bookings Reference'}}/>
        <Resource name={'new_bookings_reference'} options={{label: 'Booking Ref/PNR'}}/>

        <Resource name={'programs'} {...programs} options={{label: 'Programs'}}/>
        <Resource name={'supplierData'} {...supplierData} options={{label: 'Suppliers'}}/>
        <Resource name={'supplierDataIsMiles'} {...supplierDataIsMiles} options={{label: 'Dealers'}}/>
        <Resource name={'supplierDataNotMiles'} {...supplierDataNotMiles} options={{label: 'Suppliers'}}/>
        <Resource name={'hiddenCity'} {...hiddenCity} options={{label: 'Hidden City'}}/>
        <Resource name={'creditCard'} {...creditCard} options={{label: 'Credit Card'}}/>
        <Resource name={'refund'} {...refund} options={{label: 'Refund'}}/>
        <Resource name={'travelCredit'} {...travelCredit} options={{label: 'Travel Credit'}}/>
        <Resource name={'travel_credit_reports'} {...travel_credit_reports} options={{label: 'Travel Credit'}}/>
        <Resource name={'invoice'} {...invoice} options={{label: 'Invoice'}}/>
        <Resource name={'invoice_booking'} options={{label: 'Invoice Booking'}}/>
        <Resource name={'history'} {...history} options={{label: 'History'}}/>

        <Resource name={'non_flight_transactions'} {...non_flight_transactions} options={{label: 'Non Flight Cat.'}}/>
        <Resource name={'bookings_non_flight_transactions'} options={{label: 'Non Flight Cat.'}}/>

        <Resource name={'projects'} {...projects} options={{label: 'Projects'}}/>


    </Admin>
);
/*some changes*/

export default App;
