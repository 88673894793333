import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {SupplierDataList} from "./SupplierDataList";
import {SupplierDataEdit} from "./SupplierDataEdit";
import {SupplierDataCreate} from "./SupplierDataCreate";

const resource = {
    list: SupplierDataList,
    create: SupplierDataCreate,
    edit: SupplierDataEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;