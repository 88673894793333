import * as React from 'react';
import {
    Create,
    //useRedirect
} from 'react-admin';

import RefundEditCreate from "./RefundEditCreate";
import PostEditActions from "../_common/PostEditActions";

export const RefundCreate = props => {
    const {location} = props;
    const {search} = location;

    //const redirect = useRedirect()

    let bookingId = '';
    if(search){
        let url = new URL('https://test/' + search);
        bookingId = url.searchParams.get('bookingId')
    }

    /*const onSuccessData = (data) => {
        const {data: dataRes} = data;
        const {id: idRes} = dataRes || {};
        //console.log('DATA======>', idRes)
        if (bookingId && idRes) {
            redirect(`/booking/${bookingId}`)
        } else props.history.goBack();
    };*/

    return (
        <Create {...props}
            title={' '}
            //onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <RefundEditCreate bookingId={bookingId}/>
        </Create>
    )
};