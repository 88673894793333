import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    TextField,
    //useRecordContext,
    //EmailField,
    BooleanField, EmailField,
} from 'react-admin';
import {DateFieldComp, TextFieldBold} from "../_common/CompReact";

export const SupplierDataColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        title: <TextFieldBold source={`title`} label={'Title'}/>,
        is_miles: <BooleanField source={`is_miles`} label={'Is miles'}/>,
        address: <div style={{display: "grid"}} label={'Address'} title={row => `${row.address}`}>
            <TextField source={`country`} label={'Country'}/>
            <TextField source={`region`} label={'Region'}/>
            <TextField source={`city`} label={'City'}/>
        </div>,
        email: <EmailField source={`email`} label={'E-mail'}/>,
        phone: <TextField source={`phone`} label={'Phone'}/>,
        notes: <TextField source={`notes`} label={'Notes'}/>,
        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'} cellClassName={classes.date_st}/>,
    }
    return columns;
};
