import * as React from 'react';
import {
    NumberField,
    //ReferenceField,
    TextField,
    //useRecordContext,
    //EmailField,
    ReferenceField, BooleanField,
} from 'react-admin';
// import {Box} from "@material-ui/core";
import {DateFieldComp, TextFieldButton} from "../_common/CompReact";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";
// import {commonYesNo} from "../utils/choices";
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
// import get from 'lodash/get';
// import {EditCommentFieldFromList} from "../_common/EditCommentFieldFromList";
// import {titleMrMs} from "../utils/choices";
// import SelectAndSaveChoices from "../_common/SelectAndSaveChoices";

export const SupplierColumnsForList = (resource, locale, classes) => {

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        program_id:
            <ReferenceField source="program_id" reference="programs"
                            label={'Program'} sortBy={'programs:title'}>
                <TextField source="title"/>
            </ReferenceField>,
        airline:
        <div label={'Airline for Program'} link={false}>
            <ReferenceField source="program_id" reference="programs"  link={false}
                            label={'Airline for Program'}>
                <ReferenceField source="airline_id" reference="airline"  link={false}>
                    <TextField source="title"/>
                </ReferenceField>
            </ReferenceField>
        </div>,
        is_miles:
            <ReferenceField source="supplier_id" reference="supplierData" label={'Is miles'} link={false}
                            sortBy={'supplierData:is_miles'}>
                <BooleanField source="is_miles"/>
            </ReferenceField>,

        account_name: <TextField source={`account_name`} label={'Account name'}/>,
        f_name: <TextField source={`f_name`} label={'Account First Name'}/>,
        l_name: <TextField source={`l_name`} label={'Account Last Name'}/>,
        account_number: <TextField source={`account_number`} label={'Account Number'}/>,
        login: <TextField source={`login`} label={'Account Username'}/>,
        password: /*<TextField source={`password`} label={'Password'}/>,*/
            <TextFieldButton
                //itsOriText={record => (`${record.last_name}`)}
                copyClipBoard={true}
                source="password"
                label={'Password'}
                pasw={true}
                bold={false}
            />,
        pin: <TextField source={`pin`} label={'Pin'}/>,
        email_login: <TextField source={`email_login`} label={'Email Connected to Account'}/>,
        email_password: /*<TextField source={`email_password`} label={'Email Password'}/>,*/
            <TextFieldButton
                //itsOriText={record => (`${record.last_name}`)}
                copyClipBoard={true}
                source="email_password"
                label={'Email Password'}
                pasw={true}
                bold={false}
            />,

        cost: <TextField source={`cost`} label={'Cost'}/>,
        supplier_id:
            <ReferenceField source="supplier_id" reference="supplierDataIsMiles" label={'Dealer'}
                            sortBy={'supplierData:title'}>
                <TextField source="title"/>
            </ReferenceField>,
        /*currency_id:
            <ReferenceField source="currency_id" reference="currency" label={'Currency'} sortBy={'currency:code'} link={link}>
                <TextField source="code"/>
            </ReferenceField>,*/

        created_at: <DateFieldComp source="created_at" label={'Created'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={'Updated'} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};
