import * as React from "react";
import {useState, useRef, useEffect} from "react";
import {ReactTabulator} from 'react-tabulator'
import "react-tabulator/lib/css/tabulator.min.css";
import {headerMenu} from "./common";
import ConfigReport from "./config";
// import {apiPathConfig} from "../../utils/config";
import Variants from "./variants";
import {Button, Grid} from "@material-ui/core";
import {apiPathConfig} from "../utils/config";
import Filter from "./filter";
import {fetchJson as httpClient} from "../utils/keycloak";
//import {useRedirect} from "react-admin";

const mergeArray = (a1, a2) => {
    return Object.fromEntries(a1.map((key, index) => [key, a2[index]]))
}


const getInitFilterStatus = (filters,) => {
    // const filters = {
    //     booking: ['Query', false, '/admin/booking'],
    //     customer: ['Query', false, '/admin/customer'],
    //     date_from: ['DateTime', false,],
    //     date_to: ['DateTime', false,],
    // }

    // const queryDefault = {use: false, value: ''}
    // const dateDefault = {use: false, value: '', compare: '<='}
    const newFilters = Object.entries(filters).reduce((acc, [key, [type, use, value, compare]]) => {
        acc[key] = {use, value};
        acc[key].value = ''
        if (key === 'date_from' && !value) {
            acc[key].value = `${new Date().getFullYear()}-01-01T00:00:00`;
            acc[key].compare = '>=';
        } else if (key === 'date_to' && !value) {
            acc[key].compare = '<=';
        } else if (key === 'expire_date' && !value) {
            const n = new Date()
            acc[key].value = `${n.toISOString().slice(0, 10)}T23:59:59`;
            acc[key].compare = '>=';
        }
        if (compare !== undefined) {
            acc[key].compare = compare;
        }
        // debugger
        return acc;
    }, {});


    // return {
    //     'booking': {use: false, value: ''},
    //     'customer': {use: false, value: ''},
    //     'date_from': {use: true, value: `${new Date().getFullYear()}-01-01T00:00:00`, compare: '>='},
    //     'date_to': {use: false, value: '', compare: '<='},
    // }

    return newFilters
}


const MasterReport = (props) => {

    const {
        report_fields,
        report_group_by,
        variantsConfig,
        table_columns,
        report_url,
        filters,
        additionalOptions,
        AdditionalParamsComponent,
        customFilter = "",
    } = props

    //const redirect = useRedirect()
    const controllerRef = useRef();
    const [filterState, setFilterState] = React.useState(getInitFilterStatus(filters))

    const variants = Object.keys(variantsConfig)

    const initFields = mergeArray(report_fields, variantsConfig[variants[0]][0])
    const initGroups = mergeArray(report_group_by, variantsConfig[variants[0]][1])

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let bookmanShowBlockFilters = localStorage.getItem('bookmanShowBlockFilters');
    const [showBlockFilters, setShowBlockFilters] = useState(bookmanShowBlockFilters && bookmanShowBlockFilters === 'true' ? true : false);

    // const [variant, setVariant] = useState(variants[0]);
    const [fields, setFields] = React.useState(initFields);
    const [groups, setGroups] = React.useState(initGroups);

    const handleChangeVariant = (v) => {
        setFields(mergeArray(report_fields, variantsConfig[v][0]))
        setGroups(mergeArray(report_group_by, variantsConfig[v][1]))
    }

    const BlockFilters = useRef();
    const handleBlockFilters = (v) => {
        // let block = BlockFilters.current;
        //localStorage.removeItem('bookmanShowBlockFilters');
        localStorage.getItem('bookmanShowBlockFilters');
        if (showBlockFilters) {
            setShowBlockFilters(false);
            localStorage.setItem('bookmanShowBlockFilters', false);
            /*block.animate(
                [
                    // keyframes
                    {height: 0, opacity: 0},
                ],
                {
                    // timing options
                    duration: 2000,
                    //iterations: Infinity,
                }
            );*/
        } else {
            localStorage.setItem('bookmanShowBlockFilters', true);
            setShowBlockFilters(true);
        }
    }

    // const {year, month, name} = fields;

    useEffect(() => {
        const getData = async () => {
            const url = apiPathConfig.getApiUrl()

            const queryFilters = Object.keys(filterState).filter(i => filterState[i].use).map(key => {
                const compare = filterState[key].compare
                if (compare === ">=") return `${key.replace("_from", "")}=gte__${filterState[key].value}`
                if (compare === "<=") return `${key.replace("_to", "")}=lte__${filterState[key].value}`
                return `${key}=${filterState[key].value}`
            });
            const queryColumns = `columns=${Object.keys(fields).filter(i => fields[i]).join(',')}`
            const q = `${url}${report_url}?${[...queryFilters, queryColumns, customFilter].join('&')}`

            if (controllerRef.current) {
                controllerRef.current.abort();
            }
            const controller = new AbortController();
            controllerRef.current = controller;

            try {
                const response = await httpClient(q, {
                    signal: controllerRef.current?.signal
                });
                // debugger
                if (response.status !== 200) {
                    throw new Error(
                        `This is an HTTP error: The status is ${response.status} ${response.body}`
                    );
                }
                let actualData = response.json;
                let d1 = actualData && actualData.results
                setData(d1);
                setError(null);
                controllerRef.current = null;
            } catch (err) {
                setError(err.message);
                setData(null);
            } finally {
                setLoading(false);
            }
        }
        getData()
    }, [fields, groups, report_url, filterState, customFilter])

    const columns = table_columns.filter(i => report_fields.includes(i.field) ? fields[i.field] : true).map(i => ({
        ...i,
        headerMenu: headerMenu
    }))

    /*const rowClick = (e, row) => {
        // console.log('rowClick==e==================>', e);
        //e.stopPropagation();
        const field = e.target.attributes['tabulator-field'].value;
        const dt = row.getData();
        console.log(field, 'rowClick====================>', dt);
        //if(dt.booking_id !== undefined && dt.booking_id) redirect(`/booking/${dt.booking_id}`)

    };*/

    //let ref = React.useRef();
    return (
        <>
            <Grid container spacing={3} style={{marginBottom: 8}}>
                <Grid item xs={12}>
                    <Variants variants={variants} changeVariant={handleChangeVariant}/>
                </Grid>
            </Grid>
            <Button
                size="small"
                color="primary"
                style={{marginTop: -8, marginBottom: !showBlockFilters && 16}}
                onClick={handleBlockFilters}
            >
                {showBlockFilters ? 'Hide Fields, Group By, Filters' : 'Show Fields, Group By, Filters'}
            </Button>
            <Grid container
                  spacing={3}
                  style={{marginBottom: 8, display: showBlockFilters ? "flex" : "none"}}
                  id="BlockFilters"
                  ref={BlockFilters}
            >
                <Grid item xs={"auto"}>
                    <ConfigReport title='Fields' state={fields} setState={setFields}
                                  table_columns={Object.values(table_columns)}/>
                    <ConfigReport title='Group By' state={groups} setState={setGroups}
                                  table_columns={Object.values(table_columns)}/>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={"auto"}>
                    <Filter filters={filters} filterState={filterState} setFilterState={setFilterState}
                            table_columns={Object.values(table_columns)}/>
                </Grid>
            </Grid>

            {AdditionalParamsComponent ?
                <AdditionalParamsComponent filterState={filterState} setFilterState={setFilterState}/> : null}

            {loading && <>Loading...</>}
            {error && <>Error: {error}</>}
            {/*<React15Tabulator*/}
            <ReactTabulator
                // height={"100%"}
                //onRef={(ref) => (ref = ref)}
                options={{
                    groupBy: Object.keys(groups).filter(i => groups[i]),
                    movableRows: true,
                    ...additionalOptions,
                }}
                data={data}
                columns={columns}
                layout={"fitDataFill"}
                /*events={{
                    rowClick: rowClick,
                }}*/
            />
        </>
    )
};

export default MasterReport
