import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {ProgramsList} from "./ProgramsList";
import {ProgramsEdit} from "./ProgramsEdit";
import {ProgramsCreate} from "./ProgramsCreate";

const resource = {
    list: ProgramsList,
    create: ProgramsCreate,
    edit: ProgramsEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;