import * as React from 'react';
import {
    Create,
    //useRedirect
} from 'react-admin';

import ProgramsEditCreate from "./ProgramsEditCreate";
import PostEditActions from "../_common/PostEditActions";

export const ProgramsCreate = props => {
    /*const {location} = props;
    const {search} = location;

    const redirect = useRedirect()
    let programId = '';
    if (search) {
        let url = new URL('https://test/' + search);
        programId = url.searchParams.get('programId')
    }

    const onSuccessData = (data) => {
        const {data: dataRes} = data;
        const {id: idRes} = dataRes || {};
        //console.log('DATA======>', idRes)
        if (programId && idRes) {
            redirect(`/programs/${programId}`)
        } else props.history.goBack();
    };*/
    return (
        <Create {...props}
            title={' '}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={onSuccessData}
            onSuccess={() => props.history.goBack()}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <ProgramsEditCreate/>
        </Create>
    )
};